import { Pipe, PipeTransform } from "@angular/core";
import { Package } from "../../models/package.model";
import { E_TRACKING_STATUSES } from "../../helpers/constants";
import { externalIdToName } from "../../helpers/constants/transporters";

@Pipe({ name: "currentStatusText" })
export class CurrentStatusTextPipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */

    transform(packageData: Package) {
        const { last_status, service_point } = packageData;
        if (last_status == null) {
            return "Geen status beschikbaar";
        }

        switch (last_status) {
            case E_TRACKING_STATUSES.PACKAGE_ADDED_TO_SYSTEM:
                return "Pakket toegevoegd aan het systeem";
            case E_TRACKING_STATUSES.PACKAGE_SCANNED_BY_DC:
                return "Pakket in bezit van het ViaDC";
            case E_TRACKING_STATUSES.PACKAGE_SCANNED_BY_VIABODE:
                return "Pakket in bezit van een ViaBode ";
            case E_TRACKING_STATUSES.PACKAGE_SCANNED_BY_VIAPUNT:
                return `Pakket in bezit van ViaTim Punt ${service_point?.name}`;
            case E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_VIAGEBRUIKER:
                return "Pakket is bezorgd";
            case E_TRACKING_STATUSES.PACKAGE_PICKED_UP_BY_VIAGEBRUIKER:
                return "Pakket is opgehaald door de ontvanger";
            case E_TRACKING_STATUSES.PACKAGE_VERIFIED_BY_VIAGEBRUIKER:
                return "Pakket is geverifieerd door de ontvanger";
            case E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_VIABODE:
                return "Pakket is toegewezen aan een ViaBode";
            case E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_VIAPUNT:
                return `Pakket is gegeven aan ViaTim Punt ${service_point?.name}`;
            case E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_VIADC:
                return "Pakket is toegewezen aan een ViaDC ";

            case E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_EXTERNAL:
                return `Pakket in bezit van externe vervoerder ${externalIdToName(
                    packageData?.external_id,
                )}`;
            default:
                return `Onbekende status: ${last_status}`;
        }
    }
}
