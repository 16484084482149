import { Pipe, PipeTransform } from "@angular/core";
import { UserJSON } from "../../models/json/userJSON";
import { User } from "../../models/user.model";

@Pipe({
    name: "fullName",
})
export class FullNamePipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(address: UserJSON | User): string {
        if (address != null) {
            const nameParts = [address.firstname, address.middlename, address.lastname];
            return nameParts.filter((x) => x && x.length > 0).join(" ");
        }
        return "";
    }
}
