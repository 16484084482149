import { uniq, groupBy, isEqual } from "lodash-es";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { parse } from "papaparse";
import { StatusCodes } from "http-status-codes";
import { ViaWebshop } from "../../models/viawebshop.model";
import { Message } from "../../models/message";
import { PackagePriceListItem } from "../../models/packagePriceListItem.model";
import { PriceCategoryService } from "../../services/priceCategory.service";
import { ExternalPriceType } from "../../models/externalPriceType";
import { addMessage } from "../../store/actions/message.actions";
import { PickupPriceListItem } from "../../models/pickupPriceListItem.model";
import { BackendService } from "../../services/backend.service";
import moment, { Moment } from "moment";
import { Progress } from "../../store/reducers/progress.reducer";
import {
    finishProgress,
    startProgress,
    updateProgress,
} from "../../store/actions/progress.actions";
import { FULL_PERCENTAGES } from "../../helpers/constants";

const IMPORT_FILE_HEADERS = ["Webshop", "Type", "Vervoerder", "Land", "Prijs", "Retour"];

@Component({
    selector: "app-import-prices",
    templateUrl: "./import-prices.component.html",
    styleUrls: ["./import-prices.component.scss"],
})
export class ImportPricesComponent implements OnInit {
    progress: number = 0;

    file: File;

    @ViewChild("uploader")
    fileInputElement: ElementRef;

    input: string;

    data: { packagePrices: PackagePriceListItem[]; pickupPrices: PickupPriceListItem[] } = {
        packagePrices: [],
        pickupPrices: [],
    };

    webshops: ViaWebshop[];

    selectedWebshopId: number | null = null;

    importDate: Moment;

    state: "NONE" | "LOADING" | "PROCESSING" | "SAVING" = "NONE";

    fileData: any[] = [];

    externalPriceTypes: ExternalPriceType[] = [];

    carrierCodes: string[] = [];

    constructor(
        private service: PriceCategoryService,
        private backendService: BackendService,
        private store: Store<{ progress: Progress }>,
    ) {}

    ngOnInit(): void {
        this.backendService.getAllWebshops().subscribe((webshops) => {
            this.webshops = webshops;
        });

        this.service.getExternalPriceTypes().subscribe((externalPriceTypes) => {
            this.externalPriceTypes = externalPriceTypes;
            this.carrierCodes = uniq(externalPriceTypes.map((x) => x.carrierCode));
        });
    }

    onDateSelect(event) {
        this.importDate = moment([event.year, event.month - 1, event.day]);
    }

    back() {
        if (
            this.data &&
            (this.data.packagePrices?.length > 0 || this.data.pickupPrices?.length > 0)
        ) {
            this.data = { packagePrices: [], pickupPrices: [] };
        } else if (this.importDate) {
            this.importDate = null;
        } else {
            this.fileData = [];
            this.file = null;
            this.fileInputElement.nativeElement.value = "";
        }
    }

    processFileData = () => {
        this.state = "PROCESSING";

        const webshops = this.webshops.map((w) => ({
            id: w.id,
            companyName: w.companyName,
        }));

        const webshopNames = uniq(this.fileData.map((row) => row.Webshop));
        this.store.dispatch(
            startProgress({
                total: this.fileData.length,
            }),
        );
        let count = 0;

        const worker = new Worker(new URL("./import-worker.worker", import.meta.url));
        worker.onmessage = (data: MessageEvent) => {
            if (typeof data.data !== "string") {
                this.data.pickupPrices = data.data.pickupPrices.map(PickupPriceListItem.fromJSON);
                this.data.packagePrices = data.data.packagePrices.map(
                    PackagePriceListItem.fromJSON,
                );
                this.localValidation();
                this.store.dispatch(finishProgress());
                this.state = "NONE";
            } else {
                if (data.data === "DONE") {
                    this.store.dispatch(
                        updateProgress({
                            count: count++,
                        }),
                    );
                } else if (data.data === "ERROR") {
                    this.store.dispatch(
                        addMessage({
                            message: Message.createErrorMessage(
                                "Inleesfout",
                                "Er is iets fout gegaan tijdens het inlezen. Controleer of je geen fouten in je sheet hebt staan.",
                            ),
                        }),
                    );

                    this.store.dispatch(finishProgress());
                    this.state = "NONE";
                }
            }
        };
        worker.onerror = (e) => {
            console.error("FROM WORKER", e);
        };
        const data = {
            rows: this.fileData,
            webshopNames,
            webshops,
            types: this.externalPriceTypes,
        };
        worker.postMessage(data);
    };

    upload(event) {
        [this.file] = event.target.files;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            this.state = "LOADING";
            this.input = <string>fileReader.result;
            const result = parse(this.input, {
                skipEmptyLines: true,
                header: true,
                dynamicTyping: true,
            });

            this.fileData = result.data;
            const allHeaders = this.fileData.reduce(
                (acc, obj) => uniq([...acc, ...Object.keys(obj)]),
                [],
            );
            const missingHeaders = IMPORT_FILE_HEADERS.filter(
                (header) => !allHeaders.includes(header),
            );
            if (missingHeaders.length > 0) {
                this.store.dispatch(
                    addMessage({
                        message: Message.createErrorMessage(
                            "Inlezen",
                            `Het gekozen bestand bevalt niet alle verplichte headers. Check of deze in het bestand aanwezig zijn ${missingHeaders.join(
                                ", ",
                            )}`,
                        ),
                    }),
                );
                this.fileData = null;
                this.file = null;
                this.fileInputElement.nativeElement.value = "";
            }
            this.localValidation();
            this.state = "NONE";
        };
        fileReader.readAsText(this.file);
    }

    localValidation() {
        if (this.data) {
            const duplicatePackagePrices = Object.values(
                groupBy(this.data.packagePrices, (a) =>
                    [
                        a.countryCode,
                        a.carrierCode,
                        a.externalPackageType,
                        a.webshopId,
                        a.inbound,
                    ].join(":"),
                ),
            )
                .filter((x) => x.length > 1)
                .flat();
            this.data.packagePrices.forEach((packagePrice) => {
                const mandatoryColumns = [
                    "countryCode",
                    "carrierCode",
                    "externalPackageType",
                    "webshopId",
                ];
                const errors = {};
                mandatoryColumns.forEach((row) => {
                    if (!packagePrice[row]) {
                        errors[row] = "required";
                    }
                });
                if (!this.carrierCodes.includes(packagePrice.carrierCode)) {
                    errors["carrierCode"] = "invalid";
                }

                if (duplicatePackagePrices.find((d) => isEqual(packagePrice, d))) {
                    errors["duplicate"] = "duplicate";
                }
                packagePrice.errors = errors;
            });
            const duplicatePickupPrices = Object.values(
                groupBy(this.data.packagePrices, (a) =>
                    [a.countryCode, a.carrierCode, a.externalPackageType, a.webshopId].join(":"),
                ),
            )
                .filter((x) => x.length > 1)
                .flat();
            this.data.pickupPrices.forEach((pickupPrice) => {
                const errors = {};
                if (!pickupPrice.webshopId) {
                    errors["webshopId"] = "required";
                }
                if (duplicatePickupPrices.find((d) => isEqual(pickupPrice, d))) {
                    errors["duplicate"] = "duplicate";
                }
                pickupPrice.errors = errors;
            });
        }
    }

    get webshopsForFilter() {
        if (this.webshops && this.data && this.data.packagePrices && this.data.pickupPrices) {
            const usedWebshopIds = uniq(
                [...this.data.packagePrices, ...this.data.pickupPrices].map((x) => x.webshopId),
            );
            return this.webshops.filter((w) => usedWebshopIds.includes(w.id));
        }
        return [];
    }

    get packagePricesToDisplay() {
        if (this.selectedWebshopId) {
            return this.data.packagePrices.filter((p) => p.webshopId === this.selectedWebshopId);
        }
        return this.data.packagePrices.filter((p) => Object.keys(p.errors).length > 0);
    }

    get pickupPricesToDisplay() {
        if (this.selectedWebshopId) {
            return this.data.pickupPrices.filter((p) => p.webshopId === this.selectedWebshopId);
        }
        return this.data.pickupPrices.filter((p) => Object.keys(p.errors).length > 0);
    }

    updateProgress(counter: number, total: number) {
        this.progress = Math.round((counter / total) * FULL_PERCENTAGES);
    }

    get hasErrors() {
        const hasPackagePriceErrors =
            this.data.packagePrices &&
            this.data.packagePrices.some((p) => Object.keys(p.errors).length > 0);
        const hasPickupPriceErrors =
            this.data.pickupPrices &&
            this.data.pickupPrices.some((p) => Object.keys(p.errors).length > 0);
        return hasPackagePriceErrors || hasPickupPriceErrors;
    }

    import() {
        this.state = "SAVING";
        this.localValidation();
        if (this.hasErrors) {
            this.store.dispatch(
                addMessage({
                    message: Message.createErrorMessage(
                        "Validatiefouten",
                        "Er zitten nog validatiefouten in de import. Los deze op voor je de sheet importeert",
                    ),
                }),
            );
            return;
        }
        this.service
            .postWebshopPrices(
                this.data.pickupPrices,
                this.data.packagePrices,
                this.importDate.format("YYYY-MM-DD"),
            )
            .subscribe({
                next: () => {
                    this.state = "NONE";
                    this.store.dispatch(
                        addMessage({
                            message: Message.createSuccessMessage(
                                "Importeren",
                                "Het opslaan van de prijzen is gelukt",
                            ),
                        }),
                    );
                    this.importDate = null;
                    this.fileData = [];
                    this.file = null;
                    this.data = {
                        packagePrices: [],
                        pickupPrices: [],
                    };
                },
                error: (e) => {
                    this.state = "NONE";

                    if (e.status === StatusCodes.INTERNAL_SERVER_ERROR) {
                        this.store.dispatch(
                            addMessage({
                                message: Message.createErrorMessage(
                                    "Server fout tijdens import",
                                    "Neem contact op met systeem beheerder!",
                                ),
                            }),
                        );
                    } else {
                        const { errors } = e.error;
                        const toInternalFields = {
                            webshop_id: "webshopId",
                            country_code: "countryCode",
                            external_id: "externalId",
                            external_package_type: "externalPackageType",
                        };
                        const all = [...this.data.pickupPrices, ...this.data.packagePrices];
                        errors.forEach((error) => {
                            const [, index, field] = error.fields[0].split(".");
                            const indexNr = parseInt(index, 10);
                            if (!all[indexNr].errors) {
                                all[indexNr].errors = {};
                            }
                            all[indexNr].errors[toInternalFields[field] || field] = error.reason;
                        });
                        this.store.dispatch(
                            addMessage({
                                message: Message.createErrorMessage(
                                    "Importeren",
                                    "Er hebben zich wat fouten voorgedaan bij het importeren",
                                ),
                            }),
                        );
                    }
                },
            });
    }
}
