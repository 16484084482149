import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AuthGuard } from "../services/auth.guard";
import { WebshopsComponent } from "./webshops.component";
import { WebshopsDetailComponent } from "./webshops-detail/webshops-detail.component";
import { WebshopsCreateComponent } from "./webshops-create/webshops-create.component";
import { WebshopsEditComponent } from "./webshops-edit/webshops-edit.component";
import { WebshopsActivationModalComponent } from "./webshops-modals/webshops-activation-modal/webshops-activation-modal.component";

const webshopsRoutes: Routes = [
    {
        path: "webshops",
        component: WebshopsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "webshops/create",
        component: WebshopsCreateComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "webshops/:id",
        component: WebshopsDetailComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "webshops/:id(modal:webshop-activation)",
        component: WebshopsActivationModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "webshop-activation",
        component: WebshopsActivationModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "webshops/:id/edit",
        component: WebshopsEditComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [NgbModule, RouterModule.forChild(webshopsRoutes)],
    exports: [RouterModule],
})
export class WebshopsRoutingModule {}
