<table>
    <tr>
        <th>Datum</th>
        <th>Tijd</th>
        <th>Type</th>
        <th></th>
    </tr>
    <tr *ngFor="let item of history">
        <td>
            {{ item.external.timestamp || item.timestamp | date : "dd-MM-yyyy" }}
        </td>
        <td>{{ item.external.timestamp || item.timestamp | splitTime }}</td>
        <td *ngIf="!item.external.status">
            {{ item.status | statusText : item.user }}
        </td>
        <td *ngIf="item.external.status">
            <span style="color: var(--main-color)"
                >Externe Status {{ item.external.number | externalDisplayName }}:</span
            >
            {{ item.external.status }}
        </td>
        <td *ngIf="item.status === 10 && !item.external.status">
            Pakket overgedragen aan externe vervoerder
            {{ item.external.number | externalDisplayName }}
        </td>
        <td>
            <span *ngIf="item.status === 5 && signature">
                <a target="_blank" [href]="signature">
                    <i class="far fa-file-signature"></i>
                </a>
            </span>
        </td>
    </tr>
</table>
