export enum UserTypes {
    nonIntegratedUser,
    viaBode,
    viaDC,
    viaGebruiker,
    viaWebshop,
    nonIntegratedWebshop,
    externalParty,
    admin,
}
