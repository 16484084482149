import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, mergeMap, tap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { login, loginSuccess, loginFailure, logout } from "../actions/session.actions";
import { AuthService } from "../../services/auth.service";
import { AuthenticationState } from "../../models/authenticationState";
import { loadCurrentUser } from "../actions/current-user.actions";

@Injectable()
export class SessionEffects {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
    ) {}

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(login),
            mergeMap((action) =>
                this.authService.authenticate(action.username, action.password, action.userId).pipe(
                    switchMap((token) => {
                        const url = action.redirectUrlTree || "/";
                        this.router.navigate([url]);
                        return [loginSuccess({ token }), loadCurrentUser()];
                    }),
                    catchError((e) => {
                        let reason = AuthenticationState.SystemDown;
                        if (e.state === AuthenticationState.InvalidPassword) {
                            reason = e.state;
                        }
                        return of(loginFailure({ reason }));
                    }),
                ),
            ),
        ),
    );

    logout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(logout),
                tap(() => {
                    this.authService.logout();
                }),
            ),
        { dispatch: false },
    );
}
