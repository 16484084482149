export const isEmptyText = (v: string) => !(v && v.trim().length > 0);

export const currentDateString = () => new Date().toISOString().split("T")[0];

export const toString = (value: any) => {
    if (value != null) {
        return value.toString();
    }
    return null;
};

export const removeDiacritics = (str: string) => {
    if (str) {
        return toString(str)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
    }
    return "";
};

export const removeNonAlphanumerical = (value: string | number) => {
    if (value) {
        return toString(value).replace(/[^A-Z0-9]/gi, "");
    }
    return "";
};

export const upperCase = (value: string | number) => {
    if (value) {
        return toString(value).toUpperCase();
    }
    return "";
};

export const removeSpaces = (value: string) => {
    if (value) {
        return toString(value).replace(/\s/g, "");
    }
    return "";
};

export const countWords = (str: string) => {
    if (str) {
        return toString(str).split(" ").length;
    }
    return 0;
};

/**
 * Takes a number value, and prepends it with a zero if it's a single digit.
 * The return value will always be two digits long!
 * @param {number} number
 * @returns {string}
 */
export const doubleDigits = (number: number) => {
    const NUMBER_OF_DIGITS = 2;
    return number.toString().padStart(NUMBER_OF_DIGITS, "0");
};

export const splitOnPosition = (input: string, position: number): [string, string] => {
    return [input.slice(0, position), input.slice(-(input.length - position))];
};

export const postfixLength = (input: string, criteria: (x: string) => boolean): number => {
    const elements = input.split("").reverse();
    let counter = 0;
    while (criteria(elements[counter])) counter++;
    return counter;
};
