import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PackagesComponent } from "./packages.component";
import { PackageDetailComponent } from "./package-detail/package-detail.component";
import { DeletePackageModalComponent } from "./delete-package-modal/delete-package-modal.component";
import { PrintLabelModalComponent } from "./print-label-modal/print-label-modal.component";
import { BulkPrintModalComponent } from "./bulk-print-modal/bulk-print-modal.component";
import { ForcePackageComponent } from "./force-package/force-package.component";
import { CsvUploadModalComponent } from "./csv-upload-modal/csv-upload-modal.component";
import { RetourLabelModalComponent } from "./retour-label-modal/retour-label-modal.component";
import { CopyLabelModalComponent } from "./copy-label-modal/copy-label-modal.component";
import { BulkDeleteModalComponent } from "./bulk-delete-modal/bulk-delete-modal.component";
import { NewPackageComponent } from "./new-package/new-package.component";

import { AuthGuard } from "../services/auth.guard";
import { PackageOwnerGuard } from "../services/package-owner.guard";

const packagesRoutes: Routes = [
    {
        path: "packages",
        component: PackagesComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "packages/create",
        component: NewPackageComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "packages/edit/:package",
        component: NewPackageComponent,
        canActivate: [AuthGuard, PackageOwnerGuard],
    },
    {
        path: "packages(modal:delete-package)",
        component: DeletePackageModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "delete-package",
        component: DeletePackageModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "packages/:id",
        component: PackageDetailComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "packages/search/:input",
        component: PackagesComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "packages(modal:print-label)",
        component: PrintLabelModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "packages(modal:bulk-print)",
        component: BulkPrintModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "packages(modal:bulk-delete)",
        component: BulkDeleteModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "print-label",
        component: PrintLabelModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "retour-label",
        component: RetourLabelModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "copy-label",
        component: CopyLabelModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "bulk-print",
        component: BulkPrintModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "bulk-delete",
        component: BulkDeleteModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "packages(modal:force-package)",
        component: ForcePackageComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "force-package",
        component: ForcePackageComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "packages(modal:csv-upload)",
        component: CsvUploadModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
    {
        path: "csv-upload",
        component: CsvUploadModalComponent,
        outlet: "modal",
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(packagesRoutes)],
    exports: [RouterModule],
})
export class PackagesRoutingModule {}
