import { Action, createReducer, on } from "@ngrx/store";
import * as BusyActions from "../actions/busy.actions";

export type Progress = { total: number; count: number; busy: boolean };

export const initialState: boolean = false;

const messageReducer = createReducer(
    initialState,
    on(BusyActions.startBusy, () => true),
    on(BusyActions.finishBusy, () => false),
);

export function reducer(state: boolean, action: Action) {
    return messageReducer(state, action);
}
