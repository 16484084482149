import { Store } from "@ngrx/store";
import { StatusCodes } from "http-status-codes";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";

import { environment } from "../../environments/environment";
import { AuthenticationState } from "../models/authenticationState";
import { AuthenticationError } from "./auth.error";
import { ExternalServerError, UnauthorizedError, NotFoundError, ConnectionError } from "./errors";
import { E_USER_TYPES } from "../helpers/constants";
import { User } from "../models/user.model";
import { loadCurrentUser } from "../store/actions/current-user.actions";
import { loginSuccess } from "../store/actions/session.actions";
import { getToken } from "../helpers/tokenHelper";

export interface AuthResponse {
    success: boolean;
    token: string;
    user: {
        id: number;
        name: string;
        company_name: string;
        type: number;
        role: number;
        webshop_connector_type: string;
    };
}
export interface Rights {
    can_use_VIATIM: string;
    can_use_DHL: string;
    can_use_POSTNL: string;
    can_use_DPD: string;
}

export interface PreferenceResponse {
    success: boolean;
    rights: Rights;
    preferences: {
        webshop_connector_type: string;
    };
}

interface RestResult {
    success: boolean;
}

@Injectable()
export class AuthService {
    currentUser$: Observable<User> = this.store.select((state) => state.currentUser);

    currentUser: User;

    constructor(
        private http: HttpClient,
        private store: Store<{ currentUser: User }>,
        private router: Router,
    ) {
        this.currentUser$.subscribe((user) => {
            this.currentUser = user;
        });
    }

    authenticate(username: string, password: string, userId?: number): Observable<string> {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });

        const params: any = {};

        if (userId) {
            params.user_id = userId;
        }

        const options = { headers, observe: "response" as "response", params };

        return this.http
            .post<AuthResponse>(
                `${environment.apiBase}/auth`,
                { username, password, userId },
                options,
            )
            .pipe(
                map((response) => {
                    if (response.status === StatusCodes.OK) {
                        const result = <AuthResponse>response.body;
                        if (result.success) {
                            localStorage.setItem("jwt", result.token);
                            return result.token;
                        }
                    }
                    throw new AuthenticationError(AuthenticationState.InvalidPassword);
                }),
                catchError((response) => {
                    if (response.status === StatusCodes.FORBIDDEN) {
                        throw new AuthenticationError(AuthenticationState.InvalidPassword);
                    }
                    throw new AuthenticationError(AuthenticationState.SystemDown);
                }),
            );
    }

    logout() {
        localStorage.removeItem("jwt");
        setTimeout(() => this.router.navigate(["/login"]), 0);
    }

    localAuthenticate() {
        const token = getToken();
        if (token) {
            this.store.dispatch(loginSuccess({ token }));
            this.store.dispatch(loadCurrentUser());
        } else {
            setTimeout(() => this.router.navigate(["/login"]), 0);
        }
    }

    forgotPassword(username: string): Observable<void> {
        const options = { observe: "response" as "response" };
        return this.http
            .post(`${environment.apiBase}/public/accounts/forgot-password`, { username }, options)
            .pipe(
                map((response) => {
                    if (response.status === StatusCodes.OK) {
                        return;
                    }
                    throw new ExternalServerError();
                }),
                catchError((e) => {
                    switch (e.status) {
                        case StatusCodes.UNAUTHORIZED:
                            throw new UnauthorizedError();
                        case StatusCodes.NOT_FOUND:
                            throw new NotFoundError();
                        case StatusCodes.INTERNAL_SERVER_ERROR:
                            throw new ExternalServerError();
                        default:
                            throw new ConnectionError();
                    }
                }),
            );
    }

    resetPassword(password: string, key: string): Observable<void> {
        const headers = new HttpHeaders({ Accept: "application/json" });
        const options = { headers, observe: "response" as "response" };
        const data = {
            password,
            key,
        };
        return this.http
            .post<RestResult>(
                `${environment.apiBase}/public/accounts/reset-password/`,
                data,
                options,
            )
            .pipe(
                map((response) => {
                    if (response.status === StatusCodes.OK) {
                        return;
                    }
                    throw new ExternalServerError();
                }),
                catchError((e) => {
                    switch (e.status) {
                        case StatusCodes.FORBIDDEN:
                            throw new UnauthorizedError();
                        case StatusCodes.INTERNAL_SERVER_ERROR:
                            throw new ExternalServerError();
                        default:
                            throw new ConnectionError();
                    }
                }),
            );
    }

    getOwnUserId() {
        if (this.currentUser) {
            return this.currentUser.id;
        }
        return 0;
    }

    getOwnUserName() {
        if (this.currentUser) {
            return this.currentUser.userName;
        }
        return "NO CURRENT USER";
    }

    getUserRights(): Observable<PreferenceResponse> {
        return this.http.get<PreferenceResponse>(`${environment.apiBase}/users/preferences/`);
    }

    getAccountRole() {
        if (this.currentUser) {
            return this.currentUser.type;
        }
        return E_USER_TYPES.NON_INTEGRATED_USER;
    }
}
