import { StatusCodes } from "http-status-codes";
import { Component, OnInit } from "@angular/core";
import { parse } from "papaparse";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Message } from "../../../models/message";
import { addMessage } from "../../../store/actions/message.actions";
import { UploadStatus, UPLOAD_STATUS } from "../../../models/uploadStatus.model";
import { DHLSyncService, UploadDHLLabel } from "../../../services/dhl-sync.service";
import { UserSaveJSON } from "../../../models/json/userSaveJSON";
import { MAX_LENGTH } from "../../../helpers/constants/fieldSize";
import { removeSpaces } from "../../../helpers/textHelper";
import { finishBusy, startBusy } from "src/app/store/actions/busy.actions";

type CSVLine = {
    barcode: string;
    receiverName: string;
    receiverStreetname: string;
    receiverHouseNumber: string;
    receiverHouseNumberAddition: string;
    receiverPostcode: string;
    receiverCity: string;
    receiverCountry: string;
    receiverEmail: string;
    receiverPhone: string;
    customerNumber: string;
    senderCompany: string;
    senderStreetname: string;
    senderHouseNumber: string;
    senderHouseNumberAddition: string;
    senderPostcode: string;
    senderCity: string;
    senderCountry: string;
    senderEmail: string;
    senderPhone: string;
    product: string;
    mark: string;
};

export enum CSV_UPLOAD_STATUS {
    NO_FILE_SELECTED,
    CSV_UPLOADING,
    CSV_UPLOADED,
    CSV_PARSED,
    CSV_IN_PROGRESS,
    ADDING_LABELS,
    LABELS_ADDED,
    CSV_UPLOAD_FAILED,
}

@Component({
    selector: "app-sync-labels",
    templateUrl: "./sync-labels.component.html",
    styleUrls: ["./sync-labels.component.scss"],
})
export class SyncLabelsComponent implements OnInit {
    public status: CSV_UPLOAD_STATUS = CSV_UPLOAD_STATUS.NO_FILE_SELECTED;

    public fileName = "";

    public labels: CSVLine[] = [];

    public date: string;

    public uploadStatus: UploadStatus | null = null;

    public validationErrors: any;

    ngOnInit() {
        this.updateLatestUpdateStatus();
    }

    updateLatestUpdateStatus() {
        this.backend.getLatestUploadStatus().subscribe((res) => {
            this.uploadStatus = res;
        });
    }

    constructor(
        private backend: DHLSyncService,
        private router: Router,
        private store: Store<{ messages: Message[] }>,
    ) {}

    changeListener = (event: Event): void => {
        const target = <HTMLInputElement>event.target;
        const file = target.files[0];
        this.status = file ? CSV_UPLOAD_STATUS.CSV_UPLOADING : CSV_UPLOAD_STATUS.NO_FILE_SELECTED;
        if (this.status === CSV_UPLOAD_STATUS.CSV_UPLOADING) {
            this.readThis(event.target);
            this.fileName = file.name;
        }
    };

    readThis = (inputValue: any): void => {
        let result: { data: Array<{ postcode: string; barcode: string }> };
        const file: File = inputValue.files[0];
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = () => {
            const input = <string>fileReader.result;
            result = parse(input, {
                skipEmptyLines: true,
                header: true,
                dynamicTyping: false,
                transformHeader: (header: string) => header.split("*").join("").toLowerCase(),
            });
            this.labels = result.data.map((e) => ({
                barcode: e["track en trace code"],
                postcode: e["postcode ontvanger"],
                receiverName: e["ontvanger"] || "unknown",
                receiverStreetname: e["straatnaam ontvanger"] || "unknown",
                receiverHouseNumber: e["huisnummer ontvanger"],
                receiverHouseNumberAddition: e["toevoeging ontvanger"],
                receiverPostcode: e["postcode ontvanger"],
                receiverCity: e["plaatsnaam ontvanger"],
                receiverCountry: e["landcode ontvanger"],
                receiverEmail: e["e-mailadres ontvanger"] || "unknown@email.com",
                receiverPhone: e["telefoonnummer ontvanger"],
                customerNumber: e["klantnummer"],
                senderCompany: e["bedrijfsnaam verzender"],
                senderStreetname: e["straatnaam verzender"] || "unknown",
                senderHouseNumber: e["huisnummer verzender"] || "unknown",
                senderHouseNumberAddition: e["toevoeging verzender"],
                senderPostcode: e["postcode verzender"],
                senderCity: e["plaatsnaam verzender"],
                senderCountry: e["landcode verzender"],
                senderEmail: e["e-mailadres verzender"] || "unknown@email.com",
                senderPhone: e["telefoonnummer verzender"],
                mark: e["referenties"],
                product: e["product"],
            }));
            this.status = CSV_UPLOAD_STATUS.CSV_PARSED;
        };
        fileReader.readAsText(file);
    };

    get csvIsParsed(): boolean {
        return (
            this.status === CSV_UPLOAD_STATUS.CSV_PARSED ||
            this.status === CSV_UPLOAD_STATUS.ADDING_LABELS ||
            this.status === CSV_UPLOAD_STATUS.CSV_UPLOAD_FAILED
        );
    }

    get inProgress(): boolean {
        return (
            this.status === CSV_UPLOAD_STATUS.CSV_UPLOADING ||
            this.status === CSV_UPLOAD_STATUS.ADDING_LABELS
        );
    }

    get csvIsLoading(): boolean {
        return this.status === CSV_UPLOAD_STATUS.CSV_UPLOADING;
    }

    get csvInProgress(): boolean {
        return this.status === CSV_UPLOAD_STATUS.CSV_IN_PROGRESS;
    }

    buildInput = () => {
        this.status = CSV_UPLOAD_STATUS.ADDING_LABELS;
        let csvJSONarray: any = [];
        csvJSONarray = this.labels
            .filter((line: CSVLine) => line.barcode && line.receiverPostcode)
            .map((line: CSVLine): UploadDHLLabel => {
                let sender: UserSaveJSON | string = line.senderCompany;
                if (line.senderPostcode) {
                    sender = {
                        postcode: line.senderPostcode,
                        housenr: line.senderHouseNumber,
                        housenr_extra: line.senderHouseNumberAddition?.substring(
                            0,
                            MAX_LENGTH.HOUSENR_EXTRA,
                        ),
                        address_extra: "",
                        company_name: line.senderCompany?.substring(0, MAX_LENGTH.COMPANY_NAME),
                        city: line.senderCity,
                        country: line.senderCountry,
                        streetname: line.senderStreetname,
                        phone: line.senderPhone.substring(0, MAX_LENGTH.PHONE),
                        initials: "",
                        firstname: "",
                        middlename: "",
                        lastname: "Unknown",
                        email: removeSpaces(line.senderEmail),
                    };
                }
                return {
                    recipient: {
                        postcode: line.receiverPostcode,
                        housenr: line.receiverHouseNumber,
                        housenr_extra: line.receiverHouseNumberAddition?.substring(
                            0,
                            MAX_LENGTH.HOUSENR_EXTRA,
                        ),
                        address_extra: "",
                        company_name: "",
                        city: line.receiverCity,
                        country: line.receiverCountry,
                        streetname: line.receiverStreetname,
                        phone: line.receiverPhone.substring(0, MAX_LENGTH.PHONE),
                        initials: "",
                        firstname: "",
                        middlename: "",
                        lastname: line.receiverName.substring(0, MAX_LENGTH.LASTNAME),
                        email: removeSpaces(line.receiverEmail),
                    },
                    sender,
                    reference: line.barcode,
                    customer_number: line.customerNumber,
                    mark: line.mark,
                };
            });
        this.makeLabelBulk(csvJSONarray);
    };

    static colorRow = (rowNumber: any, status: string) => {
        const table = document.getElementById("table");
        const element = <HTMLTableRowElement>table.children[rowNumber];
        element.className = status;
    };

    close = () => {
        this.router.navigateByUrl("/dashboard");
    };

    makeLabelBulk = (labels: UploadDHLLabel[]) => {
        this.store.dispatch(startBusy());
        if (labels) {
            this.backend.syncDHLLabels(labels).subscribe({
                next: (d) => {
                    this.store.dispatch(finishBusy());
                    if (d.amount_to_upload > 0) {
                        this.status = CSV_UPLOAD_STATUS.CSV_IN_PROGRESS;
                        this.store.dispatch(
                            addMessage({
                                message: Message.createSuccessMessage(
                                    "Succes",
                                    `De CSV upload is bezig! ${
                                        labels.length
                                    } records worden toegevoegd. ${
                                        this.labels.length - labels.length
                                    } elementen waren niet correct. Je kan het modal sluiten.`,
                                ),
                            }),
                        );
                        this.updateLatestUpdateStatus();
                    }
                    if (d.status == UPLOAD_STATUS.STALLED) {
                        this.status = CSV_UPLOAD_STATUS.CSV_UPLOAD_FAILED;
                    }
                },
                error: (e) => {
                    this.store.dispatch(finishBusy());
                    if (e.status === StatusCodes.UNPROCESSABLE_ENTITY && e.error?.errors) {
                        this.validationErrors = e.error.errors;
                    }

                    this.status = CSV_UPLOAD_STATUS.CSV_UPLOAD_FAILED;
                    this.store.dispatch(
                        addMessage({
                            message: Message.createErrorMessage("Fout", "De CSV upload is mislukt"),
                        }),
                    );
                },
            });
        } else {
            this.store.dispatch(
                addMessage({
                    message: Message.createErrorMessage(
                        "Fout",
                        "Er zijn geen correcte records gevonden in de CSV",
                    ),
                }),
            );
        }
    };
}
