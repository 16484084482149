import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { User } from "../models/user.model";

export interface Point {
    lat: number;
    lon: number;
}

export interface WebShop {
    id: number;
    date_created: string;
    postcode: string;
    housenr: string;
    housenr_extra: string;
    city: string;
    streetname: string;
    address_extra: string;
    phone: string;
    type: number;
    initials: string;
    firstname: string;
    middlename: string;
    lastname: string;
    fullname: string;
    email: string;
    company_name: string;
    country: string;
    gps_coords: Point;
}

export interface ContactResults {
    success: boolean;
    contacts: any;
    webshop: WebShop;
}

export interface Contacts {
    success: boolean;
    contacts: User[];
    webshop: WebShop;
}

@Injectable()
export class ContactService {
    constructor(private httpClient: HttpClient) {}

    // Get Contacts
    getContacts(): Observable<Contacts> {
        const url = `${environment.apiBase}/contacts/`;
        return this.httpClient.get<ContactResults>(url).pipe(
            map((response) => {
                response.contacts = response.contacts.map(User.fromJSON);
                return response;
            }),
        );
    }
}
