<div class="modal-container" (click)="closeModal()">
    <section (click)="$event.stopPropagation()" class="modal__component">
        <div class="header">
            <h1>{{ title }}</h1>
            <div>
                <button class="close-button" (click)="closeModal()">
                    <i class="far fa-times fa-lg"></i>
                </button>
            </div>
        </div>

        <div class="content">
            <ng-content></ng-content>
        </div>
    </section>
</div>
