import { Message } from "../models/message";
import { ERROR_TRANSLATION, ReasonedValidationError } from "./constants";

export const getErrorMessages = (e: ReasonedValidationError[]) => {
    if (e) {
        return e.map((error) => {
            const { reason } = error;
            const field = error.fields[0].split(".")[1];
            let errorMessage = ERROR_TRANSLATION[reason][field];
            if (!errorMessage) {
                errorMessage = ERROR_TRANSLATION[reason].default;
                if (!errorMessage) {
                    errorMessage = ERROR_TRANSLATION.default;
                }
            }
            return errorMessage;
        });
    }
    return [];
};

export const getValidationErrorMessages = (e: ReasonedValidationError[]) => {
    const errorMessages = getErrorMessages(e);
    return errorMessages.map((em) => Message.createErrorMessage("Validatiefout", em));
};
