import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
    selector: "app-forgot-password-form",
    templateUrl: "./forgot-password-form.component.html",
    styleUrls: ["./forgot-password-form.component.scss"],
})
export class ForgotPasswordFormComponent {
    username = "";

    @Input() error: string;

    @Output() resetEvent = new EventEmitter<string>();

    submit(): void {
        this.resetEvent.emit(this.username);
    }
}
