<app-cp-modal title="Statistieken">
    <section>
        <div class="buttons">
            <button (click)="refresh()" id="bulkBtn" class="btn add" tabindex="1">
                <span><i class="fas fa-undo"></i></span>Refresh
            </button>
            <br /><br />
        </div>

        <h1>Pakketten niet opgehaald</h1>
        <button
            (click)="getPackages('status=0&concept=false&limit=1000&dateStart=', today)"
            id="bulkBtn"
            class="btn add"
            tabindex="1"
        >
            <span>{{ statistics["noPickupToday"] }}</span
            >Pakketten niet opgehaald (vandaag)
        </button>
        <br /><br />
        <button
            (click)="getPackages('status=0&concept=false&limit=1000&dateStart=', lastWeek)"
            id="bulkBtn"
            class="btn add"
            tabindex="1"
        >
            <span>{{ statistics["noPickupLastWeek"] }}</span
            >Pakketten niet opgehaald (afgelopen 7 dagen)
        </button>
        <br /><br />
        <button
            *ngIf="canSeePackages"
            (click)="getPackages('status=0&concept=false&limit=1000&dateStart=', allTime)"
            id="bulkBtn"
            class="btn add"
            tabindex="1"
        >
            <span>{{ statistics["noPickupAllTime"] }}</span
            >Pakketten niet opgehaald (totaal)
        </button>

        <h1>Pakketten nog onderweg</h1>
        <button
            (click)="
                getPackages('status=1,2,3,5,6,7,8,9,10&concept=false&limit=1000&dateStart=', today)
            "
            id="bulkBtn"
            class="btn add"
            tabindex="1"
        >
            <span>{{ statistics["notDeliveredToday"] }}</span
            >Pakketten zijn nog onderweg (vandaag)
        </button>
        <br /><br />
        <button
            (click)="
                getPackages(
                    'status=1,2,3,5,6,7,8,9,10&concept=false&limit=1000&dateStart=',
                    lastWeek
                )
            "
            id="bulkBtn"
            class="btn add"
            tabindex="1"
        >
            <span>{{ statistics["notDeliveredLastWeek"] }}</span
            >Pakketten zijn nog onderweg (afgelopen 7 dagen)
        </button>
        <br /><br />
        <button
            *ngIf="canSeePackages"
            (click)="
                getPackages(
                    'status=1,2,3,5,6,7,8,9,10&concept=false&limit=1000&dateStart=',
                    allTime
                )
            "
            id="bulkBtn"
            class="btn add"
            tabindex="1"
        >
            <span>{{ statistics["notDeliveredAllTime"] }}</span
            >Pakketten zijn nog onderweg (totaal)
        </button>

        <br />
        <br />
        <br />

        <div *ngIf="showPackages" id="table">
            <table *ngIf="canSeePackages">
                <th>Webshop Naam</th>
                <th>Webshop Aantal</th>
                <tr *ngFor="let stat of stats">
                    <td>{{ stat.company_name }}</td>
                    <td>{{ stat.count }}</td>
                </tr>
            </table>

            <table>
                <tr>
                    <th>#</th>
                    <th>Aangemaakt</th>
                    <th>Bedrijfsnaam</th>
                    <th>Voornaam</th>
                    <th>Tussenvoegsel</th>
                    <th>Achternaam</th>
                    <th>Postcode</th>
                    <th>Huisnummer</th>
                    <th>Toevoeging</th>
                    <th></th>
                </tr>
                <tr *ngFor="let package of packages">
                    <td>{{ package.id }}</td>
                    <td>{{ package.dateCreated | splitDate }}</td>
                    <td>{{ package.recipient.companyName }}</td>
                    <td>{{ package.recipient.firstname }}</td>
                    <td>{{ package.recipient.middlename }}</td>
                    <td>{{ package.recipient.lastname }}</td>
                    <td>{{ package.recipient.postcode }}</td>
                    <td>{{ package.recipient.houseNumber }}</td>
                    <td>{{ package.recipient.houseNumberExtra }}</td>

                    <td>
                        <button
                            (click)="detailPage(package.tracktrace)"
                            id="bulkBtn"
                            class="btn small"
                            tabindex="1"
                        >
                            <i class="fa fa-info fa-sm"></i>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </section>
</app-cp-modal>
