import { GPSCoordinatesJSON } from "./json/gpsCoordinatesJSON";

export class GPSCoordinates {
    constructor(public lat: number, public lon: number) {}

    static fromJSON(json: GPSCoordinatesJSON): GPSCoordinates {
        return new GPSCoordinates(json.lat, json.lon);
    }

    toJSON(): GPSCoordinatesJSON {
        return {
            lat: this.lat,
            lon: this.lon,
        };
    }
}
