import { Component, Input } from "@angular/core";

@Component({
    selector: "app-yes-or-no-icon",
    templateUrl: "./yes-or-no-icon.component.html",
    styleUrls: ["./yes-or-no-icon.component.scss"],
})
export class YesOrNoIconComponent {
    @Input()
    value: boolean;
}
