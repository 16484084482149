<nav class="sidebar" [ngClass]="{ 'sidebar__nav-toggle': collapsed }">
    <app-viatim-icon></app-viatim-icon>
    <!-- Xmas Theme -->
    <!-- <img src="../../../assets/svg/christmasLogo.svg" class="logo-viatim" alt="christmaslogo" *ngIf="isXmasTime" version="1.2" [ngClass]="{'logo-viatim__logo-toggle':collapsed}"> -->
    <!-- normal logo -->
    <!-- <img src="../../../assets/svg/logoVA.svg" class="logo-viatim" alt="normal logo" *ngIf="!isXmasTime" [ngClass]="{'logo-viatim__logo-toggle':collapsed}"> -->

    <div class="welcome" *ngIf="!collapsed && (currentUser$ | async) as currentUser">
        Welkom
        <a class="welcome__icon-link" routerLink="/account-management" routerLinkActive="active">
            <span class="welcome__name">{{ currentUser.getFullName() }}</span>
            <i class="fad fa-user-circle fa-lg"></i>
        </a>
    </div>
    <div class="nav-links">
        <ul class="nav-primary" [ngClass]="{ 'nav-links__center-icon': collapsed }">
            <!-- Dashboard -->
            <li *ngIf="showDashboard">
                <a
                    class="nav-links__icon-link"
                    routerLink="/dashboard"
                    routerLinkActive="active"
                    data-text="Dashboard"
                >
                    <i class="fad fa-home fa-lg"></i>
                    <span *ngIf="!collapsed">Dashboard</span>
                </a>
            </li>
            <!-- Packages -->
            <a
                class="nav-links__icon-link"
                routerLink="/packages"
                routerLinkActive="active"
                data-text="Pakketten"
            >
                <i class="fad fa-box fa-lg"></i>
                <span *ngIf="!collapsed">Pakketten</span>
            </a>
            <!-- Webshops -->
            <li *ngIf="showWebshops">
                <a
                    class="nav-links__icon-link"
                    routerLink="/webshops"
                    routerLinkActive="active"
                    data-text="Webshops"
                >
                    <i class="fad fa-shopping-cart fa-lg"></i>
                    <span *ngIf="!collapsed">Webshops</span>
                </a>
            </li>
            <!-- Contacts -->
            <li *ngIf="showContacts">
                <a class="nav-links__icon-link" routerLink="/contacts" routerLinkActive="active">
                    <i class="fad fa-address-book fa-lg"></i>
                    <span *ngIf="!collapsed">Adresboek</span>
                </a>
            </li>
            <li *ngIf="showInvoicing">
                <a class="nav-links__icon-link" routerLink="/invoices" routerLinkActive="active">
                    <i class="fad fa-file-invoice-dollar fa-lg"></i>
                    <span *ngIf="!collapsed">Facturatie</span>
                </a>
            </li>
        </ul>

        <ul class="nav-secondary" [ngClass]="{ 'nav-links__center-icon': collapsed }">
            <!-- Logout -->
            <li>
                <a
                    class="nav-links__icon-link"
                    routerLink="/login"
                    (click)="logout()"
                    data-text="Logout"
                >
                    <i class="fal fa-reply fa-lg"></i>
                    <span *ngIf="!collapsed">Logout</span>
                </a>
            </li>
        </ul>
    </div>
    <span class="toggler-sidebar">
        <a
            class="nav-links__icon-link"
            (click)="toggleSideBar()"
            [ngClass]="{ 'toggler-sidebar__icon-flip': collapsed }"
        >
            <i class="fal fa-arrow-to-left fa-lg"></i>
        </a>
    </span>
</nav>
<main>
    <ng-content> </ng-content>
</main>
