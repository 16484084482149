import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { externalIdToCode } from "../../helpers/constants/transporters";

@Component({
    selector: "app-external-icon",
    templateUrl: "./external-icon.component.html",
    styleUrls: ["./external-icon.component.css"],
})
export class ExternalIconsComponent {
    @Input()
    external: string | number;

    constructor(private sanitizer: DomSanitizer) {}

    get externalUrl() {
        let name = externalIdToCode(this.external);
        if (name === "MYPARCEL" || name === "PARCELPRO") {
            name = "POSTNL";
        }
        return this.sanitizer.bypassSecurityTrustStyle(`url(assets/icons/${name}-icon.svg)`);
    }
}
