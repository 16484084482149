import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { Message } from "../../models/message";
import { deleteMessage } from "../../store/actions/message.actions";

@Component({
    selector: "app-flash-message",
    templateUrl: "./flash-message.component.html",
    styleUrls: ["./flash-message.component.scss"],
})
export class FlashMessageComponent {
    messages$: Observable<Message[]> = this.store.select((state) => state.messages);

    constructor(private store: Store<{ messages: Message[] }>) {}

    deleteMessage(id: number) {
        this.store.dispatch(deleteMessage({ id }));
    }
}
