import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { ControlPanelPageComponent } from "./control-panel-page/control-panel-page.component";
import { ControlPanelModalComponent } from "./control-panel-modal/control-panel-modal.component";
import { ControlPanelWarningComponent } from "./control-panel-warning/control-panel-warning.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule],
    declarations: [
        ControlPanelPageComponent,
        ControlPanelModalComponent,
        ControlPanelWarningComponent,
    ],
    exports: [ControlPanelPageComponent, ControlPanelModalComponent, ControlPanelWarningComponent],
})
export class ControlPanelModule {}
