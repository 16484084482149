import { StatusCodes } from "http-status-codes";
import { omitBy, isNull } from "lodash-es";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Store } from "@ngrx/store";
import { map, catchError } from "rxjs/operators";
import { ViaWebshop } from "../models/viawebshop.model";
import { ViaBode } from "../models/viabode.model";
import { User } from "../models/user.model";
import { Package } from "../models/package.model";
import { AuthService } from "./auth.service";
import { E_USER_TYPES, DEFAULT_PAGE_SIZE } from "../helpers/constants";
import { environment } from "../../environments/environment";
import { PackageJSON } from "../models/json/packageJSON";
import { AddressResultJson } from "../models/json/addressResultJson";
import { UserJSON } from "../models/json/userJSON";
import { Message } from "../models/message";
import { addMessage } from "../store/actions/message.actions";
import { ServicePointJSON } from "../models/json/servicePointJSON";

import {
    UnauthorizedError,
    NotFoundError,
    ExternalServerError,
    ConnectionError,
    ConflictError,
    ValidationError,
} from "./errors";
import { ServicePoint } from "../models/servicePoint.model";
import { UserPageResults } from "../models/pageResults";
import { TransporterJSON } from "../models/json/transporterJSON";
import { Transporter } from "../models/transporter.model";
import { ViaWebshopJSON } from "../models/json/viawebshopJSON";
import { ViawebshopUpdateJSON } from "../models/json/viawebshopUpdateJSON";
import { getToken } from "../helpers/tokenHelper";
import { UserSaveJSON } from "../models/json/userSaveJSON";
import { DefaultWebshopConfig } from "../models/defaultWebshopConfig.model";
import { DefaultWebshopConfigJSON } from "../models/json/defaultWebshopConfigJSON";
import {
    WebshopTransporterConfigJSON,
    WebshopTransporterConfig,
} from "../models/webshopTransporterConfig.model";

interface RestResult {
    success: boolean;
}

interface GetPackageResult extends RestResult {
    package: PackageJSON;
}

interface GetViaWebshopResult extends RestResult {
    user: ViaWebshopJSON;
}

interface GetPackagesResult extends RestResult {
    packages: PackageJSON[];
    amount: number;
}

interface GetAddressByPostcodeResult extends RestResult {
    data: AddressResultJson;
}

interface BulkRowResult {
    row: number;
    package: PackageJSON;
    success: boolean;
    validation_errors: ValidationError[];
}

interface PostBulkResult {
    errorAmount: number;
    errorPackages: BulkRowResult[];
    succesfullAmount: number;
    succesfullPackages: BulkRowResult[];
    success: string;
}

interface CapabilitiesResult extends RestResult {
    results: {
        capabilities: string[];
        parcel_type: string;
    }[];
}

interface TrackingEventJSON {
    external: {
        status: string;
        name: string;
        number: string;
        reference: string;
        timestamp: string;
    };
    status: number;
    timestamp: string;
    user: UserJSON;
}

interface GetTrackingHistoryResult extends RestResult {
    amount: number;
    history: TrackingEventJSON[];
}

interface GetStatusTranslationResult extends RestResult {
    history: string[];
}

interface PackageStatsJSON {
    count: string;
    firstname: string;
    owner_id: number;
}

interface GetPackageStatsResult extends RestResult {
    total: number;
    data: PackageStatsJSON[];
}

const toRelevantUserObject = (user: UserJSON) => {
    if (user.viawebshop) {
        return ViaWebshop.fromJSON(user);
    }
    return User.fromJSON(user);
};

@Injectable()
export class BackendService {
    constructor(
        private httpClient: HttpClient,
        private auth: AuthService,
        private store: Store<{ messages: Message[] }>,
    ) {}

    getTransporters(): Observable<Transporter[]> {
        return this.httpClient
            .get<TransporterJSON[]>(`${environment.apiBase}/transporters`)
            .pipe(map((result) => result.map(Transporter.fromJSON)));
    }

    getPackageByTrackTrace(tracktrace: string): Observable<Package> {
        return this.httpClient
            .get<GetPackageResult>(`${environment.apiBase}/packages/tracktrace/${tracktrace}`)
            .pipe(map((result) => Package.fromJSON(result.package)));
    }

    showMessage(status: boolean, content: string): void {
        let message: Message;
        if (status) {
            message = Message.createSuccessMessage("SUCCESS", content);
        } else {
            message = Message.createErrorMessage("FOUT", content);
        }
        this.store.dispatch(addMessage({ message }));
    }

    getAddressByPostcode(
        postcode: string,
        housenr: string,
        housenr_extra?: string,
    ): Observable<AddressResultJson> {
        let url = `${environment.apiBase}/postcode/${postcode}/${housenr}`;
        if (housenr_extra) {
            url = `${url}/${housenr_extra}`;
        }
        return this.httpClient
            .get<GetAddressByPostcodeResult>(url)
            .pipe(map((result) => result.data));
    }

    updatePackage(
        status: number,
        id: number,
        user_id: number = this.auth.getOwnUserId(),
    ): Observable<boolean> {
        const details = {
            user_id,
            status,
            gps_lat: 0,
            gps_lon: 0,
        };
        return this.httpClient
            .post<RestResult>(`${environment.apiBase}/tracking/update/${id}`, details)
            .pipe(map((result) => result.success));
    }

    editPackage(parcel: Package): Observable<boolean> {
        const json = parcel.toSaveJSON();
        const data = omitBy(json, isNull);
        return this.httpClient
            .put<RestResult>(`${environment.apiBase}/packages/${parcel.id}`, data)
            .pipe(map((result) => result.success));
    }

    editUser(id: number, data: UserSaveJSON): Observable<boolean> {
        return this.httpClient
            .patch<RestResult>(`${environment.apiBase}/users/${id}`, data)
            .pipe(map((result) => result.success));
    }

    editViawebshop(id: number, data: ViawebshopUpdateJSON): Observable<boolean> {
        return this.httpClient
            .put<RestResult>(`${environment.apiBase}/users/viawebshop/${id}`, data)
            .pipe(map((result) => result.success));
    }

    editAccount(p: any): Observable<boolean> {
        return this.httpClient
            .patch<RestResult>(`${environment.apiBase}/accounts/${p.id}`, p)
            .pipe(map((result) => result.success));
    }

    deletePackage(tracktrace: string): Observable<boolean> {
        return this.httpClient
            .delete<RestResult>(`${environment.apiBase}/packages/tracktrace/${tracktrace}`)
            .pipe(map((result) => result.success));
    }

    getUserPackages(userId: number): Observable<Package[]> {
        const params = {
            id: userId,
        };
        return this.httpClient
            .get<GetPackagesResult>(`${environment.apiBase}/users/packages`, { params })
            .pipe(map((result) => result.packages.map(Package.fromJSON)));
    }

    createUserPreferences(userId: number, preference: string, value: string): Observable<boolean> {
        const data = {
            user_id: userId,
            preference,
            value,
            user_changeable: false,
        };
        return this.httpClient
            .post<RestResult>(`${environment.apiBase}/users/preferences/`, data)
            .pipe(map((result) => result.success));
    }

    getCurrentUser(): Observable<User> {
        return this.httpClient
            .get<UserJSON>(`${environment.apiBase}/users/current`)
            .pipe(map(toRelevantUserObject));
    }

    getViaWebshopById(id: number): Observable<ViaWebshop> {
        return this.httpClient
            .get<GetViaWebshopResult>(`${environment.apiBase}/users/${id}`)
            .pipe(map((result) => ViaWebshop.fromJSON(result.user)));
    }

    getCapabilitiesByPackageInformation(
        country: string,
        externalId: number,
        destinationType: string,
    ): Observable<
        {
            capabilities: string[];
            parcel_type: string;
        }[]
    > {
        const headers = new HttpHeaders({
            Accept: "application/json",
            "x-access-token": getToken(),
        });
        const params = {
            country,
            external_id: externalId.toString(),
            destinationType,
        };
        const options = { headers, params };

        return this.httpClient
            .get<CapabilitiesResult>(`${environment.apiBase}/packages/capabilities`, options)
            .pipe(map(({ results }) => results));
    }

    createPackage(parcel: Package): Observable<boolean> {
        return this.httpClient
            .post<RestResult>(`${environment.apiBase}/packages`, parcel.toSaveJSON())
            .pipe(map((result) => result.success));
    }

    createBulkPackage(parcels: Array<Package>): Observable<PostBulkResult> {
        return this.httpClient.post<PostBulkResult>(`${environment.apiBase}/packages/csv`, parcels);
    }

    createViabode(vb: ViaBode): Observable<boolean> {
        const options = { observe: "response" as "response" };
        const data = vb.toCreateJSON();

        return this.httpClient
            .post<RestResult>(`${environment.apiBase}/users/viabode`, data, options)
            .pipe(
                map((response) => {
                    if (response.status === StatusCodes.CREATED) {
                        return true;
                    }
                    throw new ExternalServerError();
                }),
                catchError((e) => {
                    switch (e.status) {
                        case StatusCodes.UNAUTHORIZED:
                            throw new UnauthorizedError();
                        case StatusCodes.CONFLICT:
                            throw new ConflictError();
                        case StatusCodes.NOT_FOUND:
                            throw new NotFoundError();
                        case StatusCodes.INTERNAL_SERVER_ERROR:
                            throw new ExternalServerError();
                        case StatusCodes.UNPROCESSABLE_ENTITY:
                            throw new ValidationError(e);
                        default:
                            throw new ConnectionError();
                    }
                }),
            );
    }

    createViawebshop(vw: ViaWebshop): Observable<boolean> {
        const options = { observe: "response" as "response" };
        const data = vw.toCreateJSON();

        return this.httpClient
            .post<RestResult>(`${environment.apiBase}/users/viawebshop`, data, options)
            .pipe(
                map((response) => {
                    if (response.status === StatusCodes.CREATED) {
                        return true;
                    }
                    throw new ExternalServerError();
                }),
                catchError((e) => {
                    switch (e.status) {
                        case StatusCodes.UNAUTHORIZED:
                            throw new UnauthorizedError();
                        case StatusCodes.CONFLICT:
                            throw new ConflictError();
                        case StatusCodes.NOT_FOUND:
                            throw new NotFoundError();
                        case StatusCodes.INTERNAL_SERVER_ERROR:
                            throw new ExternalServerError();
                        case StatusCodes.UNPROCESSABLE_ENTITY:
                            throw new ValidationError(e);
                        default:
                            throw new ConnectionError();
                    }
                }),
            );
    }

    isBlobError(err: any) {
        return (
            err instanceof HttpErrorResponse &&
            err.error instanceof Blob &&
            err.error.type === "application/json"
        );
    }

    parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
        const obs = new Observable((observer: any) => {
            reader.onloadend = () => {
                observer.error(
                    new HttpErrorResponse({
                        ...err,
                        error: JSON.parse(reader.result as string),
                    }),
                );
                observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }

    getPackageLabel(tracktrace: string): Observable<Blob> {
        const headers = new HttpHeaders({ "Content-Type": "application/pdf" });
        const options = { headers, responseType: "blob" as "blob" };

        return this.httpClient
            .get(`${environment.apiBase}/packages/label/${tracktrace}`, options)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    return !!this.isBlobError(response)
                        ? this.parseErrorBlob(response)
                        : throwError(response);
                }),
            )
            .pipe(
                catchError((e) => {
                    switch (e.status) {
                        case StatusCodes.UNAUTHORIZED:
                            throw new UnauthorizedError();
                        case StatusCodes.CONFLICT:
                            throw new ConflictError();
                        case StatusCodes.NOT_FOUND:
                            throw new NotFoundError();
                        case StatusCodes.INTERNAL_SERVER_ERROR:
                            throw new ExternalServerError();
                        case StatusCodes.UNPROCESSABLE_ENTITY:
                            const error = e.error[0];
                            const input = {
                                message: "VALIDATION ERROR",
                                error: {
                                    fields: error.fields,
                                    reason: error.reason,
                                },
                            };
                            throw new ValidationError(input);
                        default:
                            throw new ConnectionError();
                    }
                }),
            );
    }

    getReturnLabels(count: number, email: string, parcelType: string): Observable<Blob> {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const body = { count, email, parcelType };
        const options = { headers, responseType: "blob" as "blob" };

        return this.httpClient
            .post(`${environment.apiBase}/packages/return-labels`, body, options)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    return !!this.isBlobError(response)
                        ? this.parseErrorBlob(response)
                        : throwError(response);
                }),
            )
            .pipe(
                catchError((e) => {
                    switch (e.status) {
                        case StatusCodes.UNAUTHORIZED:
                            throw new UnauthorizedError();
                        case StatusCodes.CONFLICT:
                            throw new ConflictError();
                        case StatusCodes.NOT_FOUND:
                            throw new NotFoundError();
                        case StatusCodes.INTERNAL_SERVER_ERROR:
                            throw new ExternalServerError();
                        case StatusCodes.UNPROCESSABLE_ENTITY:
                            const error = e.error[0];
                            const input = {
                                message: "VALIDATION ERROR",
                                error: {
                                    fields: error.fields,
                                    reason: error.reason,
                                },
                            };
                            throw new ValidationError(input);
                        default:
                            throw new ConnectionError();
                    }
                }),
            );
    }

    getPackageSignature(tracktrace: string): Observable<Blob> {
        const headers = new HttpHeaders({ "Content-Type": "image/png" });
        const options = { headers, responseType: "blob" as "blob" };

        return this.httpClient.get(
            `${environment.apiBase}/packages/signature/${tracktrace}`,
            options,
        );
    }

    addBulkLabel(tracktrace: string, username: string): Observable<Boolean> {
        const options = { observe: "response" as "response" };
        return this.httpClient
            .get(`${environment.apiBase}/packages/label/bulk/${tracktrace}/${username}`, options)
            .pipe(
                map((response) => response.status === StatusCodes.OK),
                catchError(() => of(false)),
            );
    }

    downloadBulkLabel(username: string): Observable<Blob> {
        const headers = new HttpHeaders({ "Content-Type": "application/pdf" });
        const options = { headers, responseType: "blob" as "blob" };
        return this.httpClient.get(
            `${environment.apiBase}/packages/label/bulk/download/${username}`,
            options,
        );
    }

    getPackageHistory(id: number): Observable<TrackingEventJSON[]> {
        return this.httpClient
            .get<GetTrackingHistoryResult>(`${environment.apiBase}/tracking/history/${id}`)
            .pipe(map((result) => result.history));
    }

    changePackageExternal(tracktrace: string, external_name: string): Observable<boolean> {
        return this.httpClient
            .patch<RestResult>(`${environment.apiBase}/packages/${tracktrace}`, {
                external_name,
            })
            .pipe(map((result) => result.success));
    }

    getStatusTranslation(
        history: Array<string>,
        language: string,
        external: number,
    ): Observable<string[]> {
        const data = {
            language,
            history,
            external_id: external.toString(),
        };

        return this.httpClient
            .post<GetStatusTranslationResult>(`${environment.apiBase}/public/translate`, data)
            .pipe(map((result) => result.history));
    }

    getPackageStats(query: string): Observable<GetPackageStatsResult> {
        return this.httpClient.get<GetPackageStatsResult>(
            `${environment.apiBase}/packages/count?${query}`,
        );
    }

    getPackages(query: string): Observable<Package[]> {
        return this.httpClient
            .get<GetPackagesResult>(`${environment.apiBase}/packages/?${query}`)
            .pipe(map((result) => result.packages.map(Package.fromJSON)));
    }

    // Create contact
    createContact(contact: User): Observable<boolean> {
        const data = {
            user: {
                postcode: contact.postcode,
                housenr: contact.houseNumber,
                housenr_extra: contact.houseNumberExtra,
                phone: contact.phone,
                type: E_USER_TYPES.NON_INTEGRATED_USER,
                streetname: contact.streetname,
                city: contact.city,
                firstname: contact.firstname,
                middlename: contact.middlename,
                lastname: contact.lastname,
                email: contact.email,
                company_name: contact.companyName,
                address_extra: contact.addressExtra,
                country: contact.country,
            },
        };
        return this.httpClient
            .post<RestResult>(`${environment.apiBase}/contacts/`, data)
            .pipe(map((result) => result.success));
    }

    deleteContact(id: number): Observable<boolean> {
        return this.httpClient
            .delete<RestResult>(`${environment.apiBase}/contacts/${id}`)
            .pipe(map((result) => result.success));
    }

    getWebshops(
        searchTerm: string,
        filters?: { active?: boolean; start?: number },
        limit?: number,
    ): Observable<UserPageResults<ViaWebshopJSON>> {
        const params: { [key: string]: string | null } = {
            type: E_USER_TYPES.VIAWEBSHOP.toString(),
            searchterm: searchTerm,
            limit: limit ? limit.toString() : DEFAULT_PAGE_SIZE.toString(),
            start: filters.start ? filters.start.toString() : "0",
        };
        if (filters && filters.active != null) {
            params["active"] = filters.active.toString();
        }

        const options = { params };

        return this.httpClient.get<UserPageResults<ViaWebshopJSON>>(
            `${environment.apiBase}/users/`,
            options,
        );
    }

    getAllWebshops(): Observable<ViaWebshop[]> {
        const params: { [key: string]: string | null } = {
            type: E_USER_TYPES.VIAWEBSHOP.toString(),
            limit: Number.MAX_SAFE_INTEGER.toString(),
        };
        const options = { params };
        return this.httpClient
            .get<UserPageResults<ViaWebshopJSON>>(`${environment.apiBase}/users/`, options)
            .pipe(map((result) => result.users.map(ViaWebshop.fromJSON)));
    }

    activateAccount(id: number): Observable<boolean> {
        return this.httpClient
            .get<RestResult>(`${environment.apiBase}/accounts/toggle/${id}`)
            .pipe(map((result) => result.success));
    }

    importPackages(): Observable<number> {
        return this.httpClient
            .get(`${environment.apiBase}/webshop/import`)
            .pipe(map((response) => <number>(<any>response).number));
    }

    getServicePoints(
        postcode: string,
        housenr: string,
        external: number,
        country: string,
    ): Observable<ServicePoint[]> {
        const params = {
            external: external.toString(),
            limit: "6",
            country,
        };
        const options = { params };
        return this.httpClient
            .get<ServicePointJSON[]>(
                `${environment.apiBase}/servicepoints/near/${postcode}/${housenr}`,
                options,
            )
            .pipe(map((response) => response.map(ServicePoint.fromJSON)));
    }

    bulkExternalUpdate(status, newStatus): Observable<number> {
        const data = {
            status,
            newStatus,
        };

        return this.httpClient
            .post(`${environment.apiBase}/tracking/status/bulk`, data)
            .pipe(map((response) => (<any>response).amount));
    }

    getDefaultConfig(): Observable<DefaultWebshopConfig[]> {
        return this.httpClient
            .get<DefaultWebshopConfigJSON[]>(
                `${environment.apiBase}/users/viawebshop/transporterconfig/default`,
            )
            .pipe(map((response) => response.map(DefaultWebshopConfig.fromJSON)));
    }

    putTransporterConfig(
        configs: WebshopTransporterConfig[],
        webshopId: number,
    ): Observable<boolean> {
        const data = configs.map((config) => {
            const json = config.toJSON();
            if (!json.data.ACCOUNT_ID) {
                delete json.data.ACCOUNT_ID;
            }
            if (!json.data.INBOUND_ACCOUNT_ID) {
                delete json.data.INBOUND_ACCOUNT_ID;
            }
            if (!json.data.sameDay) {
                delete json.data.sameDay;
            }
            if (!json.data.rotate) {
                delete json.data.rotate;
            }
            json.data.sendMail = json.data.sendMail === true;
            if (!json.data.priorities) {
                json.data.priorities = [];
            }
            return json;
        });
        return this.httpClient
            .put<RestResult>(
                `${environment.apiBase}/users/viawebshop/transporterconfig/${webshopId}`,
                data,
            )
            .pipe(map((result) => result.success));
    }

    getWebshopConfigById(webshopId: number): Observable<WebshopTransporterConfig[]> {
        return this.httpClient
            .get<WebshopTransporterConfigJSON[]>(
                `${environment.apiBase}/users/viawebshop/transporterconfig/${webshopId}`,
            )
            .pipe(map((response) => response.map(WebshopTransporterConfig.fromJSON)));
    }

    syncDatawarehouse(): Observable<string> {
        const headers = new HttpHeaders({ Accept: "text/plain" });
        const options = { headers, responseType: "text" as "text" };
        return this.httpClient.get(`${environment.apiBase}/datawarehouse`, options);
    }
}
