import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { UserSaveJSON } from "../models/json/userSaveJSON";
import { UploadStatus, UPLOAD_STATUS } from "../models/uploadStatus.model";

interface SyncDHLLabelStatus {
    id: number;
    amount_to_upload: number;
    success_amount: number;
    errored_amount: number;
    errored_references: string[];
    date_created: string;
    status: UPLOAD_STATUS;
}

export interface UploadDHLLabel {
    recipient: UserSaveJSON;
    sender: UserSaveJSON | string;
    reference: string;
    customer_number: string;
    mark: string;
}

@Injectable()
export class DHLSyncService {
    constructor(private httpClient: HttpClient) {}

    syncDHLLabels(p: UploadDHLLabel[]): Observable<SyncDHLLabelStatus> {
        return this.httpClient.post<SyncDHLLabelStatus>(
            `${environment.apiBase}/packages/upload/dhl`,
            p,
        );
    }

    getLatestUploadStatus(): Observable<UploadStatus | null> {
        return this.httpClient
            .get<any>(`${environment.apiBase}/packages/upload/status/latest`)
            .pipe(map(UploadStatus.fromJSON));
    }
}
