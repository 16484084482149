import moment from "moment";
import { Component, OnInit } from "@angular/core";
import * as FileSaver from "file-saver-es";
import { ExportService } from "../../../services/export.service";
import { MONTH_NAMES_DUTCH, surroundingYearOptions } from "../../../helpers/dateHelper";

@Component({
    selector: "app-logistics-export-modal",
    templateUrl: "./logistics-export-modal.component.html",
    styleUrls: ["./logistics-export-modal.component.scss"],
})
export class LogisticsExportModalComponent implements OnInit {
    exportYear: number;

    exportMonth: number;

    exportWeek: number;

    yearOptions: number[];

    period: "week" | "month" = "week";

    monthOptions = MONTH_NAMES_DUTCH;

    weekOptions = Array.from({ length: 53 }, (_, key) => key + 1);

    constructor(private exportService: ExportService) {}

    ngOnInit() {
        const now = moment();
        const currentYear = now.year();
        if (now.month() > 0) {
            this.exportYear = currentYear;
            this.exportMonth = now.month() - 1;
        } else {
            this.exportYear = currentYear - 1;
            this.exportMonth = 11;
        }
        this.yearOptions = surroundingYearOptions(3);
    }

    download() {
        if (this.period === "month") {
            this.exportService
                .downloadExportForLogisticForMonth(this.exportYear, this.exportMonth)
                .subscribe((data) => {
                    const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                    FileSaver.saveAs(
                        blob,
                        `logistics-month-export-${this.exportYear}-${this.exportMonth + 1}.csv`,
                    );
                });
        } else {
            this.exportService
                .downloadExportForLogisticForWeek(this.exportYear, this.exportWeek)
                .subscribe((data) => {
                    const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                    FileSaver.saveAs(
                        blob,
                        `logistics-week-export-${this.exportYear}-${this.exportWeek}.csv`,
                    );
                });
        }
    }
}
