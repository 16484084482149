import {
    PickupPriceListItemJSON,
    PickupPriceListItemSaveJSON,
} from "./json/pickupPriceListItemJSON";

export class PickupPriceListItem {
    public constructor(
        public webshopId: number,
        public webshop: string,
        public price: number,
        public name: string,
        public errors: { [field: string]: string } = {},
    ) {}

    toSaveJSON(): PickupPriceListItemSaveJSON {
        return {
            webshop_id: this.webshopId,
            name: this.name,
            price: this.price,
        };
    }

    toJSON(): PickupPriceListItemJSON {
        return {
            webshop_id: this.webshopId,
            webshop_name: this.webshop,
            name: this.name,
            price: this.price,
        };
    }

    static fromJSON(json: PickupPriceListItemJSON): PickupPriceListItem {
        return new PickupPriceListItem(json.webshop_id, json.webshop_name, json.price, json.name);
    }
}
