<div class="table-wrapper" *ngIf="items">
    <table class="table">
        <thead>
            <tr class="table__header">
                <th class="col-md-1">Externe Reference</th>
                <th class="col-md-2">Vervoerder</th>
                <th class="col-md-2">Aantal</th>
                <th class="col-md-2">Van</th>
                <th class="col-md-1">Tot</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tr *ngFor="let row of items.elements">
            <td>
                <a class="open-link" (click)="open(row)">{{ row.externalInvoiceId }}</a>
            </td>
            <td><app-external-icon [external]="row.externalId"></app-external-icon></td>
            <td>{{ row.numberOfItems }}</td>
            <td>{{ row.first | splitDate }}</td>
            <td>{{ row.last | splitDate }}</td>
            <td>
                <button (click)="delete(row)" type="button" class="btn btn-outline-primary">
                    <i class="fad fa-trash-alt fa-lg"></i>
                </button>
                <button (click)="recalculate(row)" type="button" class="btn btn-outline-primary">
                    <i class="fad fa-calculator fa-lg"></i>
                </button>
                <button (click)="exportCSV(row)" type="button" class="btn btn-outline-primary">
                    <i class="fad fa-download fa-lg"></i>
                </button>
            </td>
        </tr>
    </table>
    <app-pagination [tablePage]="items" (onPageChange)="gotoPage($event)"></app-pagination>
    <!-- <div class="table__icon-wrapper" (click)="downloadPackages()">
                <i class="fas fa-download table__icon"></i>
                <p>Download aangepaste pakketten</p>
            </div>
            <div class="table__icon-wrapper" (click)="downloadPackages()">
                <i class="fas fa-download table__icon"></i>
                <p>Downloaden pakketten die DHL te hoog heeft gefactureerd</p>
            </div> -->
</div>
