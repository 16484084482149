import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BackendService } from "../../services/backend.service";
import { ServicePoint } from "../../models/servicePoint.model";

@Component({
    selector: "app-choose-parcelpoint",
    templateUrl: "./choose-parcelpoint.component.html",
    styleUrls: ["./choose-parcelpoint.component.scss"],
})
export class ChooseParcelpointComponent implements OnInit {
    servicePoints: ServicePoint[] = [];

    searching: boolean = false;

    @Input() postcode: string;

    @Input() houseNumber: string;

    @Input() external: number;

    @Input() country: string = "NL";

    @Output() servicePointChange = new EventEmitter();

    searchPostcode: string;

    searchHouseNr: string;

    constructor(private backend: BackendService) {}

    ngOnInit() {
        this.searchPostcode = this.postcode;
        this.searchHouseNr = this.houseNumber;
        this.updatePoints();
    }

    get isValid() {
        return this.searchPostcode && this.searchHouseNr && this.external;
    }

    selectServicePoint(point: ServicePoint) {
        this.servicePointChange.emit(point);
    }

    updatePoints() {
        if (this.isValid) {
            this.searching = true;
            this.servicePoints = [];
            this.backend
                .getServicePoints(
                    this.searchPostcode,
                    this.searchHouseNr,
                    this.external,
                    this.country,
                )
                .subscribe((result) => {
                    this.servicePoints = result;
                    this.searching = false;
                });
        }
    }
}
