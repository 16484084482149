<form [formGroup]="transporterForm" (ngSubmit)="onSubmit()" class="webshop-config container">
    <h2 class="webshop-config__title">Actieve vervoerders</h2>
    <div formArrayName="configs" class="accordion">
        <div *ngIf="!availableTransporters">
            <p>Er zijn geen vervoerders om te configureren!</p>
        </div>

        <div
            class="accordion-item"
            *ngFor="let transporter of availableTransporters; let i = index"
            [formGroupName]="i"
        >
            <h2 class="accordion-header" [ngClass]="{ collapsed: openAccordion !== i }">
                <input
                    class="form-check-input"
                    name="active"
                    formControlName="active"
                    type="checkbox"
                />
                <button
                    class="accordion-button"
                    (click)="open(i)"
                    [ngClass]="{ collapsed: openAccordion !== i }"
                    type="button"
                >
                    {{ transporter.transporterCode }}
                </button>
            </h2>
            <div
                id="collapseOne"
                class="accordion-collapse collapse"
                [ngClass]="{ show: openAccordion === i }"
            >
                <div class="form-row" *ngIf="transporter.configurableFields.includes('ACCOUNT_ID')">
                    <label class="carriers-accordion-form__label" for="accountID">Account ID</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Account ID"
                        class="config__input"
                        name="accountID"
                        formControlName="accountID"
                    />
                    <div class="row error" *ngIf="!isValid(i, 'accountID')">
                        <div class="error__section-input">
                            <span class="error__errorMessageInput"
                                >Account ID moet 8 cijfers bevatten</span
                            >
                        </div>
                    </div>
                </div>
                <div
                    class="form-row"
                    *ngIf="transporter.configurableFields.includes('INBOUND_ACCOUNT_ID')"
                >
                    <label class="carriers-accordion-form__label" for="inboundAccountID"
                        >Account ID voor retourlabels</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Account ID"
                        class="config__input"
                        name="inboundAccountID"
                        formControlName="inboundAccountID"
                    />
                    <div class="row error" *ngIf="!isValid(i, 'inboundAccountID')">
                        <div class="error__section-input">
                            <span class="error__errorMessageInput"
                                >Account ID moet 8 cijfers bevatten</span
                            >
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <label class="carriers-accordion-form__label" for="rotate"
                        >Rotatie (0, 90, 180, 270)</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Rotatie"
                        class="config__input"
                        name="rotate"
                        formControlName="rotate"
                    />
                    <div class="row error" *ngIf="!isValid(i, 'rotate')">
                        <div class="error__section-input">
                            <span class="error__errorMessageInput"
                                >Rotate kan alleen de waarden: 0, 90, 180 of 270 bevatten</span
                            >
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        name="sendMail"
                        formControlName="sendMail"
                    />
                    <label class="form-check-label" for="sendMail">Send ViaTim T&T Mails</label>
                </div>
                <div class="form-row">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        name="sameDay"
                        formControlName="sameDay"
                    />
                    <label class="form-check-label" for="sameDay">Same Day Bezorging</label>
                </div>
            </div>
        </div>
    </div>
    <div _ngcontent-klf-c162="" class="row justify-content-md-center p-4">
        <button type="button" class="col btn btn-primary validate-button" (click)="onSubmit()">
            Opslaan
        </button>
        <a (click)="cancel()" href="javascript:;" class="btn btn-secondary offset-sm-1 col"
            >Annuleren</a
        >
    </div>
</form>
