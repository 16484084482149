import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../environments/environment";
import { TransporterInvoiceImportItem } from "../models/transporterInvoiceImportItem.model";
import { WorksheetItem } from "../models/worksheetItem.model";
import { PickupItem } from "../models/pickuptem.model";
import { TransporterInvoiceItemJSON } from "../models/json/transporterInvoiceItemJSON";
import { TablePage } from "../models/tablePage.model";
import { TransporterInvoiceItem } from "../models/transporterInvoiceItem";
import { TransporterInvoice } from "../models/transporterInvoice";
import { TransporterInvoiceJSON } from "../models/json/transporterInvoiceJSON";

export interface GetInvoiceItemResults {
    items: TransporterInvoiceItemJSON[];
    amount: number;
}

export interface GetTransporterInvoiceResults {
    items: TransporterInvoiceJSON[];
    amount: number;
}

export type TransporterInvoiceItemFilter = {
    externalId?: number;
    externalInvoiceId?: string;
    has_price?: boolean;
    start?: number;
    limit?: number;
    carrierCode?: string;
    externalPriceTypeId?: number;
    ownerId?: number;
};

const cleanParams = (obj: { [key: string]: any }) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] != null) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};

@Injectable()
export class InvoiceService {
    constructor(private http: HttpClient) {}

    uploadTransporterInvoiceItems(data: TransporterInvoiceImportItem[]): Observable<void> {
        return this.http.post<void>(
            `${environment.apiBase}/webshop/invoices/transporter-invoices/items`,
            data.map((d: TransporterInvoiceImportItem) => d.toSaveJSON()),
            {
                observe: "body",
                responseType: "json",
                reportProgress: true,
            },
        );
    }

    uploadWorksheetItems(items: WorksheetItem[]): Observable<void> {
        const data = items.map((i) => i.toSaveJSON());
        return this.http.post<void>(
            `${environment.apiBase}/webshop/invoices/worksheet-items`,
            data,
        );
    }

    uploadPickupItems(items: PickupItem[]): Observable<void> {
        const data = items.map((i) => i.toSaveJSON());
        return this.http.post<void>(`${environment.apiBase}/webshop/invoices/pickup-items`, data);
    }

    recaculatePricesForDate(date: moment.Moment) {
        const data = {
            date: date.format("YYYY-MM-DD"),
        };
        return this.http
            .post(`${environment.apiBase}/webshop/invoices/prices/recalculate`, data)
            .pipe(map(() => true));
    }

    get(start: number, limit: number): Observable<TablePage<TransporterInvoice>> {
        const params = {
            start,
            limit,
        };
        return this.http
            .get<GetTransporterInvoiceResults>(
                `${environment.apiBase}/webshop/invoices/transporter-invoices`,
                { params },
            )
            .pipe(
                map((data) => {
                    const result = new TablePage<TransporterInvoice>(
                        data.items.map(TransporterInvoice.fromJSON),
                        start,
                        data.amount,
                        limit,
                    );
                    return result;
                }),
            );
    }

    deleteTransporterInvoice(externalId: number, invoiceId: string): Observable<void> {
        return this.http.delete<void>(
            `${environment.apiBase}/webshop/invoices/transporter-invoices/${externalId}/${invoiceId}`,
        );
    }

    recalculateTransporterInvoice(invoiceId: string): Observable<void> {
        return this.http.post<void>(`${environment.apiBase}/webshop/invoices/prices/recalculate`, {
            invoice_id: invoiceId,
        });
    }

    exportTransporterInvoice(externalId: number, externalInvoiceId: string) {
        const params = cleanParams({
            external_id: externalId,
            external_invoice_id: externalInvoiceId,
            has_price: true,
            start: 0,
            limit: Number.MAX_SAFE_INTEGER,
        });
        const options = { responseType: "text" as "text", params };
        return this.http.get(
            `${environment.apiBase}/webshop/invoices/transporter-invoices/items/csv`,
            options,
        );
    }

    updatePackage(webshop: string): Observable<any> {
        const data = {
            webshop,
        };
        return this.http
            .post(`${environment.apiBase}/webshop/invoices/prices/recalculate`, data)
            .pipe(map(() => true));
    }

    comparePrices(
        filter: TransporterInvoiceItemFilter,
    ): Observable<TablePage<TransporterInvoiceItem>> {
        const params = cleanParams({
            external_id: filter.externalId,
            external_invoice_id: filter.externalInvoiceId,
            has_price: true,
            start: filter.start,
            limit: filter.limit,
            owner_id: filter.ownerId,
            carrier_code: filter.carrierCode,
            external_price_type_id: filter.externalPriceTypeId,
        });
        return this.http
            .get<GetInvoiceItemResults>(
                `${environment.apiBase}/webshop/invoices/transporter-invoices/items`,
                {
                    params,
                },
            )
            .pipe(
                map((data) => {
                    const result = new TablePage<TransporterInvoiceItem>(
                        data.items.map(TransporterInvoiceItem.fromJSON),
                        filter.start,
                        data.amount,
                        filter.limit,
                    );
                    return result;
                }),
            );
    }
}
