import { Action, createReducer, on } from "@ngrx/store";
import { DEFAULT_PAGE_SIZE } from "../../helpers/constants";
import { Package } from "../../models/package.model";
import { TablePage } from "../../models/tablePage.model";
import * as PackagesActions from "../actions/packages.actions";

export const initialState: TablePage<Package> = new TablePage<Package>([], 0, 0, DEFAULT_PAGE_SIZE);

const packageFilterReducer = createReducer(
    initialState,
    on(PackagesActions.loadedPackages, (_, action) => action.packages),
);

export function reducer(state: TablePage<Package>, action: Action) {
    return packageFilterReducer(state, action);
}
