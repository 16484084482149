<div class="content">
    <h1>Wachtwoord vergeten?</h1>
    <p>Vul je gebruikersnaam in en wij sturen een email naar het gekoppelde emailadres.</p>
    <p>{{ error }}</p>
    <form>
        <div class="row">
            <label for="username">Gebruikersnaam </label><br />
            <input type="text" name="username" [(ngModel)]="username" id="username" />
        </div>
        <div class="submit-row">
            <button [disabled]="!username" class="btn inverse" (click)="submit()">VERSTUUR</button>
            <a [routerLink]="['/login']">Ga terug naar login</a>
        </div>
    </form>
</div>
