<app-cp-modal title="Forceer Pakket">
    <form (ngSubmit)="onSubmit()" #packageForm="ngForm">
        <div *ngIf="package" id="force-package">
            <h2>
                <span>Waarschuwing:</span> Als je het pakket forceert, is het niet terug te zien
                welk proces dit pakket heeft doorlopen.
            </h2>
            <div class="packageInfo">
                <h2>
                    <a
                        href="https://www.viatim.nl/trackentrace-status/?tracktrace={{
                            package.tracktrace
                        }}&postcode={{ package.recipient.postcode }}"
                        target="_blank"
                    >
                        {{ package.tracktrace }}
                        <i class="far fa-share fa-xs" data-fa-transform="up-2"></i
                    ></a>
                </h2>
            </div>
            <div id="packageDetails">
                <div class="senderInfo">
                    <ul>
                        <li>
                            <h4>Verzender</h4>
                        </li>
                        <li>{{ package.sender | fullName }}</li>
                        <li>{{ package.sender.streetname }} {{ package.sender.houseNumber }}</li>
                        <li>{{ package.sender.postcode }}</li>
                        <li>{{ package.sender.city }}</li>
                        <li>{{ package.sender.phone }}</li>
                        <li>
                            <a href="mailto:{{ package.sender.email }}">{{
                                package.sender.email
                            }}</a>
                        </li>
                    </ul>
                </div>
                <div class="recipientInfo">
                    <ul>
                        <li>
                            <h4>Ontvanger</h4>
                        </li>
                        <li>{{ package.recipient | fullName }}</li>
                        <li>
                            {{ package.recipient.streetname }}
                            {{ package.recipient.houseNumber }}
                        </li>
                        <li>{{ package.recipient.postcode }}</li>
                        <li>{{ package.recipient.city }}</li>
                        <li>{{ package.recipient.phone }}</li>
                        <li>
                            <a href="mailto:{{ package.recipient.email }}">{{
                                package.recipient.email
                            }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <button class="btn inverse" type="submit">FORCEER</button>
    </form>
</app-cp-modal>
