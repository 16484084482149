import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { PackagesService } from "../../packages/packages.service";
import { setPackageFilter } from "../actions/package-filter.actions";
import { loadedPackages } from "../actions/packages.actions";

@Injectable()
export class PackageFilterEffects {
    constructor(private actions$: Actions, private packagesService: PackagesService) {}

    updateFilter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setPackageFilter),
            mergeMap((action) =>
                this.packagesService
                    .getPackages(action.filter)
                    .pipe(map((packages) => loadedPackages({ packages }))),
            ),
        ),
    );
}
