import { DefaultWebshopConfigJSON, PrioritiesJSON } from "./json/defaultWebshopConfigJSON";

export type DefaultWebshopConfigType = {
    code: string;
    id: number;
    countriesTo: string[];
    countriesFrom: string[];
    priorities: PrioritiesJSON;
    defaultPriority: number;
    supportOptions: boolean;
    servicepoints: boolean;
    configurableFields: string[];
};

export class DefaultWebshopConfig {
    constructor(
        public transporterCode: string,
        public id: number,
        public countriesTo: string[],
        public countriesFrom: string[],
        public priorities: PrioritiesJSON,
        public defaultPriority: number,
        public supportOptions: boolean,
        public servicepoints: boolean,
        public configurableFields: string[],
    ) {}

    static fromJSON(json: DefaultWebshopConfigJSON): DefaultWebshopConfig {
        return new DefaultWebshopConfig(
            json.code,
            json.id,
            json.countriesTo,
            json.countriesFrom,
            json.priorities,
            json.defaultPriority,
            json.supportOptions,
            json.servicepoints,
            json.configurableFields,
        );
    }

    toJSON(): DefaultWebshopConfigJSON {
        return {
            code: this.transporterCode,
            id: this.id,
            countriesTo: this.countriesTo,
            countriesFrom: this.countriesFrom,
            priorities: this.priorities,
            defaultPriority: this.defaultPriority,
            supportOptions: this.supportOptions,
            servicepoints: this.servicepoints,
            configurableFields: this.configurableFields,
        };
    }
}
