import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { ExternalDisplayName } from "../../helpers/pipes/external-display-name.pipe";
import { addMessage } from "../../store/actions/message.actions";
import { Message } from "../../models/message";
import { AuthService } from "../../services/auth.service";
import { BackendService } from "../../services/backend.service";
import { User } from "../../models/user.model";
import { E_USER_TYPES } from "../../helpers/constants";
import { E_EXTERNAL_PARTIES } from "../../helpers/constants/transporters";
import { ViaWebshop } from "../../models/viawebshop.model";
import { ValidationError } from "../../services/errors";

@Component({
    selector: "app-account-management-edit",
    templateUrl: "./account-management-edit.component.html",
    styleUrls: ["./account-management-edit.component.scss"],
})
export class AccountManagementEditComponent implements OnInit {
    user: User;

    transporters: string;

    editForm: UntypedFormGroup;

    manualStreetInput = false;

    errors: ValidationError[] = [];

    constructor(
        private backend: BackendService,
        private router: Router,
        private auth: AuthService,
        private store: Store,
        private ExternalPipe: ExternalDisplayName,
    ) {}

    ngOnInit() {
        if (!this.canEdit) {
            this.router.navigateByUrl("/account-management");
        }
        this.backend.getCurrentUser().subscribe(
            (result) => {
                this.user = result;
                this.getTransporterString(this.user.rights);
                this.initForm();
            },
            () => {
                this.router.navigateByUrl("/");
            },
        );
    }

    get canEdit() {
        return (
            this.auth.getAccountRole() == E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() == E_USER_TYPES.VIAWEBSHOP ||
            this.auth.getAccountRole() == E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() == E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    get userIsWebshop() {
        return this.auth.getAccountRole() == E_USER_TYPES.VIAWEBSHOP;
    }

    getTransporterString(rights: string[]) {
        rights.map((transporter) => {
            const transporterCapString = transporter.split("can_use_")[1];
            const transporterId = E_EXTERNAL_PARTIES[transporterCapString];

            this.transporters = this.transporters
                ? `${this.transporters}, ${this.ExternalPipe.transform(transporterId)}`
                : this.ExternalPipe.transform(transporterId);
            return transporter;
        });
    }

    updateAddress() {
        if (
            this.hasAutomaticPostcodeCheck &&
            this.user.houseNumber &&
            this.user.postcode &&
            !this.manualStreetInput
        ) {
            this.backend
                .getAddressByPostcode(
                    this.editForm.get("postcode").value,
                    this.editForm.get("houseNumber").value,
                    this.editForm.get("houseNumberExtra").value,
                )
                .subscribe(
                    (addressRes) => {
                        this.editForm.patchValue({
                            streetname: addressRes.street,
                            city: addressRes.city,
                        });
                    },
                    () => {
                        this.editForm.patchValue({ streetname: "", city: "" });
                    },
                );
        }
    }

    get hasAutomaticPostcodeCheck() {
        return this.user.country === "NL";
    }

    initForm() {
        this.editForm = new UntypedFormGroup({
            firstname: new UntypedFormControl(this.user.firstname, [Validators.required]),
            middlename: new UntypedFormControl(this.user.middlename),
            lastname: new UntypedFormControl(this.user.lastname, [Validators.required]),
            companyName: new UntypedFormControl(this.user.companyName, [Validators.required]),
            postcode: new UntypedFormControl(this.user.postcode, [Validators.required]),
            houseNumber: new UntypedFormControl(this.user.houseNumber, [Validators.required]),
            houseNumberExtra: new UntypedFormControl(this.user.houseNumberExtra),
            streetname: new UntypedFormControl(this.user.streetname, [Validators.required]),
            addressExtra: new UntypedFormControl(this.user.addressExtra),
            city: new UntypedFormControl(this.user.city, [Validators.required]),
            email: new UntypedFormControl(this.user.email, [Validators.required]),
            phone: new UntypedFormControl(this.user.phone, [Validators.required]),
        });
        if (this.user instanceof ViaWebshop) {
            this.editForm.addControl(
                "domain",
                new UntypedFormControl(this.user.domain, [Validators.required]),
            );
            // this.editForm.addControl("kvk", new FormControl(this.user.kvk, [Validators.required]));
            // this.editForm.addControl("btw", new FormControl(this.user.btw, [Validators.required]));
            // this.editForm.addControl("iban", new FormControl(this.user.iban, [Validators.required]));
            // this.editForm.addControl("bic", new FormControl(this.user.bic, [Validators.required]));
        }
    }

    onSubmit(data) {
        Object.assign(this.user, data);
        if (this.userIsWebshop) {
            this.user.domain = this.editForm.get("domain").value;
            this.user.kvk = undefined;
            this.user.btw = undefined;
            this.user.iban = undefined;
            this.user.bic = undefined;
            this.user.webshopConnectorType = undefined;
            this.user.channelId = undefined;
        }
        this.editForm.reset();
        this.initForm();
        this.backend.editUser(this.user.id, this.user.toUpdateJSON()).subscribe(
            () => {
                this.errors = [];
                this.store.dispatch(
                    addMessage({
                        message: Message.createSuccessMessage(
                            "Succes",
                            "Je gegevens zijn aangepast",
                        ),
                    }),
                );
                this.router.navigateByUrl("/account-management");
            },
            (error) => {
                if (error.error && error.error.errors) {
                    this.errors = error.error.errors;
                    const illegalFields = [];
                    this.errors.map((validationError) =>
                        validationError.reason == "illegal"
                            ? illegalFields.push(validationError.fields[0].split(".")[1])
                            : undefined,
                    );
                    if (illegalFields.length > 0) {
                        this.store.dispatch(
                            addMessage({
                                message: Message.createErrorMessage(
                                    "Fout",
                                    `Je hebt niet de juiste rechten om ${illegalFields.join(
                                        ", ",
                                    )} aan te passen`,
                                ),
                            }),
                        );
                    } else {
                        this.store.dispatch(
                            addMessage({
                                message: Message.createErrorMessage(
                                    "Fout",
                                    "Een veld is niet helemaal goed ingevuld",
                                ),
                            }),
                        );
                    }
                } else {
                    this.store.dispatch(
                        addMessage({
                            message: Message.createErrorMessage(
                                "Fout",
                                `De  aanpassing geeft een fout ${error.error}`,
                            ),
                        }),
                    );
                }
            },
        );
    }
}
