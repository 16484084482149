import moment from "moment";
import { Component, OnInit } from "@angular/core";
import * as FileSaver from "file-saver-es";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { map, switchMap } from "rxjs/operators";
import { Message } from "../../../models/message";
import { addMessage } from "../../../store/actions/message.actions";
import { ExportService } from "../../../services/export.service";
import { InvoiceService } from "../../../services/invoices.service";
import { FULL_PERCENTAGES } from "../../../helpers/constants";
import { MONTH_NAMES_DUTCH, surroundingYearOptions } from "../../../helpers/dateHelper";

@Component({
    selector: "app-invoicing-export-modal",
    templateUrl: "./invoicing-export-modal.component.html",
    styleUrls: ["./invoicing-export-modal.component.scss"],
})
export class InvoicingExportModalComponent implements OnInit {
    exportYear: number;

    exportMonth: number;

    exportWeek: number;

    yearOptions: number[];

    recalculate = false;

    counter = 0;

    total = 0;

    showLoading = false;

    failures = [];

    period: "week" | "month" = "week";

    monthOptions = MONTH_NAMES_DUTCH;

    weekOptions = Array.from({ length: 53 }, (_, key) => key + 1);

    constructor(
        private exportService: ExportService,
        private invoiceService: InvoiceService,
        private store: Store,
    ) {}

    ngOnInit() {
        this.updateDefaultPeriod();
        this.yearOptions = surroundingYearOptions(3);
    }

    get progressPercentage() {
        if (this.total) {
            return Math.round((this.counter / this.total) * FULL_PERCENTAGES);
        }
        return 0;
    }

    setPeriod(period: "month" | "week") {
        this.period = period;
        this.updateDefaultPeriod();
    }

    updateDefaultPeriod() {
        const lastPeriod = moment().subtract(1, this.period);
        this.exportYear = lastPeriod.year();
        this.exportMonth = lastPeriod.month();
        this.exportWeek = lastPeriod.week();
    }

    download() {
        if (this.period === "month") {
            this.exportService
                .downloadExportForBillingForMonth(this.exportYear, this.exportMonth)
                .subscribe({
                    next: (data) => {
                        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                        FileSaver.saveAs(
                            blob,
                            `invoice-month-export-${this.exportYear}-${this.exportMonth}.csv`,
                        );
                        this.showLoading = false;
                    },
                    error: () => {
                        const message = Message.createErrorMessage(
                            "Fout bij het downloaden",
                            "Er is iets fout gegaan bij het downloaden van de csv",
                        );
                        this.store.dispatch(addMessage({ message }));
                        this.showLoading = false;
                    },
                });
        } else {
            this.exportService
                .downloadExportForBillingForWeek(this.exportYear, this.exportWeek)
                .subscribe({
                    next: (data) => {
                        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                        FileSaver.saveAs(
                            blob,
                            `invoice-week-export-${this.exportYear}-${this.exportWeek}.csv`,
                        );
                        this.showLoading = false;
                    },
                    error: () => {
                        const message = Message.createErrorMessage(
                            "Fout bij het downloaden",
                            "Er is iets fout gegaan bij het downloaden van de csv",
                        );
                        this.store.dispatch(addMessage({ message }));
                        this.showLoading = false;
                    },
                });
        }
    }

    export() {
        this.showLoading = true;
        if (this.recalculate) {
            this.counter = 0;
            let startDate: moment.Moment;
            let endDate: moment.Moment;

            if (this.period === "week") {
                startDate = moment([this.exportYear]).week(this.exportWeek);
                endDate = startDate.clone().add(1, "week");
            } else {
                startDate = moment([this.exportYear, this.exportMonth]);
                endDate = startDate.clone().add(1, "month");
            }

            this.total = endDate.diff(startDate, "days");
            const period = [];

            for (let date = startDate; !date.isAfter(endDate); date.add(1, "day")) {
                period.push(date.clone());
            }

            const jobs: Observable<boolean[]> = period.reduce(
                (
                    acc: Observable<{ date: moment.Moment; success: boolean }[]>,
                    date: moment.Moment,
                ) => {
                    if (!acc) {
                        return this.invoiceService
                            .recaculatePricesForDate(date)
                            .pipe(map((success) => [{ date, success }]));
                    }
                    return acc.pipe(
                        switchMap((result: { date: moment.Moment; success: boolean }[]) => {
                            this.counter++;
                            return this.invoiceService
                                .recaculatePricesForDate(date)
                                .pipe(
                                    map((success: boolean) => result.concat([{ date, success }])),
                                );
                        }),
                    );
                },
                null,
            );
            jobs.subscribe({
                next: (result) => {
                    this.failures = result.filter((success) => !success);
                    if (this.failures.length > 0) {
                        const message = Message.createErrorMessage(
                            "Fout bij het herberekenen van prijzen",
                            "Het het berekenen van de pakket prijzen is niet helemaal gelukt",
                        );
                        this.store.dispatch(addMessage({ message }));
                    }
                    this.download();
                },
                error: () => {
                    const message = Message.createErrorMessage(
                        "Fout bij het herberekenen van prijzen",
                        "Het het berekenen van de pakket prijzen is niet helemaal gelukt",
                    );
                    this.store.dispatch(addMessage({ message }));
                    this.showLoading = false;
                },
            });
        } else {
            this.download();
        }
    }
}
