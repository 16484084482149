import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { Observable } from "rxjs";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { first } from "rxjs/operators";
import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { PackageFilter } from "../../../models/packageFilter";

const HIDE_ADVANCED_OPTIONS_TEXT = "&#8963;"; // ⌃
const SHOW_ADVANCED_OPTIONS_TEXT = "&#8964;"; // ⌄

@Component({
    selector: "app-package-filter",
    templateUrl: "./package-filter.component.html",
    styleUrls: ["./package-filter.component.scss"],
})
export class PackageFilterComponent implements OnInit {
    filterForm = new UntypedFormGroup({
        searchTerm: new UntypedFormControl(""),
        isConcept: new UntypedFormControl(null),
        isReturnLabel: new UntypedFormControl(null),
        statuses: new UntypedFormArray([]),
        type: new UntypedFormControl(null),
        dateFrom: new UntypedFormControl(null),
        dateTo: new UntypedFormControl(null),
        carrierCode: new UntypedFormControl(null),
    });

    packageStatuses: { name: string; value: number }[] = [
        { name: "Pakket toegevoegd", value: 0 },
        { name: "Pakket binnen op DC", value: 1 },
        { name: "Pakket gescand door ViaBode", value: 2 },
        { name: "Pakket afgegeven DC", value: 9 },
        { name: "Pakket in bezit externe vervoerder", value: 10 },
        { name: "Pakket Bezorgd", value: 4 },
    ];

    showAdvancedFilters: boolean = false;

    packageFilter$: Observable<PackageFilter> = this.store.select((state) => state.packageFilter);

    toggleButtonText: string = SHOW_ADVANCED_OPTIONS_TEXT;

    @Input()
    totalPackages: number = 0;

    constructor(
        private store: Store<{ packageFilter: PackageFilter }>,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.packageFilter$.pipe(first(() => true, new PackageFilter())).subscribe((filter) => {
            this.filterForm.patchValue({
                searchTerm: filter.searchterm,
                isConcept: filter.concept,
                type: filter.type,
                carrierCode: filter.carrierCode,
                isReturnLabel: filter.isReturnLabel,
                dateFrom: filter.dateStart
                    ? {
                          year: filter.dateStart.year(),
                          month: filter.dateStart.month() + 1,
                          day: filter.dateStart.date(),
                      }
                    : null,
                dateTo: filter.dateEnd
                    ? {
                          year: filter.dateEnd.year(),
                          month: filter.dateEnd.month() + 1,
                          day: filter.dateEnd.date(),
                      }
                    : null,
            });
            this.addCheckboxes();
            this.showAdvancedFilters =
                Object.entries(filter).reduce((acc, [key, value]) => {
                    if (value && !["searchterm", "start", "limit"].includes(key)) {
                        return acc + 1;
                    }
                    return acc;
                }, 0) > 0;
        });
    }

    addCheckboxes() {
        if (this.statusesFormArray.length > 0) {
            this.statusesFormArray.clear();
        }
        this.packageStatuses.forEach(() =>
            this.statusesFormArray.push(new UntypedFormControl(false)),
        );
    }

    get statusesFormArray() {
        return this.filterForm.controls["statuses"] as UntypedFormArray;
    }

    toggleFilter() {
        this.showAdvancedFilters = !this.showAdvancedFilters;
        this.toggleButtonText = this.showAdvancedFilters
            ? HIDE_ADVANCED_OPTIONS_TEXT
            : SHOW_ADVANCED_OPTIONS_TEXT;

        // TODO : only do this if the filters are filled.
        this.onFilterChange();
    }

    get selectedStatuses() {
        const selectedStatusIds = this.filterForm.value.statuses
            .map((checked, i: number) => (checked ? this.packageStatuses[i].value : null))
            .filter((v) => v !== null);
        return selectedStatusIds;
    }

    onFilterChange() {
        const filterValue = this.filterForm.value;
        const filterClone = new PackageFilter();
        const packageStatuses = this.selectedStatuses;
        if (filterValue.searchTerm) {
            filterClone.searchterm = filterValue.searchTerm;
        }
        if (this.showAdvancedFilters) {
            if (filterValue.isConcept !== null) {
                filterClone.concept = filterValue.isConcept;
            }
            if (packageStatuses.length > 0) {
                filterClone.status = [...packageStatuses];
            }
            if (filterValue.type !== null) {
                filterClone.type = filterValue.type;
            }
            if (filterValue.dateFrom !== null) {
                filterClone.dateStart = moment({
                    ...filterValue.dateFrom,
                    month: filterValue.dateFrom.month - 1,
                });
            }
            if (filterValue.dateTo !== null) {
                filterClone.dateEnd = moment({
                    ...filterValue.dateTo,
                    month: filterValue.dateTo.month - 1,
                });
            }
            if (filterValue.isReturnLabel !== null) {
                filterClone.isReturnLabel = filterValue.isReturnLabel;
            }
            if (filterValue.carrierCode !== null) {
                filterClone.carrierCode = filterValue.carrierCode;
            }
        }
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: filterClone.toURLParams(),
        });
    }
}
