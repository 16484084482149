import { cloneDeep } from "lodash-es";
import { HttpParams } from "@angular/common/http";
import { Params } from "@angular/router";
import moment from "moment";

const START_DEFAULT = 0;
const LIMIT_DEFAULT = 32;

export class PackageFilter {
    public sender?: number[] | number = null;

    public owner?: number[] | number = null;

    public recipient?: number[] | number = null;

    public currentOwner?: number[] | number = null;

    public currentRecipient?: number[] | number = null;

    public type?: number[] | number = null;

    public status?: string[] | string = null;

    public dateEnd?: moment.Moment = null;

    public dateStart?: moment.Moment = null;

    public start?: number;

    public limit?: number;

    public carrierCode?: string = null;

    public concept?: boolean = null;

    public isReturnLabel?: boolean = null;

    public searchterm?: string = null;

    constructor() {
        this.searchterm = "";
        this.start = START_DEFAULT;
        this.limit = LIMIT_DEFAULT;
    }

    clone(): PackageFilter {
        return cloneDeep(this);
    }

    static fromURLParams(params: Params) {
        const result = new PackageFilter();
        if (params["searchterm"]) {
            result.searchterm = params["searchterm"];
        }
        if (params["limit"]) {
            result.limit = Number.parseInt(params["limit"], 10);
        }
        if (params["page"]) {
            result.start = (params["page"] - 1) * result.limit;
        }
        if (params["concept"] != null) {
            result.concept = params["concept"] === "true";
        }
        if (params["status"] != null && params["status"].length > 0) {
            result.status = params["status"];
        }
        if (params["type"] != null) {
            result.type = Number.parseInt(params["type"], 10);
        }

        if (params["dateTo"] != null) {
            result.dateEnd = moment(params["dateTo"]);
        }
        if (params["dateFrom"] != null) {
            result.dateStart = moment(params["dateFrom"]);
        }

        if (params["carrier_code"] != null) {
            result.carrierCode = params["carrier_code"];
        }

        if (params["return"] != null) {
            result.isReturnLabel = params["return"] === "true";
        }

        return result;
    }

    toURLParams() {
        const result: any = {};
        if (this.searchterm) {
            result.searchterm = this.searchterm;
        }
        if (this.concept != null) {
            result.concept = this.concept.toString();
        }
        if (this.start > 0) {
            const page = Math.floor((this.start - 1) / this.limit);
            if (page) {
                result.page = page + 1;
            }
        }
        if (this.limit && this.limit != LIMIT_DEFAULT) {
            result.limit = this.limit.toString();
        }
        if (this.status != null && this.status.length > 0) {
            result.status = this.status;
        }
        if (this.type != null && (!(this.type instanceof Array) || this.type.length > 0)) {
            result.type = this.type;
        }
        if (this.dateEnd != null) {
            result.dateTo = this.dateEnd.format("YYYY-MM-DD");
        }
        if (this.dateStart != null) {
            result.dateFrom = this.dateStart.format("YYYY-MM-DD");
        }
        if (this.carrierCode != null) {
            result.carrier_code = this.carrierCode;
        }

        if (this.isReturnLabel != null) {
            result.return = this.isReturnLabel;
        }

        return result;
    }

    toSearchParams() {
        let result = new HttpParams();
        result = PackageFilter.addToSearch(result, "sender", this.sender);
        result = PackageFilter.addToSearch(result, "owner", this.owner);
        result = PackageFilter.addToSearch(result, "recipient", this.recipient);
        result = PackageFilter.addToSearch(result, "currentOwner", this.currentOwner);
        result = PackageFilter.addToSearch(result, "currentRecipient", this.currentRecipient);
        result = PackageFilter.addToSearch(result, "type", this.type);
        result = PackageFilter.addToSearch(result, "status", this.status);
        result = PackageFilter.addToSearch(result, "dateEnd", this.dateEnd);
        result = PackageFilter.addToSearch(result, "dateStart", this.dateStart);
        result = PackageFilter.addToSearch(result, "start", this.start);
        result = PackageFilter.addToSearch(result, "limit", this.limit);
        result = PackageFilter.addToSearch(result, "carrier_code", this.carrierCode);
        result = PackageFilter.addToSearch(result, "concept", this.concept);
        result = PackageFilter.addToSearch(result, "is_return_label", this.isReturnLabel);
        result = PackageFilter.addToSearch(result, "searchterm", this.searchterm);
        return result;
    }

    static addToSearch(search: HttpParams, key: string, value: any) {
        if (value != null && value !== "") {
            let stringValue: string;
            if (value instanceof moment) {
                stringValue = (<moment.Moment>value).format("YYYY-MM-DD");
            } else {
                stringValue = value.toString();
            }
            return search.append(key, stringValue);
        }
        return search;
    }
}
