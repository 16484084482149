<app-cp-page>
    <div class="content-margin">
        <h2>Bewerk Webshop</h2>
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink>Algemeen</a>
                <ng-template ngbNavContent>
                    <div class="tabcontent">
                        <app-edit-webshop-form
                            *ngIf="viaWebshop"
                            [viaWebshop]="viaWebshop"
                            (onSave)="save($event)"
                            (onCancel)="cancel()"
                            [errors]="errors"
                        ></app-edit-webshop-form>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Vervoerders</a>
                <ng-template ngbNavContent>
                    <div class="tabcontent">
                        <app-webshops-config (onCancel)="cancel()"></app-webshops-config>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Prioriteiten</a>
                <ng-template ngbNavContent>
                    <div class="tabcontent">
                        <app-webshops-config-priority></app-webshops-config-priority>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</app-cp-page>
