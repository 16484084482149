<!-- AANMAKEN VIABODE -->
<h6>Account aanmaken voor ViaBode</h6>
<div class="row">
    <div>
        <label for="firstname">Voornaam</label>
        <i class="fas fa-asterisk"></i>
        <input
            type="text"
            placeholder="Tim"
            id="firstname"
            name="firstname"
            [(ngModel)]="viabode.firstname"
            required
        />
        <span class="errorMessage"></span>
    </div>
    <div>
        <label for="middlename">Tussenvoegsel</label>
        <input
            type="text"
            placeholder="van"
            id="middlename"
            name="middlename"
            [(ngModel)]="viabode.middlename"
        />
        <span class="errorMessage"></span>
    </div>
    <div>
        <label for="lastname">Achternaam</label>
        <i class="fas fa-asterisk"></i>
        <input
            type="text"
            placeholder="ViaTim"
            id="lastname"
            name="lastname"
            [(ngModel)]="viabode.lastname"
            required
        />
        <span class="errorMessage"></span>
    </div>
    <div>
        <label for="postcode">Postcode</label>
        <i class="fas fa-asterisk"></i>
        <input
            type="text"
            placeholder="1234AB"
            id="postcode"
            name="postcode"
            [(ngModel)]="viabode.postcode"
            required
        />
        <span class="errorMessage"></span>
    </div>
</div>
<div class="row">
    <div>
        <label for="housenr">Huisnummer</label>
        <i class="fas fa-asterisk"></i>
        <input
            type="text"
            placeholder="20"
            id="housenr"
            name="housenr"
            [(ngModel)]="viabode.houseNumber"
            required
        />
        <span class="errorMessage"></span>
    </div>
    <div>
        <label for="street">Street</label>
        <i class="fas fa-asterisk"></i>
        <input
            type="text"
            placeholder="Rijksstraatweg"
            id="street"
            name="street"
            [(ngModel)]="viabode.streetname"
            required
        />
        <span class="errorMessage"></span>
    </div>
    <div>
        <label for="city">Woonplaats</label>
        <i class="fas fa-asterisk"></i>
        <input
            type="text"
            placeholder="Schiedam"
            id="city"
            name="city"
            [(ngModel)]="viabode.city"
            required
        />
        <span class="errorMessage"></span>
    </div>
    <div>
        <label for="phone">Telefoonnummer</label>
        <i class="fas fa-asterisk"></i>
        <input
            type="text"
            placeholder="0612345678"
            id="phone"
            name="phone"
            [(ngModel)]="viabode.phone"
            required
        />
        <span class="errorMessage"></span>
    </div>
    <div>
        <label for="email">Emailadres</label>
        <i class="fas fa-asterisk"></i>
        <input
            type="text"
            placeholder="timmetje@viatim.nl"
            id="email"
            name="email"
            [(ngModel)]="viabode.email"
            required
        />
        <span class="errorMessage"></span>
    </div>
</div>

<!-- <h6>Scans</h6>
  <div class="row">
    <div>
        <label for="license">Rijbewijs</label><i class="fas fa-asterisk"></i>
        <label class="form-file"><i class="fas fa-arrow-alt-from-bottom fa-lg"></i> KIES EEN BESTAND
            <input type="file" id="license" name="license" [(ngModel)]="viabode.license" (change)="onFileInputChange()" accept="image/*">
        </label>
        <span class="errorMessage">{{licenseErr}}</span>
    </div>
    <div>
        <label for="passport">ID / Paspoort</label><i class="fas fa-asterisk"></i>
        <label class="form-file"><i class="fas fa-arrow-alt-from-bottom fa-lg"></i> KIES EEN BESTAND
            <input type="file" id="passport" name="passport" [(ngModel)]="viabode.passport" accept="image/*">
        </label>
        <span class="errorMessage">{{passportErr}}</span>
    </div>
    <div>
        <label for="insurance">Zorgverzekeringspas</label><i class="fas fa-asterisk"></i>
        <label class="form-file"><i class="fas fa-arrow-alt-from-bottom fa-lg"></i> KIES EEN BESTAND
            <input type="file" id="insurance" name="insurance" [(ngModel)]="viabode.insurance" accept="image/*">
        </label>
        <span class="errorMessage">{{insuranceErr}}</span>
    </div>
    <div>
        <label for="contract">Contract</label>
        <label class="form-file"><i class="fas fa-arrow-alt-from-bottom fa-lg"></i> KIES EEN BESTAND
            <input type="file" id="contract" name="contract" [(ngModel)]="viabode.contract" accept="image/*">
        </label>
        <span class="errorMessage">{{contractErr}}</span>
    </div>
    <div>
        <label for="photo">Foto van ViaBode</label><i class="fas fa-asterisk"></i>
        <label class="form-file"><i class="fas fa-arrow-alt-from-bottom fa-lg"></i> KIES EEN BESTAND
            <input type="file" id="photo" name="photo" [(ngModel)]="viabode.photo" accept="image/*">
        </label>
        <span class="errorMessage">{{photoErr}}</span>
    </div>
  </div> -->

<!-- <h6>In case of emergency contactgegevens</h6>
  <div class="row">
    <div>
        <label for="icename">Naam contactpersoon</label>
        <input type="text" id="icename" name="icename" [(ngModel)]="viabode.icename">
        <span class="errorMessage">{{icenameErr}}</span>
    </div>
    <div>
        <label for="icephone">Telefoonnummer contactpersoon</label>
        <input type="text" id="icephone" name="icephone" [(ngModel)]="viabode.icephone">
        <span class="errorMessage">{{icephoneErr}}</span>
    </div>
  </div> -->

<!-- <h6>Checklist</h6>
  <div class="row">
    <input type="checkbox" name="items" value="items" [(ngModel)]="viabode.items">
    <label for="items">Items meegeven aan ViaBode</label><br>
    <input type="checkbox" name="tag" value="tag" [(ngModel)]="viabode.tag">
    <label for="tag">Tag inclusief nummer</label><br>
    <input type="checkbox" name="jacket" value="jacket" [(ngModel)]="viabode.jacket">
    <label for="jacket">Winter / Zomerjas</label><br>
    <input type="checkbox" name="polo" value="polo" [(ngModel)]="viabode.polo">
    <label for="polo">Polo</label><br>
  </div> -->
<button class="btn inverse" (click)="onSubmit()">TOEVOEGEN</button>

<!-- EIND AANMAKEN VIABODE -->
