<div *ngIf="spinner$ | async as spinner">
    <div *ngIf="spinner" class="spinner-container">
        <div class="spinner-container__dialog">
            <h4>Even geduld a.u.b</h4>
            <i
                class="fa fa-spinner-scale fa-spin fa-3x fa-fw"
                style="--fa-animation-duration: 2s"
            ></i>
        </div>
    </div>
</div>
