import { Component, OnInit } from "@angular/core";
import { currentThemeName } from "../../helpers/dateHelper";

@Component({
    selector: "app-viatim-icon",
    templateUrl: "./viatim-icon.component.html",
})
export class ViatimIconComponent implements OnInit {
    theme: string = "normal";

    ngOnInit() {
        this.theme = currentThemeName();
    }
}
