<div class="content">
    <div class="message">
        <h1>Nieuw wachtwoord aanmaken</h1>
        <p>
            Vul hieronder je nieuwe wachtwoord in. Wij adviseren je om een wachtwoord te gebruiken
            dat uniek en niet te raden is.
        </p>
    </div>
    <form action="">
        <div class="row">
            <label for="password1">Wachtwoord</label><br />
            <input
                type="password"
                name="password1"
                [(ngModel)]="password1"
                id="password1"
                placeholder="Wachtwoord"
            />
        </div>
        <div class="row">
            <label for="password2">Herhaal wachtwoord</label><br />
            <input
                type="password"
                name="password2"
                [(ngModel)]="password2"
                id="password2"
                placeholder="Wachtwoord"
            />
        </div>
        <div *ngIf="error" class="error">
            <p>
                {{ error }}
            </p>
        </div>
        <div class="submit-row">
            <button [disabled]="!isFilledIn" class="btn inverse" (click)="submit()">
                Verstuur
            </button>
        </div>
    </form>
</div>
