import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import {
    UntypedFormBuilder,
    UntypedFormArray,
    Validators,
    UntypedFormControl,
    UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { DefaultWebshopConfig } from "../../../models/defaultWebshopConfig.model";
import { RotateValidator } from "../../../helpers/validators/RotateValidator";
import { BackendService } from "../../../services/backend.service";
import {
    WebshopTransporterConfig,
    WebshopTransporterConfigData,
} from "../../../models/webshopTransporterConfig.model";
import { Message } from "../../../models/message";
import { addMessage } from "../../../store/actions/message.actions";
import { NO_CUSTOM_DOCUMENT_COUNTRY_CODES } from "../../../helpers/constants";

type TransporterConfigurableFields = {
    transporterCode: string;
    configurableFields: string[];
};

@Component({
    selector: "app-webshops-config",
    templateUrl: "./webshops-config.component.html",
    styleUrls: ["./webshops-config.component.scss"],
})
export class WebshopsConfigComponent implements OnInit {
    userId: number;

    disabled: false;

    openAccordion: number;

    viawebshopId: string;

    defaultConfig: DefaultWebshopConfig[];

    webshopConfig: WebshopTransporterConfig[] = [];

    availableTransporters: {
        transporterCode: string;
        configurableFields: string[];
    }[];

    transporterConfig: WebshopTransporterConfig[] = [];

    transporterForm = this.fb.group({
        configs: this.fb.array([]),
    });

    schengenCountries = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;

    PARCEL_TYPES = ["MAILBOX", "NORMAL", "HEAVY", "*"];

    get configs() {
        return this.transporterForm.get("configs") as UntypedFormArray;
    }

    @Output() onCancel: EventEmitter<void> = new EventEmitter();

    constructor(
        private fb: UntypedFormBuilder,
        private backend: BackendService,
        private route: ActivatedRoute,
        private store: Store,
    ) {}

    ngOnInit() {
        this.route.params.subscribe((params) => {
            const { id } = params;
            this.userId = id;
        });

        this.loadConfig();
    }

    getFormGroup(i: number) {
        return (this.transporterForm.get("configs") as UntypedFormArray).controls[
            i
        ] as UntypedFormGroup;
    }

    open(i: number) {
        if (this.openAccordion === i) {
            this.openAccordion = null;
        } else if (this.getFormGroup(i).get("active").value) {
            this.openAccordion = i;
        }
    }

    cancel() {
        this.onCancel.emit();
    }

    getControls(i: number) {
        return (
            (this.transporterForm.get("configs") as UntypedFormArray).controls[
                i
            ] as UntypedFormGroup
        ).controls;
    }

    isValid(i: number, field: string) {
        return this.getControls(i)[field].valid;
    }

    addConfig(configFields: TransporterConfigurableFields) {
        const [foundConfig] = this.webshopConfig.filter(
            (w: WebshopTransporterConfig) => w.transporterCode == configFields.transporterCode,
        );
        if (foundConfig) {
            this.configs.push(
                this.fb.group({
                    transporterCode: foundConfig.transporterCode,
                    active: true,
                    accountID: new UntypedFormControl(foundConfig.data.accountID, [
                        Validators.pattern("^[0-9]{8}$"),
                    ]),
                    inboundAccountID: new UntypedFormControl(foundConfig.data.inboundAccountID, [
                        Validators.pattern("^[0-9]{8}$"),
                    ]),
                    rotate: new UntypedFormControl(foundConfig.data.rotate, RotateValidator),
                    sendMail: new UntypedFormControl(foundConfig.data.sendMail),
                    sameDay: new UntypedFormControl(foundConfig.data.sameDay),
                }),
            );
        } else {
            this.configs.push(
                this.fb.group({
                    transporterCode: configFields.transporterCode,
                    active: false,
                    accountID: new UntypedFormControl("", [Validators.pattern("^[0-9]{8}$")]),
                    inboundAccountID: new UntypedFormControl("", [
                        Validators.pattern("^[0-9]{8}$"),
                    ]),
                    rotate: new UntypedFormControl("", RotateValidator),
                    sendMail: new UntypedFormControl(false),
                    sameDay: new UntypedFormControl(false),
                }),
            );
        }
    }

    generateTransporterForm(transporters: TransporterConfigurableFields[]): void {
        if (transporters) {
            transporters.forEach((t: TransporterConfigurableFields) => this.addConfig(t));
        }
    }

    loadConfig() {
        forkJoin([
            this.backend.getDefaultConfig(),
            this.backend.getWebshopConfigById(this.userId),
        ]).subscribe(([defaultConfig, webshopConfig]) => {
            this.defaultConfig = defaultConfig;
            const transporters: TransporterConfigurableFields[] = defaultConfig.map((t) => ({
                transporterCode: t.transporterCode,
                configurableFields: t.configurableFields,
            }));
            this.availableTransporters = transporters;
            this.webshopConfig = webshopConfig;
            this.generateTransporterForm(transporters);
        });
    }

    onSubmit() {
        if (this.transporterForm.valid) {
            this.defaultConfig.forEach((defaultConfig: DefaultWebshopConfig) => {
                this.transporterForm.value.configs.forEach(
                    (transporterConfig: {
                        active: boolean;
                        transporterCode: string;
                        accountID: string | undefined;
                        inboundAccountID: string | undefined;
                        rotate: number;
                        sendMail: boolean | null;
                        sameDay: boolean | null;
                    }) => {
                        let rotate = null;
                        if (transporterConfig.rotate) {
                            rotate = transporterConfig.rotate;
                        }

                        const configWithExisitingPriorities: WebshopTransporterConfig[] =
                            this.webshopConfig.filter(
                                (w: WebshopTransporterConfig) =>
                                    transporterConfig.transporterCode == w.transporterCode,
                            );

                        let priorities = [];
                        if (configWithExisitingPriorities[0]) {
                            priorities = configWithExisitingPriorities[0].data.priorities;
                        }

                        if (
                            defaultConfig.transporterCode == transporterConfig.transporterCode &&
                            transporterConfig.active
                        ) {
                            const newConfig = new WebshopTransporterConfig(
                                transporterConfig.transporterCode,
                                new WebshopTransporterConfigData(
                                    priorities,
                                    transporterConfig.accountID,
                                    transporterConfig.inboundAccountID,
                                    Number.parseInt(rotate, 10),
                                    transporterConfig.sameDay,
                                    transporterConfig.sendMail,
                                ),
                            );
                            this.transporterConfig.push(newConfig);
                        }
                    },
                );
            });

            if (this.transporterConfig) {
                this.backend.putTransporterConfig(this.transporterConfig, this.userId).subscribe({
                    error: () => {
                        const message = Message.createErrorMessage(
                            "Fout bij het opslaan",
                            "Bij het opslaan is iets fout gegaan. Probeer het later nog een keer",
                        );
                        this.store.dispatch(addMessage({ message }));
                    },
                    complete: () => {
                        window.location.reload();
                    },
                });
            }
        } else {
            const message = Message.createErrorMessage(
                "Fout bij het opslaan",
                "Er zijn nog fouten in het formulier",
            );
            this.store.dispatch(addMessage({ message }));
        }
    }
}
