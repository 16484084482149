import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Package } from "../../models/package.model";
import { BackendService } from "../../services/backend.service";
import { E_USER_TYPES } from "../../helpers/constants";
import { AuthService } from "../../services/auth.service";
import { TablePage } from "../../models/tablePage.model";
import { PackageFilter } from "../../models/packageFilter";
import { DataService } from "../../services/data.service";
import { selectPackage, toggleSelectPackage } from "../../store/actions/selected-packages.actions";

@Component({
    selector: "app-package-table",
    templateUrl: "./package-table.component.html",
    styleUrls: ["./package-table.component.scss"],
})
export class PackageTableComponent {
    packages$: Observable<TablePage<Package>> = this.store.select((state) => state.packages);

    packageFilter$: Observable<PackageFilter> = this.store.select((state) => state.packageFilter);

    selectedPackages$: Observable<string[]> = this.store.select((state) => state.selectedPackages);

    private lastClickedIndex: number;

    constructor(
        private auth: AuthService,
        private backend: BackendService,
        private activatedRoute: ActivatedRoute,
        private data: DataService,
        private router: Router,
        private store: Store<{
            packageFilter: PackageFilter;
            packages: TablePage<Package>;
            selectedPackages: string[];
        }>,
    ) {}

    get isWebshop() {
        return this.auth.getAccountRole() === E_USER_TYPES.VIAWEBSHOP;
    }

    get isSupportUser() {
        return this.auth.getAccountRole() === E_USER_TYPES.SUPPORT;
    }

    labelModal(tracktrace: string) {
        this.data.changeMessage({ recipient: "print", content: tracktrace });
        this.router.navigateByUrl("/packages(modal:print-label)");
    }

    deleteModal(tracktrace: string) {
        this.data.changeMessage({ recipient: "delete", content: tracktrace });
        this.router.navigateByUrl("/packages(modal:delete-package)");
    }

    copyMessage(val: any) {
        this.backend.showMessage(true, `${val} is gekopieerd naar je klembord.`);
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
    }

    gotoPage(page: number) {
        const filter$ = this.packageFilter$.pipe(first(() => true, new PackageFilter()));
        filter$.subscribe((filter) => {
            const newFilter = filter.clone();
            newFilter.limit = filter.limit;
            newFilter.start = filter.limit * page + 1;
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: newFilter.toURLParams(),
            });
        });
    }

    clickCheckbox(event: MouseEvent, tracktrace: string, index: number) {
        if (event.shiftKey && this.lastClickedIndex != null && this.lastClickedIndex < index) {
            const packages = this.packages$.pipe(first());
            packages.subscribe((page) => {
                for (let x = this.lastClickedIndex; x < index + 1; x++) {
                    this.store.dispatch(selectPackage({ tracktrace: page.elements[x].tracktrace }));
                }
            });
        } else {
            this.store.dispatch(toggleSelectPackage({ tracktrace }));
        }
        this.lastClickedIndex = index;
    }
}
