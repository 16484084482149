import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BackendService } from "../../services/backend.service";
import { ViaBode } from "../../models/viabode.model";

@Component({
    selector: "app-create-user-create-viabode",
    templateUrl: "./create-user-create-viabode.component.html",
    styleUrls: ["./create-user-create-viabode.component.scss"],
})
export class CreateUserCreateViabodeComponent {
    viabode: ViaBode = ViaBode.createEmpty();

    userName: string;

    accountPassword: string;

    constructor(private backend: BackendService, private router: Router) {}

    onSubmit() {
        this.backend.createViabode(this.viabode).subscribe({
            complete: () => {
                this.router.navigateByUrl("/");
                this.backend.showMessage(true, `ViaBode ${this.userName} is aangemaakt!`);
            },
            error: () => {
                this.backend.showMessage(
                    false,
                    "Foutcode 9a: Het aanmaken van een ViaBode account is niet gelukt.",
                );
            },
        });
    }
}
