import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "../shared/shared.module";

import { ControlPanelModule } from "../control-panel/control-panel.module";
import { WebshopsComponent } from "./webshops.component";
import { WebshopsRoutingModule } from "./webshops-routing.module";
import { WebshopsDetailComponent } from "./webshops-detail/webshops-detail.component";
import { WebshopsCreateComponent } from "./webshops-create/webshops-create.component";
import { WebshopsEditComponent } from "./webshops-edit/webshops-edit.component";
import { EditWebshopFormComponent } from "./webshops-edit/edit-webshop-form/edit-webshop-form.component";
import { WebshopsActivationModalComponent } from "./webshops-modals/webshops-activation-modal/webshops-activation-modal.component";
import { WebshopsConfigComponent } from "./webshops-edit/webshops-config/webshops-config.component";
import { WebshopsConfigPriorityComponent } from "./webshops-edit/webshops-config-priority/webshops-config-priority.component";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        ControlPanelModule,
        CommonModule,
        WebshopsRoutingModule,
        NgbModule,
    ],
    declarations: [
        WebshopsComponent,
        WebshopsDetailComponent,
        WebshopsCreateComponent,
        WebshopsEditComponent,
        EditWebshopFormComponent,
        WebshopsActivationModalComponent,
        WebshopsConfigComponent,
        WebshopsConfigPriorityComponent,
    ],
})
export class WebshopsModule {}
