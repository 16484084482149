<ng-template #internalToolTip>
    <table class="tooltip" *ngIf="selectedRow">
        <thead>
            <td>Naam</td>
            <td>Prijs</td>
        </thead>
        <tr *ngFor="let element of selectedRow.internalPriceComponents">
            <td>{{ element.name }}</td>
            <td>{{ element.price | priceAmount }}</td>
        </tr>
    </table>
</ng-template>

<ng-template #externalToolTip>
    <table class="tooltip" *ngIf="selectedRow">
        <thead>
            <td>Naam</td>
            <td>Prijs</td>
        </thead>
        <tr *ngFor="let element of selectedRow.externalPriceComponents">
            <td>{{ element.name }}</td>
            <td>{{ element.price | priceAmount }}</td>
        </tr>
    </table>
</ng-template>

<app-cp-page>
    <div class="import">
        <h2>Vergelijk inkoop en verkoop</h2>
        <div class="table-wrapper" *ngIf="items">
            <table class="table">
                <thead>
                    <tr class="table__header">
                        <th class="col-md-1">Externe Reference</th>
                        <th class="col-md-2">Type</th>
                        <th class="col-md-2">Webshop</th>
                        <th class="col-md-2">Product Naam</th>
                        <th class="col-md-1">Land</th>
                        <th class="col-md-1">Vervoerder Prijs</th>
                        <th class="col-md-1">Onze Prijs</th>
                        <th class="col-md-1">Verschil</th>
                    </tr>
                    <tr class="table__filters">
                        <th class="col-md-1"></th>
                        <th class="col-md-2">
                            <select
                                class="form-select"
                                (change)="updateInvoices($event)"
                                [(ngModel)]="externalPriceTypeFilter"
                            >
                                <option value="">Alle</option>
                                <option
                                    *ngFor="let type of externalPriceTypeOptions"
                                    [ngValue]="type.id"
                                >
                                    {{ type.name }}
                                </option>
                            </select>
                        </th>
                        <th class="col-md-2">
                            <select
                                class="form-select"
                                [(ngModel)]="webshopIdFilter"
                                (change)="updateInvoices($event)"
                            >
                                <option [ngValue]="null">Alle</option>
                                <option *ngFor="let shop of webshops" [ngValue]="shop.id">
                                    {{ shop.displayName }}
                                </option>
                            </select>
                        </th>
                        <th class="col-md-2"></th>
                        <th class="col-md-1"></th>
                        <th class="col-md-1"></th>
                        <th class="col-md-1"></th>
                        <th class="col-md-1"></th>
                    </tr>
                </thead>
                <tr *ngFor="let row of items.elements" (mouseenter)="selectRow(row)">
                    <td>
                        <a [routerLink]="['/packages', row.tracktrace]">{{
                            row.externalReference.split("-").pop()
                        }}</a>
                    </td>
                    <td>{{ row.carrierCode }} - {{ getExternalPriceType(row) }}</td>
                    <td>
                        {{ row.companyName | replaceEmptyString }}
                    </td>
                    <td>{{ row.productName }}</td>
                    <td>{{ row.country }}</td>
                    <td>
                        {{ row.externalPrice | priceAmount }}
                        <button
                            (click)="selectRow(row)"
                            [ngbTooltip]="externalToolTip"
                            *ngIf="row.externalPriceComponents.length > 1"
                        >
                            +
                        </button>
                    </td>
                    <td>
                        {{ row.price | priceAmount }}
                        <button
                            (click)="selectRow(row)"
                            [ngbTooltip]="internalToolTip"
                            *ngIf="row.internalPriceComponents.length > 1"
                        >
                            +
                        </button>
                    </td>
                    <td>{{ row.priceDifference | priceAmount }}</td>
                </tr>
            </table>
            <app-pagination [tablePage]="items" (onPageChange)="gotoPage($event)"></app-pagination>
        </div>
    </div>
</app-cp-page>
