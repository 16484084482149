<nav *ngIf="tablePage.numberOfPages > 1">
    <ul class="pagination">
        <li [ngClass]="{ disabled: tablePage.isFirstPage }" class="page-item">
            <a (click)="gotoPage(tablePage.pageNumber - 1)" class="page-link">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
            </a>
        </li>

        <li
            *ngFor="let pageNumber of navigationPageNumbers"
            [ngClass]="{
                active: tablePage.pageNumber === pageNumber,
                disabled: pageNumber === null
            }"
            class="page-item"
        >
            <a *ngIf="pageNumber !== null" (click)="gotoPage(pageNumber)" class="page-link">{{
                pageNumber + 1
            }}</a>
            <a *ngIf="pageNumber === null" class="page-link">...</a>
        </li>
        <li class="page-item" [ngClass]="{ disabled: tablePage.isLastPage }">
            <a (click)="gotoPage(tablePage.pageNumber + 1)" class="page-link">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
            </a>
        </li>
    </ul>
</nav>
