<app-cp-page>
    <div class="import">
        <h2>Importeren vervoerdersfacturatie</h2>
        <div *ngIf="step > 0" class="card-header mb-2">
            <div class="summary">
                <div class="transporter-icon">
                    <span class="text-muted">Vervoerder: {{ transporter.name }}</span>
                </div>
                <div *ngIf="step > 1"><span class="text-muted">Bestand: </span>{{ fileName }}</div>
            </div>
        </div>
        <form>
            <div *ngIf="step === 0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <label class="input-group-text" for="transporter">Vervoerder</label>
                            <select
                                class="form-select import__input"
                                id="transporter"
                                [(ngModel)]="transporter"
                                name="transporter"
                            >
                                <option selected>Kies...</option>
                                <option
                                    *ngFor="let transporter of transporters"
                                    [ngValue]="transporter"
                                >
                                    {{ transporter.name }}
                                </option>
                            </select>
                        </div>
                        <p class="text-muted">
                            Kies de vervoerder waarvoor je de factuur wilt importeren.
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="step === 1">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <input
                            type="file"
                            #uploader
                            (change)="upload($event)"
                            placeholder="Upload file"
                            accept=".csv"
                        />
                        <button
                            class="btn btn-primary btn-lg upload-button"
                            (click)="uploader.click()"
                        >
                            Upload CSV
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="step === 2">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <label class="input-group-text input-group-text-wide" for="amountField"
                                >Aantal kolom</label
                            >
                            <select
                                class="form-select form-select-wide import__input"
                                [(ngModel)]="amountField"
                                *ngIf="fields"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option [value]="field" *ngFor="let field of fields">
                                    {{ field }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mb-3">
                            <label
                                class="input-group-text input-group-text-wide"
                                for="externalReferenceField"
                                >Vervoerder Referentie kolom</label
                            >
                            <select
                                class="form-select form-select-wide import__input"
                                [(ngModel)]="externalReferenceField"
                                *ngIf="fields"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option [value]="field" *ngFor="let field of fields">
                                    {{ field }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mb-3">
                            <label
                                class="input-group-text input-group-text-wide"
                                for="productNameField"
                                >Product naam kolom</label
                            >
                            <select
                                class="form-select form-select-wide import__input"
                                [(ngModel)]="productNameField"
                                *ngIf="fields"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option [value]="field" *ngFor="let field of fields">
                                    {{ field }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mb-3">
                            <label class="input-group-text input-group-text-wide" for="priceField"
                                >Prijs kolom</label
                            >
                            <select
                                class="form-select form-select-wide import__input"
                                [(ngModel)]="priceField"
                                *ngIf="fields"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option [value]="field" *ngFor="let field of fields">
                                    {{ field }}
                                </option>
                            </select>
                        </div>
                        <div class="input-group mb-3">
                            <label
                                class="input-group-text input-group-text-wide"
                                for="externalInvoiceIdField"
                                >Factuur ID</label
                            >
                            <select
                                class="form-select form-select-wide import__input"
                                [(ngModel)]="externalInvoiceIdField"
                                *ngIf="fields"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option [value]="field" *ngFor="let field of fields">
                                    {{ field }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="step === 3">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3" *ngIf="!amountField">
                            <label class="input-group-text input-group-text-wide" for="amountField"
                                >Aantal kolom waarde</label
                            >
                            <input
                                type="number"
                                class="import__textinput"
                                [(ngModel)]="amountValue"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                        <div class="input-group mb-3" *ngIf="!productNameField">
                            <label
                                class="input-group-text input-group-text-wide"
                                for="productNameValue"
                                >Product naam waarde</label
                            >
                            <input
                                class="import__textinput"
                                type="text"
                                [(ngModel)]="productNameValue"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                        <div class="input-group mb-3" *ngIf="!externalInvoiceIdField">
                            <label
                                class="input-group-text input-group-text-wide"
                                for="externalInvoiceIdValue"
                                >Factuur ID</label
                            >
                            <input
                                class="import__textinput"
                                type="text"
                                [(ngModel)]="externalInvoiceIdValue"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="step === 4">
                <div class="table-wrapper">
                    <table class="table">
                        <thead>
                            <tr class="table__header">
                                <th class="col-md-2">{{ amountField || "Aantal" }}</th>
                                <th class="col-md-3">{{ externalReferenceField }}</th>
                                <th class="col-md-3">{{ productNameField || "Productnaam" }}</th>
                                <th class="col-md-2">{{ priceField }}</th>
                                <th class="col-md-2">
                                    {{ externalInvoiceIdField || "Factuurnummer" }}
                                </th>
                            </tr>
                        </thead>
                        <tr *ngFor="let row of exampleTablePage.elements">
                            <td>{{ row.totalAmount }}</td>
                            <td>{{ row.externalReference }}</td>
                            <td>{{ row.productName }}</td>
                            <td>{{ row.price }}</td>
                            <td>{{ row.externalInvoiceId }}</td>
                        </tr>
                    </table>
                </div>
                <app-pagination
                    [tablePage]="exampleTablePage"
                    (onPageChange)="gotoExamplePage($event)"
                ></app-pagination>
            </div>
        </form>
        <div class="import__buttons">
            <button
                *ngIf="step === 0"
                class="btn btn-primary import__button"
                (click)="selectTransporter()"
            >
                OK
            </button>
            <button *ngIf="step >= 1" class="btn btn-primary import__button" (click)="back()">
                Terug
            </button>
            <button
                *ngIf="step === 2"
                class="btn btn-primary import__button"
                [disabled]="!amountField && !externalInvoiceIDField && !externalReferenceField"
                (click)="checkFields()"
            >
                OK
            </button>

            <button *ngIf="step === 3" class="btn btn-primary import__button" (click)="example()">
                OK
            </button>
            <button
                *ngIf="step === 4"
                class="btn btn-primary import__button"
                (click)="import()"
                [disabled]="importInProgress"
            >
                OK
            </button>
        </div>
    </div>
    <app-transporter-invoice-list></app-transporter-invoice-list>
</app-cp-page>
