import { isEmpty } from "lodash-es";
import { GPSCoordinates } from "./gpsCoordinates.model";
import { ServicePointJSON } from "./json/servicePointJSON";

export class ServicePoint {
    constructor(
        public id: string,
        public name: string,
        public type: string,
        public distance: number,
        public city: string,
        public housenr: string,
        public houseNumberExtra: string,
        public postcode: string,
        public streetname: string,
        public country: string,
        public location: GPSCoordinates,
        public externalId: number,
    ) {}

    static fromJSON(json: ServicePointJSON) {
        if (isEmpty(json)) {
            return undefined;
        }
        return new ServicePoint(
            json.id,
            json.name,
            json.type,
            json.distance,
            json.location.city,
            json.location.housenr,
            json.location.housenr_extra,
            json.location.postcode,
            json.location.streetname,
            json.location.country,
            GPSCoordinates.fromJSON(json.location.gps),
            json.external_id,
        );
    }

    toJSON(): ServicePointJSON {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            distance: this.distance,
            location: {
                city: this.city,
                gps: this.location.toJSON(),
                housenr: this.housenr,
                housenr_extra: this.houseNumberExtra,
                postcode: this.postcode,
                streetname: this.streetname,
                country: this.country,
            },
            external_id: this.externalId,
        };
    }
}
