<app-cp-page>
    <div class="standard-container" *ngIf="user">
        <div class="header">
            <h1>Account Managing voor {{ user.companyName }}</h1>
            <p>Aangemaakt op {{ user.dateCreated | splitDate }}</p>
        </div>

        <table>
            <tr>
                <td>Naam</td>
                <th>{{ user | fullName }}</th>
            </tr>
            <tr>
                <td>Bedrijfsnaam</td>
                <th>{{ user.companyName }}</th>
            </tr>
            <tr *ngIf="userIsWebshop">
                <td>Website</td>
                <th>{{ user.domain ? user.domain : "-" }}</th>
            </tr>
            <tr class="borderUnderRow">
                <td>Gebruikersnaam</td>
                <th>{{ user.userName }}</th>
            </tr>
            <tr>
                <td>Straat + huisnummer</td>
                <th>
                    {{ user.streetname }} {{ user.houseNumber }}
                    {{ user.houseNumberExtra }}
                </th>
            </tr>
            <tr>
                <td>Postcode</td>
                <th>{{ user.postcode }}</th>
            </tr>
            <tr>
                <td>Stad</td>
                <th>{{ user.city }}</th>
            </tr>
            <tr class="borderUnderRow">
                <td>Land</td>
                <th>{{ user.country }}</th>
            </tr>
            <tr>
                <td>Emailadres</td>
                <th>{{ user.email }}</th>
            </tr>
            <tr [ngClass]="!userIsWebshop ? 'borderUnderRow' : ''">
                <td>Telefoonnummer</td>
                <th>{{ user.phone ? user.phone : "-" }}</th>
            </tr>
            <tr *ngIf="userIsWebshop">
                <td>KVK nummer</td>
                <th>{{ user.kvk ? user.kvk : "-" }}</th>
            </tr>
            <tr *ngIf="userIsWebshop">
                <td>IBAN</td>
                <th>{{ user.iban ? user.iban : "-" }}</th>
            </tr>
            <tr *ngIf="userIsWebshop" class="borderUnderRow">
                <td>BTW nummer</td>
                <th>{{ user.btw ? user.btw : "-" }}</th>
            </tr>
            <tr>
                <td>Te gebruiken vervoerders</td>
                <th>{{ transporters }}</th>
            </tr>
        </table>
    </div>
    <div class="infoContainer">
        <div class="flexRow">
            <div><i class="fas fa-pencil-alt icon"></i>Klopt er informatie niet (meer)?</div>
            <div *ngIf="!canEdit">Neem dan contact op met ons!</div>
        </div>
        <button
            *ngIf="user && canEdit"
            class="btn inverse"
            [routerLink]="['/account-management/edit']"
        >
            Pas het hier aan
        </button>
    </div>
</app-cp-page>
