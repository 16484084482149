import { Pipe, PipeTransform } from "@angular/core";
import { E_EXTERNAL_PARTIES } from "../constants/transporters";

@Pipe({ name: "externalDisplayName" })
export class ExternalDisplayName implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(externalId: number) {
        switch (externalId) {
            case E_EXTERNAL_PARTIES.VIATIM:
                return "ViaTim";
            case E_EXTERNAL_PARTIES.MYPARCEL:
                return "PostNL";
            case E_EXTERNAL_PARTIES.DPD:
                return "DPD";
            case E_EXTERNAL_PARTIES.FEDEX:
                return "FedEx";
            case E_EXTERNAL_PARTIES.RJP:
                return "Red je Pakketje";
            case E_EXTERNAL_PARTIES.PARCELPRO:
                return "PostNL";
            case E_EXTERNAL_PARTIES.ASCENDIA:
                return "Asendia";
            case E_EXTERNAL_PARTIES.SBS:
                return "SendByShare";
            case E_EXTERNAL_PARTIES.POSTNL:
                return "PostNL";
            case E_EXTERNAL_PARTIES.BUDBEE:
                return "Budbee";
            case E_EXTERNAL_PARTIES.POSTNL_BRIEF:
                return "PostNL briefpakket";
            case E_EXTERNAL_PARTIES.CYCLOON:
                return "Cycloon";
            case E_EXTERNAL_PARTIES.BPOST:
                return "Bpost";
            case E_EXTERNAL_PARTIES.DHL_4Y1:
                return "DHL For You 1";
            case E_EXTERNAL_PARTIES.DHL_4Y2:
                return "DHL For You 2";
            case E_EXTERNAL_PARTIES.DHL_DE:
                return "DHL Duitsland";
            case E_EXTERNAL_PARTIES.COLIS_PRIVE:
                return "Colis Privé";
            default:
                return "Onbekend";
        }
    }
}
