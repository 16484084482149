import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { StatusCodes } from "http-status-codes";
import { environment } from "../../environments/environment";
import { PriceCategory } from "../models/priceCategory.model";
import { DeleteConflictError, ServerError } from "./errors";
import { PriceCategoryJSON } from "../models/json/priceCategoryJSON";
import { ExternalPriceType } from "../models/externalPriceType";
import { PickupPriceListItem } from "../models/pickupPriceListItem.model";
import { PackagePriceListItem } from "../models/packagePriceListItem.model";
import { ExternalPriceTypeJSON } from "../models/json/externalPriceTypeJSON";

@Injectable()
export class PriceCategoryService {
    constructor(private http: HttpClient) {}

    loadPriceCategories(): Observable<PriceCategory[]> {
        return this.http
            .get<PriceCategoryJSON[]>(`${environment.apiBase}/webshop/invoices/price-categories`)
            .pipe(map((response) => response.map(PriceCategory.fromJSON)));
    }

    updatePriceCategory(priceCategory: PriceCategory): Observable<void> {
        const data = {
            name: priceCategory.name,
        };
        return this.http.put<void>(
            `${environment.apiBase}/webshop/invoices/price-categories/${priceCategory.id}`,
            data,
        );
    }

    deletePriceCategory(id: number): Observable<void> {
        return this.http
            .delete<void>(`${environment.apiBase}/webshop/invoices/price-categories/${id}`)
            .pipe(
                catchError((e) => {
                    if (e.status === StatusCodes.CONFLICT) {
                        throw new DeleteConflictError();
                    }
                    throw new ServerError(e);
                }),
            );
    }

    creartePriceCategory(name: string): Observable<void> {
        const data = {
            name,
        };
        return this.http.post<void>(
            `${environment.apiBase}/webshop/invoices/price-categories/`,
            data,
        );
    }

    postWebshopPrices(
        pickupPrices: PickupPriceListItem[],
        packagePrices: PackagePriceListItem[],
        date: string,
    ): Observable<void> {
        const data = {
            package_prices: packagePrices.map((x) => x.toSaveJSON()),
            pickup_prices: pickupPrices.map((x) => x.toSaveJSON()),
            from: date,
        };
        return this.http.post<void>(`${environment.apiBase}/webshop/invoices/prices/`, data);
    }

    getExternalPriceTypes(): Observable<ExternalPriceType[]> {
        return this.http
            .get<ExternalPriceTypeJSON[]>(
                `${environment.apiBase}/webshop/invoices/external-price-types`,
            )
            .pipe(map((response) => response.map(ExternalPriceType.fromJSON)));
    }
}
