import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
    selector: "app-spinner",
    templateUrl: "./spinner.component.html",
    styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent {
    spinner$: Observable<boolean> = this.store.select((state) => state.busy);

    constructor(private store: Store<{ busy: boolean }>) {}
}
