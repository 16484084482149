import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "unknown",
})
export class UnknownValuePipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(value: string, replacement: string = "-"): string {
        if (value == "" || value == null) {
            return replacement;
        }
        return value;
    }
}
