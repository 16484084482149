<app-cp-page>
    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
        <div class="col" *ngIf="canCreatePackage">
            <div class="card" (click)="newPackage()">
                <div class="card-header">
                    <i class="fad fa-box fa-4x"></i>
                </div>
                <div class="card-body">
                    <h4>Nieuw pakket</h4>
                    <p>Maak een nieuw pakket aan.</p>
                </div>
            </div>
        </div>

        <div class="col" *ngIf="canCreateReturnLabels">
            <div class="card" (click)="newReturnLabels()">
                <div class="card-header">
                    <i class="fad fa-house-person-return fa-4x"></i>
                </div>
                <div class="card-body">
                    <h4>Retour labels</h4>
                    <p>Maak retour labels aan</p>
                </div>
            </div>
        </div>

        <div class="col" *ngIf="canCreatePackage">
            <div class="card" (click)="newPackageBulk()">
                <div class="card-header">
                    <i class="fad fa-file-alt fa-5x"></i>
                </div>
                <div class="card-body">
                    <h4>CSV Import</h4>
                    <p>Maak pakketten aan door middel van een CSV import.</p>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card" (click)="openStats()" *ngIf="hasStats">
                <div class="card-header">
                    <i class="fad fa-chart-line fa-5x"></i>
                </div>
                <div class="card-body">
                    <h4>Statistieken</h4>
                    <p>Zie hier de statistieken van je pakketten.</p>
                </div>
            </div>
        </div>

        <div class="col" *ngIf="canExportPackages">
            <div class="card" (click)="downloadExport()">
                <div class="card-header">
                    <i class="fad fa-file-csv fa-5x fa-swap-opacity"></i>
                </div>
                <div class="card-body">
                    <h4>Download Export</h4>
                    <p>Download de backend pakketten export!</p>
                </div>
            </div>
        </div>

        <div class="col" *ngIf="canExportPackages">
            <div class="card" (click)="openInvoicingExportModal()">
                <div class="card-header">
                    <i class="fad fa-file-csv fa-5x fa-swap-opacity"></i>
                </div>
                <div class="card-body">
                    <h4>Facturatie Export</h4>
                    <p>Download de facturatie export!</p>
                </div>
            </div>
        </div>

        <div class="col" *ngIf="canBulkStatusUpdate">
            <div class="card" (click)="openBulkUpdate()">
                <div class="card-header">
                    <i class="fad fa-box fa-4x"></i>
                </div>
                <div class="card-body">
                    <h4>Bulk Status Update</h4>
                    <p>Update opgehaalde pakketten naar status in bezit van externe vervoerder</p>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card" (click)="openStats()" *ngIf="hasStats">
                <div class="card-header">
                    <i class="fad fa-shipping-fast fa-5x"></i>
                </div>
                <div class="card-body">
                    <h4>{{ statistics["notDelivered"] }}</h4>
                    <p>Pakketten zijn nog onderweg naar de consument!</p>
                </div>
            </div>
        </div>

        <div class="col" *ngIf="canCreateUser">
            <div class="card" (click)="createUser()">
                <div class="card-header">
                    <i class="fad fa-users fa-5x"></i>
                </div>
                <div class="card-body">
                    <h4>Nieuwe gebruiker</h4>
                    <p>Maak een nieuwe gebruiker aan.</p>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card" (click)="openTipWebshop()">
                <div class="card-header">
                    <i class="fad fa-gift fa-5x"></i>
                </div>
                <div class="card-body">
                    <h4>Tip een webshop</h4>
                    <p>Geef ons een tip dan geven wij jou iets lekkers!</p>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card" (click)="openPatchNotes()">
                <div class="card-header">
                    <i [ngClass]="!readNews ? 'fad fa-cogs fa-5x blink' : 'fad fa-cogs fa-5x'"></i>
                </div>
                <div class="card-body">
                    <h4>Laatste veranderingen</h4>
                    <p>Lees hier onze laatste veranderingen.</p>
                </div>
            </div>
        </div>

        <div class="col" *ngIf="canSyncDHL">
            <div class="card" (click)="openSyncLabels()">
                <div class="card-header">
                    <i class="fad fa-sync fa-5x"></i>
                </div>
                <div class="card-body">
                    <h4>Synchroniseer</h4>
                    <p>Synchroniseer DHL labels</p>
                </div>
            </div>
        </div>
        <div class="col" *ngIf="canSyncWarehouse">
            <div class="card" (click)="syncWarehouse()">
                <div class="card-header" [ngClass]="warehouseSyncing ? 'insert-animation' : ''">
                    <i class="fad fa-arrow-right fa-3x arrow"></i>
                    <i class="fad fa-database fa-5x database"></i>
                </div>
                <div class="card-body">
                    <h4>Datawarehouse</h4>
                    <p>Synchroniseer Datawarehouse database met control panel database</p>
                </div>
            </div>
        </div>
    </div>
</app-cp-page>
