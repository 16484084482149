import { Store } from "@ngrx/store";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { BackendService } from "../../services/backend.service";
import { Package } from "../../models/package.model";
import { User } from "../../models/user.model";
import { Message } from "../../models/message";
import { CountryService } from "../../services/country.service";
import { SHIPPING_COMPANIES } from "../../helpers/constants/transporters";

@Component({
    selector: "app-copy-label-modal",
    templateUrl: "./copy-label-modal.component.html",
    styleUrls: ["./copy-label-modal.component.scss"],
})
export class CopyLabelModalComponent implements OnInit, OnDestroy {
    currentUser$: Observable<User> = this.store.select((state) => state.currentUser);

    private tracktrace: string;

    package: Package;

    optionSelected: number;

    private sub: Subscription;

    availableCarriers = [];

    constructor(
        private backend: BackendService,
        private countryService: CountryService,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<{ messages: Message[]; currentUser: User }>,
    ) {}

    ngOnInit() {
        const urlraw = this.router.url.split("/")[2];
        const url = urlraw.split("(")[0];
        this.tracktrace = url;
        this.sub = this.route.params.subscribe(() => {
            this.backend.getPackageByTrackTrace(this.tracktrace).subscribe(
                (result) => {
                    this.package = result;
                    this.package.service_point = null;
                    delete this.package.recipient.id;
                    this.currentUser$.subscribe((user: User) => {
                        if (user) {
                            this.availableCarriers = SHIPPING_COMPANIES.filter(
                                (company) =>
                                    user.rights.includes(`can_use_${company.code}`) &&
                                    this.countryService
                                        .countriesThatExternalCanSendFrom(company.value)
                                        .map((c) => c.code)
                                        .includes(result.sender.country) &&
                                    this.countryService
                                        .countriesThatExternalCanSendTo(company.value)
                                        .map((c) => c.code)
                                        .includes(result.recipient.country),
                            );
                            this.optionSelected = this.availableCarriers.find(
                                (carrier) => carrier.value == this.package.external_id,
                            ).value;
                        }
                    });
                },
                () => {
                    this.router.navigateByUrl("/packages");
                },
            );
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit() {
        this.package.external_id = this.optionSelected;
        this.backend.createPackage(this.package).subscribe(
            () => {
                this.router.navigateByUrl("/packages");
                this.backend.showMessage(true, "Pakket is gekopieerd!");
            },
            () => {
                this.backend.showMessage(
                    false,
                    "Foutcode 10b: Het aanmaken van een kopie is niet gelukt.",
                );
            },
        );
    }
}
