import * as moment from "moment";
import { TransporterInvoiceJSON } from "./json/transporterInvoiceJSON";

export class TransporterInvoice {
    constructor(
        public externalInvoiceId: string,
        public externalId: number,
        public numberOfItems: number,
        public first: moment.Moment,
        public last: moment.Moment,
    ) {}

    static fromJSON(json: TransporterInvoiceJSON): TransporterInvoice {
        return new TransporterInvoice(
            json.external_invoice_id,
            json.external_id,
            json.number_of_items,
            moment(json.first),
            moment(json.last),
        );
    }
}
