import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "brackets",
})
export class BracketsPipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(input: string): string {
        if (input == null) {
            return "";
        }
        return `(${input})`;
    }
}
