import { Component, OnInit } from "@angular/core";
import { Validators, UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { mergeMap } from "rxjs/operators";
import {
    WebshopTransporterConfig,
    WebshopTransporterConfigData,
} from "../../../models/webshopTransporterConfig.model";
import { BackendService } from "../../../services/backend.service";
import { Message } from "../../../models/message";
import { addMessage } from "../../../store/actions/message.actions";
import { NO_CUSTOM_DOCUMENT_COUNTRY_CODES } from "../../../helpers/constants";

type Priority = {
    countryTo: string;
    countryFrom: string;
    priority: number;
    parcelType?: string;
};

type WebshopConfigType = {
    transporterCode: string;
    countryTo: string;
    countryFrom: string;
    priority: number;
    parcelType: string;
    index: number;
};

@Component({
    selector: "app-webshops-config-priority",
    templateUrl: "./webshops-config-priority.component.html",
    styleUrls: ["./webshops-config-priority.component.scss"],
})
export class WebshopsConfigPriorityComponent implements OnInit {
    userId: number;

    submitted: boolean = false;

    selected: boolean = false;

    transformedWebshopConfig: WebshopConfigType[] = [];

    PARCEL_TYPES = ["MAILBOX", "NORMAL", "HEAVY", "*"];

    schengenCountries = NO_CUSTOM_DOCUMENT_COUNTRY_CODES;

    priorityForm = this.fb.group({
        transporterCode: ["", Validators.required],
        countryFrom: ["", Validators.required],
        countryTo: ["", Validators.required],
        parcelType: ["", Validators.required],
    });

    webshopConfig: WebshopTransporterConfig[] = [];

    constructor(
        private fb: UntypedFormBuilder,
        private backend: BackendService,
        private route: ActivatedRoute,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            const { id } = params;
            this.userId = id;
        });

        this.getWebshopConfig();
    }

    getWebshopConfig() {
        if (this.userId) {
            this.route.paramMap
                .pipe(mergeMap(() => this.backend.getWebshopConfigById(this.userId)))
                .subscribe((webshopConfig) => {
                    this.webshopConfig = webshopConfig;
                    this.transformedWebshopConfig = this.webshopConfig.flatMap(
                        (config: WebshopTransporterConfig) => {
                            if (config.data && config.data.priorities) {
                                return config.data.priorities.map(
                                    (prio: Priority, index: number) => ({
                                        transporterCode: config.transporterCode,
                                        countryTo: prio.countryTo,
                                        countryFrom: prio.countryFrom,
                                        priority: prio.priority,
                                        parcelType: prio.parcelType,
                                        index,
                                    }),
                                );
                            }
                            return [];
                        },
                    );
                });
        }
    }

    isSelected() {
        this.selected = !this.selected;
    }

    onSubmitPriorityRules() {
        this.submitted = true;

        const PRIORITY_VALUE = new Date().getTime();

        if (this.priorityForm.valid) {
            const newTransporterConfig: WebshopTransporterConfig = this.webshopConfig.find(
                (w: WebshopTransporterConfig) =>
                    w.transporterCode == this.priorityForm.value.transporterCode,
            );
            const newPriority: Priority = {
                countryTo: this.priorityForm.value.countryTo,
                countryFrom: this.priorityForm.value.countryFrom,
                priority: PRIORITY_VALUE,
            };

            if (this.priorityForm.value.parcelType !== "*") {
                newPriority.parcelType = this.priorityForm.value.parcelType;
            }

            newTransporterConfig.data.priorities.push(newPriority);

            const newWebshopConfig = this.webshopConfig.map((w: WebshopTransporterConfig) =>
                w.transporterCode == newTransporterConfig.transporterCode
                    ? newTransporterConfig
                    : w,
            );

            this.backend.putTransporterConfig(newWebshopConfig, this.userId).subscribe(
                () => {
                    window.location.reload();
                },
                () => {
                    const message = Message.createErrorMessage(
                        "Fout bij het opslaan",
                        "Bij het opslaan is iets fout gegaan. Probeer het later nog een keer",
                    );
                    this.store.dispatch(addMessage({ message }));
                    window.location.reload();
                },
            );
        }
    }

    deleteRow({ transporterCode: selected_transporterCode, index }: WebshopConfigType) {
        const result = this.webshopConfig.map(
            ({ transporterCode, data }: WebshopTransporterConfig) => {
                const webshopTransporterConfigData = {
                    ...data,
                    priorities: data.priorities.filter(
                        (_, i: number) =>
                            i !== index || transporterCode !== selected_transporterCode,
                    ),
                };
                return new WebshopTransporterConfig(
                    transporterCode,
                    new WebshopTransporterConfigData(
                        webshopTransporterConfigData.priorities,
                        webshopTransporterConfigData.accountID,
                        webshopTransporterConfigData.inboundAccountID,
                        webshopTransporterConfigData.rotate,
                        webshopTransporterConfigData.sameDay,
                        webshopTransporterConfigData.sendMail,
                    ),
                );
            },
        );

        if (result) {
            this.backend.putTransporterConfig(result, this.userId).subscribe(() => {
                window.location.reload();
            });
        }
    }
}
