<div *ngIf="progress$ | async as progress">
    <div *ngIf="progress.busy" class="progress-container">
        <div class="progress-container__dialog">
            <p>{{ progress.count }} / {{ progress.total }}</p>
            <div class="progress-bar-background">
                <div
                    class="progress-bar bg-primary"
                    role="progressbar"
                    [attr.aria-valuenow]="progress.count"
                    aria-valuemin="0"
                    [attr.aria-valuemax]="progress.total"
                    [style.width.%]="(progress.count / progress.total) * 100"
                ></div>
            </div>
        </div>
    </div>
</div>
