import { Component, OnInit } from "@angular/core";
import { currentThemeName } from "../../helpers/dateHelper";

@Component({
    selector: "app-dialog-page",
    templateUrl: "./dialog-page.component.html",
    styleUrls: ["./dialog-page.component.scss"],
})
export class DialogPageComponent implements OnInit {
    theme: string = "normal-theme";

    ngOnInit(): void {
        this.theme = `${currentThemeName()}-theme`;
    }
}
