import { Component, Input } from "@angular/core";
import { E_USER_TYPES } from ".././../../helpers/constants";
import { User } from "../../../models/user.model";

@Component({
    selector: "app-address-cell",
    templateUrl: "./address-cell.component.html",
})
export class AddressCellComponent {
    @Input()
    address: User;

    get isWebshop() {
        return this.address.type === E_USER_TYPES.VIAWEBSHOP;
    }

    get countryFlag() {
        if (this.address.country) {
            return `fi fi-${this.address.country.toLowerCase()}`;
        }
        return "fad fa-question-square";
    }
}
