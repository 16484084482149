import moment from "moment";
import { omitBy, isNull } from "lodash-es";
import { User } from "./user.model";
import { ServicePoint } from "./servicePoint.model";
import { PackageJSON } from "./json/packageJSON";
import { SavePackageJSON } from "./json/savePackageJSON";
import { ValidationError } from "../services/errors";

const toAddressJSON = (user: User) => {
    if (user) {
        return user.toAddressJSON();
    }
    return undefined;
};

const toParcelPointJSON = (point: ServicePoint) => {
    if (point) {
        return point.toJSON();
    }
    return undefined;
};

const toUserId = (user: User) => {
    if (user) {
        return user.id;
    }
    return undefined;
};

export class Package {
    constructor(
        public type: number,
        public concept: boolean,
        public same_day: boolean,
        public id_check: boolean,
        public insured: boolean,
        public no_neighbours: boolean,
        public proof_of_delivery: boolean,
        public premium: boolean,
        public evening_delivery: boolean,
        public sender: User,
        public owner: User,
        public recipient: User,
        public weight: number,
        public value: number,
        public dateCreated: moment.Moment,
        public length?: number,
        public width?: number,
        public height?: number,
        public id?: number,
        public tracktrace?: string,
        public instructions?: string,
        public mark?: string,
        public last_status?: number,
        public external_id?: number,
        public external_reference?: string,
        public validation_errors?: { errors: ValidationError[] },
        public service_point?: ServicePoint,
        public trackAndTraceUrl?: string,
        public carrier_code?: string,
    ) {}

    static fromJSON(json: PackageJSON) {
        let externalId = json.external_id;
        let last_status: number;
        let external_reference: string;
        let trackAndTraceUrl: string;
        if (!externalId && json.last_status) {
            externalId = json.last_status.external.number;
            last_status = json.last_status.status;
            if (json.last_status.external) {
                external_reference = json.last_status.external.reference;
                trackAndTraceUrl = json.last_status.external.trackAndTraceUrl;
            }
        }
        const [length, width, height] = Package.parseMeasurements(json.size);

        return new Package(
            json.type,
            json.concept,
            json.options.same_day,
            json.options.id_check,
            json.options.insured,
            json.options.no_neighbours,
            json.options.proof_of_delivery,
            json.options.premium,
            json.options.evening_delivery,
            User.fromJSON(json.sender),
            User.fromJSON(json.owner),
            User.fromJSON(json.recipient),
            json.weight,
            json.value,
            moment(json.date_created),
            length,
            width,
            height,
            json.id,
            json.tracktrace,
            json.instructions,
            json.mark,
            last_status,
            externalId,
            external_reference,
            json.validation_errors,
            ServicePoint.fromJSON(json.service_point),
            trackAndTraceUrl,
            json.carrier_code,
        );
    }

    toSaveJSON(): SavePackageJSON {
        let recipient_id;
        let recipient;
        let sender_id;
        let sender;
        if (this.recipient.id) {
            recipient_id = this.recipient.id;
        } else {
            recipient = omitBy(toAddressJSON(this.recipient), isNull);
            delete recipient.account;
        }
        if (this.sender.id) {
            sender_id = this.sender.id;
        } else {
            sender = omitBy(toAddressJSON(this.sender), isNull);
            delete sender.account;
        }

        if (this.weight == 0 || this.weight == null) {
            this.weight = undefined;
        }
        if (this.value == 0 || this.value == null) {
            this.value = undefined;
        }
        return {
            id: this.id,
            external_id: this.external_id,
            type: this.type,
            same_day: this.same_day,
            premium: this.premium,
            evening_delivery: this.evening_delivery,
            id_check: this.id_check,
            owner: toUserId(this.owner),
            insured: this.insured,
            no_neighbours: this.no_neighbours,
            proof_of_delivery: this.proof_of_delivery,
            size: this.size,
            instructions: this.instructions,
            mark: this.mark,
            value: this.value,
            weight: this.weight,
            recipient,
            recipient_id,
            sender,
            sender_id,
            service_point: toParcelPointJSON(this.service_point),
        };
    }

    static createEmpty() {
        return new Package(
            undefined,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            User.createEmpty(),
            undefined,
            User.createEmpty(),
            0,
            0,
            undefined,
        );
    }

    get containsErrors() {
        if (this.validation_errors && this.validation_errors.errors) {
            return Object.keys(this.validation_errors.errors).length > 0;
        }
        return false;
    }

    static parseMeasurements(jsonSize) {
        if (jsonSize) {
            const sizeArray = jsonSize.split("x");
            return sizeArray.map((v: string) => parseInt(v, 10));
        }
        return [undefined, undefined, undefined];
    }

    get size() {
        if (this.length || this.width || this.height) {
            return [
                this.length ? this.length : 0,
                this.width ? this.width : 0,
                this.height ? this.height : 0,
            ].join("x");
        }
        return undefined;
    }
}
