import { Pipe, PipeTransform } from "@angular/core";
const EMPTY_STRING_REPLACEMENT = "-";
@Pipe({
    name: "replaceEmptyString",
})
export class ReplaceEmptyString implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(string: string): string {
        if (string == "" || string == null) {
            return EMPTY_STRING_REPLACEMENT;
        }
        return string;
    }
}
