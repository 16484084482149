import { mergeMap } from "rxjs/operators";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { ViaWebshop } from "../../models/viawebshop.model";
import { BackendService } from "../../services/backend.service";
import { AuthService } from "../../services/auth.service";
import { E_USER_TYPES } from "../../helpers/constants";
import { DataService } from "../../services/data.service";

@Component({
    selector: "app-webshops-detail",
    templateUrl: "./webshops-detail.component.html",
    styleUrls: ["./webshops-detail.component.scss"],
})
export class WebshopsDetailComponent implements OnInit, OnDestroy {
    user: ViaWebshop;

    private sub: Subscription;

    constructor(
        private auth: AuthService,
        private backend: BackendService,
        private router: Router,
        private route: ActivatedRoute,
        private data: DataService,
    ) {}

    ngOnInit() {
        this.sub = this.route.paramMap
            .pipe(
                mergeMap((params) => {
                    const viawebshopId = params.get("id");
                    return this.backend.getViaWebshopById(+viawebshopId);
                }),
            )
            .subscribe(
                (result) => {
                    this.user = result;
                },
                () => {
                    this.router.navigateByUrl("/webshops");
                },
            );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    get isAllowedToDeactivate() {
        return this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN;
    }

    activateWebshopModal() {
        this.data.changeMessage({
            recipient: "webshop-activation",
            content: this.user,
        });
        this.router.navigateByUrl(`/webshops/${this.user.id}(modal:webshop-activation)`);
    }
}
