<div class="address-info">
    <div class="row">
        <span>Land<i class="fas fa-asterisk"></i></span>
        <select
            class="select-country"
            [(ngModel)]="address.country"
            name="country"
            id="country"
            ng="field.selectable"
            (change)="updateManualStreetInput()"
        >
            <option *ngFor="let country of availableCountries" [value]="country.code">
                {{ country.name }}
            </option>
        </select>
    </div>
    <div class="row error-row" *ngIf="hasError('country')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('country')">{{
                item | errorToText : "country"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span>Naam</span>
        <input
            type="text"
            class="text-input input-large"
            placeholder="Voornaam"
            id="firstname"
            name="firstname"
            maxlength="50"
            [(ngModel)]="address.firstname"
        />
        <input
            type="text"
            class="text-input input-medium"
            placeholder="Tussenvoegsel"
            id="middlename"
            name="middlename"
            maxlength="30"
            [(ngModel)]="address.middlename"
        />
    </div>

    <div class="row error-row" *ngIf="hasError('firstname')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('firstname')">{{
                item | errorToText : "firstname"
            }}</span>
        </div>
    </div>
    <div class="row error-row" *ngIf="hasError('middlename')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('middlename')">{{
                item | errorToText : "middlename"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span>Achternaam<i class="fas fa-asterisk"></i></span>
        <input
            type="text"
            class="text-input"
            [class.errorField]="hasError('lastname')"
            (change)="checkValidations()"
            placeholder="Achternaam"
            id="lastname"
            name="family-name"
            maxlength="50"
            [(ngModel)]="address.lastname"
            required
        />
    </div>

    <div class="row error-row" *ngIf="hasError('lastname')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('lastname')">{{
                item | errorToText : "lastname"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span>Bedrijfsnaam</span>
        <input
            type="text"
            class="text-input"
            placeholder="ViaTim"
            id="company"
            maxlength="55"
            [(ngModel)]="address.companyName"
            name="organization"
        />
    </div>

    <div class="row error-row" *ngIf="hasError('company_name')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('company_name')">{{
                item | errorToText : "company_name"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span>Postcode + Huisnr<i class="fas fa-asterisk"></i></span>
        <input
            type="text"
            class="text-input input-medium"
            [class.errorField]="hasError('postcode')"
            placeholder="Postcode"
            maxlength="12"
            id="postcode"
            name="postal-code"
            [(ngModel)]="address.postcode"
            (change)="updateAddress(); checkValidations()"
            required
        />
        <input
            type="text"
            class="text-input input-small"
            [class.errorField]="hasError('housenr')"
            placeholder="Huis nr."
            id="housenr"
            name="housenr"
            maxlength="20"
            [(ngModel)]="address.houseNumber"
            (change)="updateAddress(); checkValidations()"
            required
        />
        <input
            type="text"
            class="text-input input-small"
            [class.errorField]="hasError('housenr_extra')"
            placeholder="A"
            id="housenrletters"
            maxlength="5"
            name="housenr_extra"
            [(ngModel)]="address.houseNumberExtra"
            (change)="updateAddress()"
        />
    </div>

    <div class="row error-row" *ngIf="hasError('postcode')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('postcode')">{{
                item | errorToText : "postcode"
            }}</span>
        </div>
    </div>

    <div class="row error-row" *ngIf="hasError('housenr')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('housenr')">{{
                item | errorToText : "housenr"
            }}</span>
        </div>
    </div>
    <div class="row error-row" *ngIf="hasError('housenr_extra')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('housenr_extra')">{{
                item | errorToText : "housenr_extra"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span></span>
        <li>
            <label class="manual-adress" for="autoFillAdress">
                <input
                    type="checkbox"
                    [disabled]="!hasAutomaticPostcodeCheck"
                    [(ngModel)]="manualStreetInput"
                    name="manualStreetInput"
                />
                Handmatig adres invoeren
            </label>
        </li>
    </div>

    <div class="row">
        <span>Straat<i class="fas fa-asterisk"></i></span>
        <input
            type="text"
            [readonly]="!manualStreetInput"
            [class.errorField]="hasError('streetname')"
            placeholder="Straatnaam"
            class="text-input form-control"
            id="street"
            maxlength="64"
            (change)="checkValidations()"
            [(ngModel)]="address.streetname"
            name="address-line1"
        />
    </div>

    <div class="row error-row" *ngIf="hasError('streetname')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('streetname')">{{
                item | errorToText : "streetname"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span>Tweede adresregel</span>
        <input
            type="text"
            placeholder="Extra adresregel"
            class="form-control text-input"
            maxlength="50"
            id="address_extra"
            name="address_extra"
            [(ngModel)]="address.addressExtra"
        />
    </div>

    <div class="row error-row" *ngIf="hasError('address_extra')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('address_extra')">{{
                item | errorToText : "address_extra"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span>Plaatsnaam<i class="fas fa-asterisk"></i></span>
        <input
            type="text"
            [readonly]="!manualStreetInput"
            (change)="checkValidations()"
            [class.errorField]="hasError('city')"
            placeholder="Plaatsnaam"
            class="text-input form-control"
            maxlength="30"
            [(ngModel)]="address.city"
            id="city"
            name="city"
        />
    </div>

    <div class="row error-row" *ngIf="hasError('city')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('city')">{{
                item | errorToText : "city"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span
            >Email<span><i class="fas fa-asterisk"></i></span
        ></span>
        <input
            placeholder="E-mail"
            class="form-control text-input"
            type="text"
            (change)="checkValidations()"
            [class.errorField]="hasError('email')"
            id="email"
            name="email"
            [(ngModel)]="address.email"
        />
    </div>

    <div class="row error-row" *ngIf="hasError('email')">
        <span></span>
        <div class="error-section-input">
            <span class="errorMessageInput" *ngFor="let item of hasError('email')">{{
                item | errorToText : "email"
            }}</span>
        </div>
    </div>

    <div class="row">
        <span>Telefoonnummer</span>
        <input
            placeholder="Telefoonnummer"
            class="text-input form-control"
            type="text"
            id="tel"
            name="tel"
            maxlength="15"
            [(ngModel)]="address.phone"
        />
    </div>

    <div class="row">
        <span></span>
        <li>
            <button class="contact-button" (click)="showContactSelection($event)">
                <i class="fad fa-address-book fa-lg contact-icon"></i>Adresboek
            </button>
            <label for="saveContacts">
                <input
                    type="checkbox"
                    id="saveContacts"
                    [(ngModel)]="saveToContacts"
                    name="saveToContacts"
                />
                Opslaan in adresboek
            </label>
        </li>
    </div>
</div>

<div class="contacts" *ngIf="showContactsModal">
    <div class="address-book">
        <div class="list">
            <h1 class="list__title">Contacten</h1>
            <button class="list__close-btn" (click)="showContactsModal = false">
                <i class="far fa-times fa-lg"></i>
            </button>
            <div class="list__searchbar">
                <i class="list__searchbar--icon far fa-search fa-sm"></i>
                <input
                    class="list__searchbar--input"
                    type="search"
                    [(ngModel)]="searchTerm"
                    (change)="onChangeSearchTerm()"
                    placeholder="Zoeken"
                />
            </div>
            <main class="contact-page">
                <ul class="contact-page__key">
                    <label
                        class="contact-page__key--contacts"
                        *ngFor="let contact of displayedContacts"
                    >
                        <li
                            class="contact-page__key--contacts__name"
                            (click)="selectContact(contact)"
                        >
                            <b>{{ contact.lastname }}</b
                            >, {{ contact.firstname }} {{ contact.middlename }}
                        </li>
                    </label>
                </ul>
            </main>
        </div>
    </div>
</div>
