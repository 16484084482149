<div class="content">
    <form (ngSubmit)="login()">
        <label for="username">Gebruikersnaam</label>
        <div class="row">
            <input
                type="text"
                autocomplete="username"
                name="username"
                [(ngModel)]="form.username"
                id="username"
            />
        </div>

        <label for="password">Wachtwoord</label>
        <div class="row">
            <input
                type="password"
                autocomplete="current-password"
                name="password"
                [(ngModel)]="form.password"
                id="password"
            />
        </div>

        <div *ngIf="form.username === 'via-admin'">
            <label for="userId">Als gebruiker</label>
            <div class="row">
                <input type="text" name="userId" [(ngModel)]="form.userId" id="password" />
            </div>
        </div>

        <div class="submit-row">
            <p class="error" *ngIf="error">{{ error }}</p>
            <button class="btn inverse" type="submit">LOGIN</button>
            <a [routerLink]="['/login/forgot']">Wachtwoord vergeten?</a>
        </div>
    </form>
</div>
