import { StatusCodes } from "http-status-codes";
import { uniq } from "lodash-es";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { parse } from "papaparse";
import { Observable } from "rxjs";
import { ViaWebshop } from "../../models/viawebshop.model";
import { Message } from "../../models/message";
import { addMessage } from "../../store/actions/message.actions";
import { BackendService } from "../../services/backend.service";
import { Progress } from "../../store/reducers/progress.reducer";
import {
    finishProgress,
    startProgress,
    updateProgress,
} from "../../store/actions/progress.actions";
import { FULL_PERCENTAGES } from "../../helpers/constants";
import { InvoiceService } from "../../services/invoices.service";
import { PickupItem } from "../../models/pickuptem.model";
import { determineDateMask } from "../../helpers/dateHelper";

const IMPORT_FILE_HEADERS = ["Datum", "Webshop", "Omschrijving (komt bij Kenmerk)"];

@Component({
    selector: "app-import-worksheet",
    templateUrl: "./import-pickups.component.html",
    styleUrls: ["./import-pickups.component.scss"],
})
export class ImportPickupsComponent implements OnInit {
    webshops$: Observable<ViaWebshop[]> = this.store.select((state) => state.webshops);

    progress: number = 0;

    file: File;

    input: string;

    data: PickupItem[] = [];

    webshops: ViaWebshop[];

    state: "NONE" | "LOADING" | "PROCESSING" | "SAVING" = "NONE";

    fileData: any[] = [];

    errors: { [field: string]: string }[] = [];

    constructor(
        private backendService: BackendService,
        private invoiceService: InvoiceService,
        private store: Store<{ webshops: ViaWebshop[]; progress: Progress }>,
    ) {}

    ngOnInit(): void {
        this.backendService.getAllWebshops().subscribe((webshops) => {
            this.webshops = webshops;
        });
    }

    hasError(rowIndex: number, columnName: string) {
        return this.errors && this.errors[rowIndex] && !!this.errors[rowIndex][columnName];
    }

    back() {
        if (this.data && this.data.length > 0) {
            this.data = [];
        } else {
            this.fileData = [];
            this.file = null;
        }
    }

    processFileData = () => {
        this.state = "PROCESSING";

        const webshops = this.webshops.map((w) => ({
            id: w.id,
            companyName: w.companyName,
        }));

        const allDates = this.fileData.map((row) => row.Datum);
        const dateMask = determineDateMask(allDates);

        this.store.dispatch(
            startProgress({
                total: this.fileData.length,
            }),
        );
        let count = 0;

        const worker = new Worker(new URL("./import-worker.worker", import.meta.url));
        worker.onmessage = (data: MessageEvent) => {
            if (typeof data.data !== "string") {
                this.data = data.data.map(PickupItem.fromJSON);
                this.store.dispatch(finishProgress());
                this.state = "NONE";
            } else {
                if (data.data === "DONE") {
                    this.store.dispatch(
                        updateProgress({
                            count: count++,
                        }),
                    );
                    this.data;
                    this.localValidation();
                } else if (data.data === "ERROR") {
                    this.store.dispatch(
                        addMessage({
                            message: Message.createErrorMessage(
                                "Inleesfout",
                                "Er is iets fout gegaan tijdens het inlezen. Controlleer of je geen fouten in je sheet hebt staan.",
                            ),
                        }),
                    );

                    this.store.dispatch(finishProgress());
                    this.state = "NONE";
                }
            }
        };
        worker.onerror = (e) => {
            console.error("FROM WORKER", e);
        };
        const data = {
            rows: this.fileData,
            dateMask,
            webshops,
        };
        worker.postMessage(data);
    };

    upload(event) {
        [this.file] = event.target.files;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            this.state = "LOADING";
            this.input = <string>fileReader.result;
            const result = parse(this.input, {
                skipEmptyLines: true,
                header: true,
                dynamicTyping: true,
            });

            this.fileData = result.data;
            const allHeaders = this.fileData.reduce(
                (acc, obj) => uniq([...acc, ...Object.keys(obj)]),
                [],
            );
            const missingHeaders = IMPORT_FILE_HEADERS.filter(
                (header) => !allHeaders.includes(header),
            );
            if (missingHeaders.length > 0) {
                this.store.dispatch(
                    addMessage({
                        message: Message.createErrorMessage(
                            "Inlezen",
                            `Het gekozen bestand bevalt niet alle verplichte headers. Check of deze in het bestand aanwezig zijn ${missingHeaders.join(
                                ", ",
                            )}`,
                        ),
                    }),
                );
                this.fileData = null;
                this.file = null;
            }
            this.localValidation();
            this.state = "NONE";
        };
        fileReader.readAsText(this.file);
    }

    localValidation() {
        const mandatoryColumns = ["date", "webshopName", "category"];
        if (this.data) {
            this.errors = this.data.map((row) =>
                mandatoryColumns.reduce((acc, column) => {
                    if (!row[column] || row[column] === "") {
                        acc[column] = "required";
                    }
                    if (column === "date" && row.date && !row.date.isValid()) {
                        acc[column] = "invalid";
                    }
                    return acc;
                }, {}),
            );
        }
    }

    updateProgress(counter: number, total: number) {
        this.progress = Math.round((counter / total) * FULL_PERCENTAGES);
    }

    import() {
        this.state = "SAVING";
        this.localValidation();
        if (this.errors.some((error) => Object.keys(error).length > 0)) {
            this.store.dispatch(
                addMessage({
                    message: Message.createErrorMessage(
                        "Validatiefouten",
                        "Er zitten nog validatiefouten in de import. Los deze op voor je de sheet importeert",
                    ),
                }),
            );
            return;
        }
        this.invoiceService.uploadPickupItems(this.data).subscribe(
            () => {
                this.state = "NONE";
                this.store.dispatch(
                    addMessage({
                        message: Message.createSuccessMessage(
                            "Importeren",
                            "Het opslaan van de prijzen is gelukt",
                        ),
                    }),
                );
                this.fileData = [];
                this.file = null;
                this.data = [];
            },
            (e) => {
                this.state = "NONE";

                if (e.status === StatusCodes.INTERNAL_SERVER_ERROR) {
                    this.store.dispatch(
                        addMessage({
                            message: Message.createErrorMessage(
                                "Server fout tijdens import",
                                "Neem contact op met systeem beheerder!",
                            ),
                        }),
                    );
                } else {
                    const { errors } = e.error;
                    const toInternalFields = {
                        webshop_id: "webshopId",
                    };
                    this.errors = errors.reduce((acc, error) => {
                        const [index, field] = error.fields[0].split(".");
                        const indexNr = parseInt(index, 10);
                        if (!acc[indexNr]) {
                            acc[indexNr] = {};
                        }
                        acc[indexNr][toInternalFields[field] || field] = error.reason;
                        return acc;
                    }, []);
                    this.store.dispatch(
                        addMessage({
                            message: Message.createErrorMessage(
                                "Importeren",
                                "Er hebben zich wat fouten voorgedaan bij het importeren",
                            ),
                        }),
                    );
                }
            },
        );
    }
}
