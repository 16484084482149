import { CARRIER_CODES, CarrierCode } from "../helpers/constants/transporters";
import {
    PackagePriceListItemJSON,
    PackagePriceListItemSaveJSON,
} from "./json/packagePriceListItemJSON";

export class PackagePriceListItem {
    public constructor(
        public externalPackageType: number,
        public webshopId: number,
        public webshop: string,
        public countryCode: string,
        public countryName: string,
        public carrierCode: CarrierCode,
        public price: number,
        public type: string,
        public inbound: boolean,
        public errors: { [field: string]: string } = {},
    ) {}

    toSaveJSON(): PackagePriceListItemSaveJSON {
        return {
            external_package_type: this.externalPackageType,
            webshop_id: this.webshopId,
            country: this.countryCode,
            carrier_code: this.carrierCode,
            price: this.price,
            inbound: this.inbound,
        };
    }

    toJSON(): PackagePriceListItemJSON {
        return {
            external_package_type: this.externalPackageType,
            type: this.type,
            webshop_id: this.webshopId,
            webshop_name: this.webshop,
            country_code: this.countryCode,
            country_name: this.countryName,
            carrier_code: this.carrierCode,
            price: this.price,
            inbound: this.inbound,
        };
    }

    static fromJSON(json: PackagePriceListItemJSON): PackagePriceListItem {
        return new PackagePriceListItem(
            json.external_package_type,
            json.webshop_id,
            json.webshop_name,
            json.country_code,
            json.country_name,
            CARRIER_CODES.find((x) => x === json.carrier_code),
            json.price,
            json.type,
            json.inbound,
        );
    }
}
