import moment from "moment";
import { stringToMoment } from "../helpers/dateHelper";
import { UploadStatusJSON } from "./json/uploadStatusJSON";

export enum UPLOAD_STATUS {
    WORKING = "Bezig",
    STALLED = "Vastgelopen",
    DONE = "Klaar",
}
export class UploadStatus {
    constructor(
        public id: number,
        public dateCreated: moment.Moment,
        public totalAmount: number,
        public done: number,
        public errorReferences: string[],
        public erroredAmount: number,
        public status: UPLOAD_STATUS,
    ) {}

    static fromJSON(json: UploadStatusJSON) {
        if (!json) {
            return null;
        }
        let status = UPLOAD_STATUS.STALLED;
        switch (json.status) {
            case "STALLED":
                status = UPLOAD_STATUS.STALLED;
                break;
            case "DONE":
                status = UPLOAD_STATUS.DONE;
                break;
            case "WORKING":
                status = UPLOAD_STATUS.WORKING;
                break;
            default:
                status = UPLOAD_STATUS.WORKING;
                break;
        }
        return new UploadStatus(
            json.id,
            stringToMoment(json.date_created),
            json.amount_to_upload,
            json.success_amount,
            json.errored_references,
            json.errored_amount,
            status,
        );
    }

    get isCompleted() {
        return this.status == UPLOAD_STATUS.DONE || this.status == UPLOAD_STATUS.STALLED;
    }
}
