<app-cp-page>
    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
        <div class="col">
            <a [routerLink]="['/invoices', 'prices-import']">
                <div class="card">
                    <div class="card-header">
                        <i class="fad fa-hand-holding-dollar fa-5x"></i>
                    </div>
                    <div class="card-body">
                        <h4>Prijzen importeren</h4>
                        <p>Importeer de nieuwe webshop prijzen vanuit sales</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="['/invoices', 'worksheet-import']">
                <div class="card">
                    <div class="card-header">
                        <i class="fad fa-person-digging fa-5x"></i>
                    </div>
                    <div class="card-body">
                        <h4>Werkbestand importeren</h4>
                        <p>Importeer het logistiek werkbestand van de pakketmeester</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="['/invoices', 'transporter-import']">
                <div class="card">
                    <div class="card-header">
                        <i class="fad fa-truck fa-5x"></i>
                    </div>
                    <div class="card-body">
                        <h4>Vervoerder facturen importeren</h4>
                        <p>
                            Importeren de facturen van de vervoerders. Deze komen vanaf
                            administratie
                        </p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="['/invoices', 'pickup-import']">
                <div class="card">
                    <div class="card-header">
                        <i class="fad fa-person-dolly fa-5x"></i>
                    </div>
                    <div class="card-body">
                        <h4>Pickup bestand importeren</h4>
                        <p>Importeer de lijst met pickups van de pakketmeester</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
        <div class="col">
            <a [routerLink]="['/', { outlets: { modal: 'invoicing-export' } }]">
                <div class="card">
                    <div class="card-header">
                        <i class="fad fa-file-csv fa-5x fa-swap-opacity"></i>
                    </div>
                    <div class="card-body">
                        <h4>Facturatie Export</h4>
                        <p>Download de facturatie export!</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="['/invoices', 'compare']">
                <div class="card">
                    <div class="card-header">
                        <i class="fad fa-scale-unbalanced fa-5x"></i>
                    </div>
                    <div class="card-body">
                        <h4>Vergelijken inkoop verkoop</h4>
                        <p>Vergelijk inkoop en verkoop!</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</app-cp-page>
