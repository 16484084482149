import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "weight" })
export class PackageWeightPipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(weight: number): string {
        if (!weight || weight === 0) {
            return null;
        }
        const weightNl = weight.toLocaleString();
        return `${weightNl} kg`;
    }
}
