import { AbstractControl } from "@angular/forms";

const QUARTER = 90;
const HALF = 180;
const THREE_QUARTERS = 270;

export const RotateValidator = (control: AbstractControl) => {
    const val = control.value;
    const { untouched, parent } = control;

    if (parent) {
        if (parent.value.active) {
            if (
                val === 0 ||
                val === QUARTER ||
                val === HALF ||
                val === THREE_QUARTERS ||
                val == ""
            ) {
                return null;
            }

            if (untouched) {
                return null;
            }

            return { invalidNumber: true };
        }
        return null;
    }

    return null;
};
