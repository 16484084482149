import { ErrorTextPipe } from "./error-text.pipe";
import { FilterPipe } from "./filter.pipe";
import { FromDatePipe } from "./from-date.pipe";
import { FullNamePipe } from "./full-name.pipe";
import { SplitDatePipe } from "./split-date.pipe";
import { SplitTimePipe } from "./split-time.pipe";
import { UserTypePipe } from "./user-type.pipe";
import { ExternalDisplayName } from "./external-display-name.pipe";
import { UnknownValuePipe } from "./unknown-value.pipe";
import { BracketsPipe } from "./brackets.pipe";
import { PriceAmountPipe } from "./price-amount.pipe";
import { ReplaceEmptyString } from "./replace-empty-string.pipe";

export const pipes = [
    ErrorTextPipe,
    FilterPipe,
    FromDatePipe,
    FullNamePipe,
    SplitDatePipe,
    SplitTimePipe,
    UserTypePipe,
    ExternalDisplayName,
    UnknownValuePipe,
    BracketsPipe,
    PriceAmountPipe,
    ReplaceEmptyString,
];
