import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Progress } from "../../store/reducers/progress.reducer";

@Component({
    selector: "app-progress-bar",
    templateUrl: "./progress-bar.component.html",
    styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent {
    progress$: Observable<Progress> = this.store.select((state) => state.progress);

    constructor(private store: Store<{ progress: Progress }>) {}
}
