import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../services/auth.service";
import { ExternalServerError, ConnectionError, ForbiddenError } from "../services/errors";

@Component({
    selector: "app-password-reset",
    templateUrl: "./password-reset.component.html",
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    constructor(
        private auth: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    private key: string;

    private sub: Subscription;

    error: string;

    handleResetError = (e: Error) => {
        if (e instanceof ExternalServerError) {
            this.error = "Fout met de server, probeer het later nog een keer";
        } else if (e instanceof ConnectionError) {
            this.error = "Er kon geen connectie gemaakt worden met de server";
        } else if (e instanceof ForbiddenError) {
            this.error = "De wachtwoord reset link is verlopen, vraag een nieuwe link aan";
        } else {
            this.error = `Onbekende fout ${e.message}`;
        }
    };

    handleReset = () => {
        this.router.navigate(["/login/reset/success"]);
    };

    reset(password: string) {
        this.auth
            .resetPassword(password, this.key)
            .subscribe(this.handleReset, this.handleResetError);
    }

    ngOnInit() {
        this.sub = this.activatedRoute.queryParams.subscribe((params) => {
            this.key = params["key"];
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
