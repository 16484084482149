export class Message {
    private constructor(
        public id: number,
        public title: string,
        public content: string,
        private type: "ERROR" | "SUCCESS" | "NOTIFICATION",
    ) {}

    static createErrorMessage(title: string, content: string) {
        return new Message(new Date().getTime(), title, content, "ERROR");
    }

    static createSuccessMessage(title: string, content: string) {
        return new Message(new Date().getTime(), title, content, "SUCCESS");
    }

    static createNotification(title: string, content: string) {
        return new Message(new Date().getTime(), title, content, "NOTIFICATION");
    }

    get isErrorMessage() {
        return this.type === "ERROR";
    }

    get isSuccessMessage() {
        return this.type === "SUCCESS";
    }

    get isNotification() {
        return this.type === "NOTIFICATION";
    }
}
