import { Injectable, ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/browser";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    /* eslint-disable class-methods-use-this */

    handleError(error: any) {
        const realError = error.originalError || error.error || error;
        if (realError instanceof Error) {
            Sentry.captureException(realError);
        } else {
            Sentry.captureMessage(`Strange Error ${JSON.stringify(realError)}`);
        }
    }
}
