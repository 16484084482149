import { Component } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
    selector: "app-viatim-patch-notes-modal",
    templateUrl: "./viatim-patch-notes-modal.component.html",
    styleUrls: ["./viatim-patch-notes-modal.component.scss"],
})
export class ViatimPatchNotesModalComponent {
    version = environment.version;

    title = `Laatste veranderingen (${this.version})`;
}
