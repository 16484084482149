<app-dialog-page>
    <div class="success">
        <h1>Mail verstuurd!</h1>
        <p>
            Zojuist hebben wij een mail verstuurd naar het emailadres dat gekoppeld staat aan het
            door jou ingevoerde account. In deze mail staat uitgelegd hoe je jouw wachtwoord kunt
            resetten.
        </p>
        <p class="spam-info">
            Mocht je deze mail na 30 minuten nog steeds niet ontvangen hebben, controleer in dat
            geval even de SPAM-box. Als de mail niet in je SPAM-box staat, kun je het opnieuw
            proberen of contact met ons opnemen.
        </p>

        <div class="return">
            <a [routerLink]="['/login']">Terug naar login</a>
            <a [routerLink]="['/login/forgot']">Terug naar wachtwoord vergeten</a>
        </div>
    </div>
</app-dialog-page>
