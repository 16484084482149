import { Observable } from "rxjs";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { User } from "../../../models/user.model";

@Component({
    selector: "app-viatim-news-modal",
    templateUrl: "./viatim-news-modal.component.html",
    styleUrls: ["./viatim-news-modal.component.scss"],
})
export class ViatimNewsModalComponent {
    currentUser$: Observable<User> = this.store.select((state) => state.currentUser);

    constructor(private store: Store<{ currentUser: User }>) {}
}
