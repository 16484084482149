import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";

type LoginForm = {
    username?: string;
    password?: string;
    userId?: string;
};

@Component({
    selector: "app-login-entry",
    templateUrl: "./login-entry.component.html",
    styleUrls: ["./login-entry.component.scss"],
})
export class LoginEntryComponent implements OnChanges {
    @Input() error: string;

    @Input() urlUserId: string;

    @Output() loginEvent = new EventEmitter<LoginForm>();

    form: LoginForm = {};

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["urlUserId"] && changes["urlUserId"].currentValue) {
            this.form.userId = changes["urlUserId"].currentValue;
        }
    }

    login() {
        this.loginEvent.emit(this.form);
    }
}
