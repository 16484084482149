<app-cp-page>
    <div class="button-row">
        <a
            *ngIf="canAddPackages"
            class="btn btn-labeled btn-outline-primary"
            [routerLink]="['/packages', 'create']"
        >
            <span class="btn-label"><i class="fa fa-plus fa-lg"></i></span>
            Nieuw Pakket
        </a>
        <button
            *ngIf="canAddPackages"
            class="btn btn-labeled btn-outline-primary"
            (click)="csvUpload()"
        >
            <span class="btn-label"><i class="fa fa-plus fa-lg"></i></span>
            CSV Import
        </button>
        <button
            *ngIf="canImportPackages"
            class="btn btn-labeled btn-outline-primary"
            (click)="importPackages()"
        >
            <span class="btn-label"><i class="fa fa-plus fa-lg"></i></span>
            Webshop Import
        </button>
        <button
            *ngIf="canExportPackages"
            class="btn btn-labeled btn-outline-primary"
            (click)="exportPackages()"
        >
            <span class="btn-label"><i class="fa fa-file-csv fa-lg"></i></span>
            CSV Export
        </button>
        <div *ngIf="selectedPackages$ | async as selectedPackages">
            <button
                *ngIf="!isSupportUser"
                (click)="bulklabelModal()"
                class="btn btn-labeled btn-outline-primary"
                [ngClass]="{ disabled: selectedPackages.length === 0 }"
            >
                <span class="btn-label">{{ selectedPackages.length }}</span>
                Print labels
            </button>
            <button
                *ngIf="!isSupportUser"
                (click)="bulkDeleteModal()"
                class="btn btn-labeled btn-outline-primary"
                [ngClass]="{ disabled: selectedPackages.length === 0 }"
            >
                <span class="btn-label">{{ selectedPackages.length }}</span>
                Verwijder Pakketten
            </button>
        </div>
    </div>
    <app-package-table class="row"></app-package-table>
</app-cp-page>
