import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Component, OnInit, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { E_USER_TYPES } from "../../helpers/constants";
import { User } from "../../models/user.model";

@Component({
    selector: "app-cp-page",
    templateUrl: "./control-panel-page.component.html",
    styleUrls: ["./control-panel-page.component.scss"],
})
export class ControlPanelPageComponent implements OnInit {
    currentUser$: Observable<User> = this.store.select((state) => state.currentUser);

    collapsed: boolean = false;

    constructor(
        private auth: AuthService,
        private store: Store<{ currentUser: User }>,
        private router: Router,
    ) {}

    ngOnInit() {
        this.collapsed = localStorage.getItem("collapsedMenu") == "true";
    }

    get showDashboard() {
        return this.auth.getAccountRole() != E_USER_TYPES.SUPPORT;
    }

    get showWebshops() {
        return (
            this.auth.getAccountRole() == E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() == E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() == E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    get showInvoicing() {
        return (
            this.auth.getAccountRole() == E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() == E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() == E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    get showContacts() {
        return (
            this.auth.getAccountRole() == E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() == E_USER_TYPES.VIAWEBSHOP ||
            this.auth.getAccountRole() == E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() == E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    logout(): void {
        this.auth.logout();
        this.router.navigateByUrl("/login");
    }

    toggleSideBar() {
        this.collapsed = !this.collapsed;
        localStorage.setItem("collapsedMenu", this.collapsed.toString());
    }

    @HostBinding("class") get hostToggleClass() {
        if (this.collapsed) {
            return "grid-toggle";
        }
        return "";
    }
}
