import { Router } from "@angular/router";
import { Component } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { ExternalServerError, ConnectionError } from "../services/errors";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent {
    error: string;

    constructor(private auth: AuthService, private router: Router) {}

    handleResetError = (e: Error) => {
        if (e instanceof ExternalServerError) {
            this.error = "Fout met de server, probeer het later nog een keer";
        } else if (e instanceof ConnectionError) {
            this.error = "Er kon geen connectie gemaakt worden met de server";
        } else {
            this.error = `Onbekende fout ${e.message}`;
        }
    };

    handleReset = () => {
        this.router.navigate(["/login/forgot/success"]);
    };

    reset(username: string): void {
        this.auth.forgotPassword(username).subscribe(this.handleReset, this.handleResetError);
    }
}
