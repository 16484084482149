<app-cp-page>
    <div class="import">
        <h1 class="import__title">Importeren Prijslijst</h1>
        <div *ngIf="!file" class="import-upload">
            <div class="button-row">
                <button class="btn btn-labeled btn-outline-primary" (click)="uploader.click()">
                    <i class="fad fa-file-import"></i>
                    Importeren Prijslijst
                </button>
            </div>
        </div>
        <div *ngIf="file">
            <p class="import__subtitle">Bestand : {{ file.name }}</p>
            <p>{{ fileData.length }} regels</p>
        </div>
        <div *ngIf="file && fileData.length > 0 && !importDate" class="form-group">
            <h3 class="import__subtitle">Kies Start Datum</h3>
            <div class="col-4 import__input">
                <div class="input-group">
                    <input
                        class="form-control"
                        placeholder="Kies de datum"
                        name="importDate"
                        (dateSelect)="onDateSelect($event)"
                        ngbDatepicker
                        #d="ngbDatepicker"
                    />
                    <button
                        class="btn btn-outline-secondary form-control"
                        (click)="d.toggle()"
                        type="button"
                    >
                        <i class="fas fa-calendar"></i>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="file && fileData.length > 0 && importDate">
            <p>Datum : {{ importDate | splitDate }}</p>
        </div>
        <div *ngIf="data && (data.packagePrices?.length > 0 || data.pickupPrices?.length > 0)">
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon2">Filter</span>
                <select class="form-select" [(ngModel)]="selectedWebshopId">
                    <option [ngValue]="null">***FOUTEN***</option>
                    <option *ngFor="let webshop of webshopsForFilter" [ngValue]="webshop.id">
                        {{ webshop.companyName || webshop.domain }}({{ webshop.id }})
                    </option>
                </select>
            </div>
        </div>
        <div
            *ngIf="
                data &&
                (data.packagePrices?.length > 0 || data.pickupPrices?.length > 0) &&
                !selectedWebshopId
            "
        >
            <p>Geen fouten bij het importeren</p>
        </div>
        <div
            *ngIf="
                data && (packagePricesToDisplay?.length > 0 || pickupPricesToDisplay?.length > 0)
            "
        >
            <div class="table-wrapper">
                <table *ngIf="data" class="table">
                    <thead class="sticky-top">
                        <tr class="table__header">
                            <th>Webshop</th>
                            <th>Type</th>
                            <th>Vervoerder</th>
                            <th>Land</th>
                            <th>Retour</th>
                            <th>Prijs</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let row of packagePricesToDisplay; let i = index">
                        <td
                            [ngClass]="{
                                'button-danger': row.errors['webshopId'] || !row.webshopId
                            }"
                        >
                            {{ row.webshop }} {{ row.webshopId | brackets }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger':
                                    row.errors['externalPackageType'] || !row.externalPackageType
                            }"
                        >
                            {{ row.type }} {{ row.externalPackageType | brackets }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': row.errors['carrierCode'] || !row.carrierCode
                            }"
                        >
                            {{ row.carrierCode }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': row.errors['country'] || !row.countryName
                            }"
                        >
                            {{ row.countryName }} {{ row.countryCode | brackets }}
                        </td>
                        <td>
                            <app-yes-or-no-icon [value]="row.inbound"></app-yes-or-no-icon>
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': row.errors['price'] || !row.price
                            }"
                        >
                            {{ row.price | priceAmount }}
                        </td>
                        <td *ngIf="row.errors['duplicate']">Dubbel</td>
                    </tr>
                    <tr *ngFor="let row of pickupPricesToDisplay">
                        <td
                            [ngClass]="{
                                'button-danger': row.errors['webshopId'] || !row.webshopId
                            }"
                        >
                            {{ row.webshop }} {{ row.webshopId | brackets }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': row.errors['name'] || !row.name
                            }"
                        >
                            {{ row.name }}
                        </td>
                        <td>VIATIM</td>
                        <td>-</td>
                        <td>-</td>
                        <td [ngClass]="{ 'bg-danger': row.errors['price'] || !row.price }">
                            {{ row.price | priceAmount }}
                        </td>
                        <td *ngIf="row.errors['duplicate']">Dubbel</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="actions gap-3">
            <input
                type="file"
                #uploader
                (change)="upload($event)"
                placeholder="Upload file"
                accept=".csv"
            />
            <button *ngIf="file" class="btn btn-primary btn-lg" (click)="back()">Terug</button>
            <button
                *ngIf="data && (data.packagePrices?.length > 0 || data.pickupPrices?.length > 0)"
                class="btn btn-primary btn-lg"
                [disabled]="state === 'SAVING'"
                (click)="import()"
            >
                Opslaan
            </button>
            <button
                *ngIf="
                    file && fileData.length > 0 && importDate && data.packagePrices?.length === 0
                "
                class="btn btn-primary btn-lg"
                (click)="processFileData()"
            >
                Verwerken
            </button>
        </div>
    </div>
</app-cp-page>
