import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { ActivatedRoute, UrlTree } from "@angular/router";
import { AuthenticationState } from "../models/authenticationState";
import { login } from "../store/actions/session.actions";
import { Message } from "../models/message";
import { addMessage } from "../store/actions/message.actions";

type LoginForm = {
    username?: string;
    password?: string;
    userId?: string;
};

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
    state$: Observable<AuthenticationState> = this.store.select(
        (state) => state.session.loginState,
    );

    state: AuthenticationState;

    urlUserId: string;

    constructor(
        private store: Store<{
            session: {
                loginState: AuthenticationState;
            };
        }>,
        private route: ActivatedRoute,
    ) {
        this.state$.subscribe((state) => {
            this.state = state;
        });
    }

    ngOnInit() {
        this.urlUserId = this.route.snapshot.queryParams["userId"];
        if (this.urlUserId) {
            const message = Message.createNotification(
                "Bewerken als Admin",

                `Log in als gebruiker met Id "${this.urlUserId}" om het pakket te kunnen bewerken`,
            );
            this.store.dispatch(addMessage({ message }));
        }
    }

    get error() {
        switch (this.state) {
            case AuthenticationState.SystemDown:
                return "Het systeem heeft problemen, probeer het later nog een keer.";
            case AuthenticationState.InvalidPassword:
                return "Naam en/of wachtwoord verkeerd!";
            case AuthenticationState.Unauthorized:
                return "U heeft niet de goede rol om in te loggen.";
            default:
                return "";
        }
    }

    login(form: LoginForm): void {
        let userId: number;
        let redirectUrlTree: UrlTree;
        if (form.userId && Number.isSafeInteger(Number.parseInt(form.userId, 10))) {
            userId = Number.parseInt(form.userId, 10);
        }
        if (this.route.snapshot.queryParams["url"]) {
            redirectUrlTree = this.route.snapshot.queryParams["url"];
        }
        this.store.dispatch(
            login({
                username: form.username,
                password: form.password,
                userId,
                redirectUrlTree,
            }),
        );
    }
}
