import { Subscription } from "rxjs";
import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { parse } from "papaparse";
import { addMessage } from "../../store/actions/message.actions";
import {
    BPOST_DEFAULT_COLUMNS,
    DEFAULT_COLUMNS,
    DHL_DEFAULT_COLUMNS,
    MYPARCEL_DEFAULT_COLUMNS,
    PARCELPRO_DEFAULT_COLUMNS,
} from "../../helpers/constants";
import { Message } from "../../models/message";
import { InvoiceService } from "../../services/invoices.service";
import { TransporterInvoiceImportItem } from "../../models/transporterInvoiceImportItem.model";
import { TransporterConfigColumns } from "../../models/transporter-config.model";
import { E_EXTERNAL_PARTIES } from "../../helpers/constants/transporters";
import { Transporter } from "../../models/transporter.model";
import { TablePage } from "../../models/tablePage.model";
import { startBusy, finishBusy } from "../../store/actions/busy.actions";

@Component({
    selector: "app-import-transporter-invoice",
    templateUrl: "./import-transporter-invoice.component.html",
    styleUrls: ["./import-transporter-invoice.component.scss"],
})
export class ImportTransporterInvoiceComponent implements OnDestroy {
    file: File;

    @ViewChild("uploader")
    fileInputElement: ElementRef;

    input: string;

    data: any;

    fields: string[];

    step: number = 0;

    amountField: string;

    amountValue: number;

    externalReferenceField: string;

    externalInvoiceIdField: string;

    externalInvoiceIdValue: string;

    transporter: Transporter | null;

    priceField: string;

    productNameField: string;

    productNameValue: string;

    packages: any;

    exampleData: TransporterInvoiceImportItem[];

    examplePageNumber = 0;

    importInProgress: boolean = false;

    transporters: Transporter[] = [
        new Transporter(E_EXTERNAL_PARTIES.DHL, "DHL"),
        new Transporter(E_EXTERNAL_PARTIES.MYPARCEL, "MYPARCEL"),
        new Transporter(E_EXTERNAL_PARTIES.PARCELPRO, "PARCELPRO"),
    ];

    fileName: string;

    subscriptions: Subscription[] = [];

    DHLTransporterConfig: TransporterConfigColumns = new TransporterConfigColumns(
        DHL_DEFAULT_COLUMNS,
        ";",
    );

    BPOSTTransporterConfig: TransporterConfigColumns = new TransporterConfigColumns(
        BPOST_DEFAULT_COLUMNS,
        ",",
    );

    MYPARCELTransporterConfig: TransporterConfigColumns = new TransporterConfigColumns(
        MYPARCEL_DEFAULT_COLUMNS,
        ";",
    );

    PARCELPROTransporterConfig: TransporterConfigColumns = new TransporterConfigColumns(
        PARCELPRO_DEFAULT_COLUMNS,
        ",",
    );

    defaultConfig: TransporterConfigColumns = new TransporterConfigColumns(DEFAULT_COLUMNS, ",");

    constructor(
        private service: InvoiceService,
        private store: Store<{ messages: Message[] }>,
        private router: Router,
    ) {}

    upload(event) {
        if (!this.selectedTransporterConfig) {
            this.store.dispatch(
                addMessage({
                    message: Message.createErrorMessage(
                        "Geen transporter Config gevonden!",
                        "Ga terug en kies een bestaande transporter",
                    ),
                }),
            );
            return;
        }
        this.setTransporterConfig(this.selectedTransporterConfig);
        [this.file] = event.target.files;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            this.input = <string>fileReader.result;
            const result = parse(this.input, {
                skipEmptyLines: false,
                delimiter: this.selectedTransporterConfig.delimiter,
                header: true,
                dynamicTyping: true,
            });
            this.data = result.data;
            this.fields = result.meta.fields;
            if (this.fields.length === 1) {
                this.store.dispatch(
                    addMessage({
                        message: Message.createErrorMessage(
                            "Fout bij het importeren",
                            `Waarschijnlijk is er iets mis gegaan met de limiter!.
                            Dit moet voor ${this.transporter.name} '${this.selectedTransporterConfig.delimiter}' zijn`,
                        ),
                    }),
                );
            } else if (this.selectedTransporterConfig.defaultColumns["externalReferenceField"]) {
                this.store.dispatch(
                    addMessage({
                        message: Message.createSuccessMessage(
                            "Transporter Config gevonden!",
                            "De kolommen zijn al ingevuld, maar deze zijn nog aan te passen!",
                        ),
                    }),
                );
            } else {
                this.store.dispatch(
                    addMessage({
                        message: Message.createSuccessMessage(
                            "Geen Transporter Config gevonden!",
                            "De kolommen zijn nog niet ingevuld, maar deze zijn nog aan te passen!",
                        ),
                    }),
                );
            }
            this.step++;
        };
        fileReader.readAsText(this.file);
        this.fileName = this.file.name;
    }

    checkFields() {
        if (
            this.amountField &&
            this.externalReferenceField &&
            this.productNameField &&
            this.priceField &&
            this.externalInvoiceIdField
        ) {
            this.example();
        } else {
            this.step = 3;
        }
    }

    get selectedTransporterConfig(): TransporterConfigColumns {
        if (!this.transporter) {
            return null;
        }
        switch (this.transporter.id) {
            case E_EXTERNAL_PARTIES.DHL:
                return this.DHLTransporterConfig;
            case E_EXTERNAL_PARTIES.BPOST:
                return this.BPOSTTransporterConfig;
            case E_EXTERNAL_PARTIES.MYPARCEL:
                return this.MYPARCELTransporterConfig;
            case E_EXTERNAL_PARTIES.PARCELPRO:
                return this.PARCELPROTransporterConfig;
            default:
                return this.defaultConfig;
        }
    }

    get exampleTablePage() {
        return new TablePage(
            this.exampleData.slice(this.examplePageNumber * 20, (this.examplePageNumber + 1) * 20),
            this.examplePageNumber * 20,
            this.exampleData.length,
            20,
        );
    }

    setTransporterConfig(transporterConfig: TransporterConfigColumns) {
        this.amountField = transporterConfig.defaultColumns["amountField"];
        this.externalReferenceField = transporterConfig.defaultColumns["externalReferenceField"];
        this.externalInvoiceIdField = transporterConfig.defaultColumns["externalInvoiceIdField"];
        this.productNameField = transporterConfig.defaultColumns["productNameField"];
        this.priceField = transporterConfig.defaultColumns["priceField"];
    }

    back() {
        if (this.step === 1) {
            this.file = null;
            this.fileInputElement.nativeElement = null;
        }
        this.step--;
    }

    formatReference(reference: string) {
        if (this.transporter?.id === E_EXTERNAL_PARTIES.BPOST) {
            const REFERENCE_SUBSTR = "323200713359999";
            const formattedReference = REFERENCE_SUBSTR + reference.split(REFERENCE_SUBSTR)[1];
            return formattedReference;
        }
        return reference;
    }

    toPrice(amount: number | string) {
        if (!amount) {
            return 0;
        }
        let result: number;
        if (typeof amount === "string") {
            result = Number.parseFloat(amount.replace(",", "."));
        } else {
            result = amount;
        }
        return Math.round(result * 100);
    }

    processRow(row: any) {
        return [
            new TransporterInvoiceImportItem(
                this.amountField ? parseInt(row[this.amountField], 10) : this.amountValue,
                this.transporter.id,
                this.formatReference(row[this.externalReferenceField]),
                this.toPrice(row[this.priceField]),
                this.productNameField ? row[this.productNameField] : this.productNameValue,
                this.externalInvoiceIdField
                    ? row[this.externalInvoiceIdField]
                    : this.externalInvoiceIdValue,
            ),
        ];
    }

    processDHLRow(row: any) {
        let result = this.processRow(row);
        const [invoiceItem] = result;
        if (invoiceItem.totalAmount) {
            result = invoiceItem.split();
        }
        const references = result.map((i) => i.externalReference);
        let xcIndex = 1;
        while (row[`XC${xcIndex} Name`]) {
            references.forEach((reference) => {
                result.push(
                    new TransporterInvoiceImportItem(
                        1,
                        this.transporter.id,
                        reference,
                        Math.round(
                            this.toPrice(row[`XC${xcIndex} Total`]) / invoiceItem.totalAmount,
                        ),
                        row[`XC${xcIndex} Name`],
                        this.externalInvoiceIdField
                            ? row[this.externalInvoiceIdField]
                            : this.externalInvoiceIdValue,
                    ),
                );
            });
            xcIndex++;
        }
        return result;
    }

    example() {
        const result = this.data
            .filter((row: any) => row[this.externalReferenceField] !== undefined)
            .map((row: any) => {
                if (this.transporter.id === E_EXTERNAL_PARTIES.DHL) {
                    return this.processDHLRow(row);
                }
                return this.processRow(row);
            })
            .flat();
        this.exampleData = result;
        this.step = 4;
    }

    gotoExamplePage(page: number) {
        this.examplePageNumber = page;
    }

    import() {
        this.importInProgress = true;
        this.store.dispatch(startBusy());
        this.subscriptions.push(
            this.service.uploadTransporterInvoiceItems(this.exampleData).subscribe({
                complete: () => {
                    this.importInProgress = false;
                    this.router.navigate(["invoices"]);
                    this.store.dispatch(finishBusy());
                    this.store.dispatch(
                        addMessage({
                            message: Message.createSuccessMessage(
                                "Gelukt",
                                "Het importeren van de factuur is gelukt",
                            ),
                        }),
                    );
                },
                error: () => {
                    this.importInProgress = false;
                    this.store.dispatch(finishBusy());
                    this.store.dispatch(
                        addMessage({
                            message: Message.createErrorMessage(
                                "Fout",
                                "Er is iets misgegaan bij de import",
                            ),
                        }),
                    );
                },
            }),
        );
    }

    selectTransporter() {
        if (this.transporter) {
            this.step++;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
