import { Action, createReducer, on } from "@ngrx/store";
import * as SessionActions from "../actions/session.actions";
import { AuthenticationState } from "../../models/authenticationState";

export interface SessionState {
    token: string;
    loginState: AuthenticationState;
}

export const initialState: SessionState = {
    token: null,
    loginState: AuthenticationState.NotLoggedIn,
};

const sessionReducer = createReducer(
    initialState,
    on(SessionActions.login, () => ({
        token: undefined,
        loginState: AuthenticationState.Processing,
    })),
    on(SessionActions.loginSuccess, (_, props) => ({
        token: props.token,
        loginState: AuthenticationState.LoggedIn,
    })),
    on(SessionActions.logout, () => ({
        token: undefined,
        loginState: AuthenticationState.NotLoggedIn,
    })),
    on(SessionActions.loginFailure, (_, props) => ({
        token: undefined,
        loginState: props.reason,
    })),
);

export function reducer(state: SessionState, action: Action) {
    return sessionReducer(state, action);
}
