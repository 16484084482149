<app-cp-page>
    <div>
        <svg
            version="1.2"
            baseProfile="tiny"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1080 1080"
            xml:space="preserve"
        >
            <g id="Layer_3">
                <path
                    class="path1"
                    fill="none"
                    stroke="#000000"
                    stroke-width="50"
                    stroke-miterlimit="10"
                    d="M307.9,521c0,0,0,3.1,0-125S411.8,163.8,540,163.8 s232.1,103.9,232.1,232.1c0,71.3,0,125,0,125"
                />
                <path
                    class="path2"
                    fill="none"
                    stroke="#000000"
                    stroke-width="50"
                    stroke-miterlimit="10"
                    d="M772.1,520.1H253.9c-22,0-40,18-40,40V877 c0,22,18,40,40,40h572.3c22,0,40-18,40-40V560.1c0-22-18-40-40-40H772.1"
                />
                <line
                    class="line1"
                    fill="none"
                    stroke="#000000"
                    stroke-width="60"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    x1="540"
                    y1="661.8"
                    x2="540"
                    y2="770.7"
                />
            </g>
        </svg>
        <h1>Timmetje mag hier eigenlijk helemaal niet komen!</h1>
        <p>De actie die je probeert uit te voeren is ongeautoriseerd.</p>
        <p>Heb je het idee dat er iets fout is gegaan? Neem contact op met onze ViaCoders via</p>
        <p>
            <a href="mailto:it@viatim.nl?SUBJECT=404pagina">it&#64;viatim.nl</a> of bel naar
            <a href="tel:010-261-3616">010-261-3616</a>
        </p>
    </div>
</app-cp-page>
