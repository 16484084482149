import { max, min, uniq } from "lodash-es";
import moment from "moment";

const NUMBER_OF_MONTHS = 12;

export const MONTH_NAMES_DUTCH = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
];

export const currentThemeName = () => {
    const now = moment();
    const year = now.year();

    const xmasStart = moment(`${year}-12-06`);
    const xmasEnd = moment(`${year + 1}-01-01`);

    // Check if Christmas
    if (now.isAfter(xmasStart) && now.isBefore(xmasEnd)) {
        return "xmas";
    }

    // Check if Halloween

    // Else
    return "normal";
};

export const surroundingYearOptions = (before: number, after: number = 0) => {
    const currentYear = moment().get("year");
    const yearOptions = [];
    for (let index = -before; index <= after; index++) {
        yearOptions.push(currentYear + index);
    }
    return yearOptions;
};

export const stringToMoment = (value: string) => {
    if (value) {
        return moment(value);
    }
    return null;
};

export const determineDateMask = (dateStrings: string[]) => {
    const noneEmptyDateStrings = dateStrings.filter((x) => x?.trim().length > 0);
    const elements = uniq(noneEmptyDateStrings.map((v) => v.split("")).flat()).filter(
        (x: string) => x < "0" || x > "9",
    );
    if (elements.length === 1) {
        const pattern = ["D", "D", "D"];
        const [splitCharacter] = elements;
        const maxElements = noneEmptyDateStrings
            .map((s) => s.split(splitCharacter).map((x) => parseInt(x, 10)))
            .reduce(
                ([x, y, z], [a, b, c]) => [Math.max(a, x), Math.max(b, y), Math.max(c, z)],
                [0, 0, 0],
            );
        const maxIndex = maxElements.indexOf(max(maxElements));
        let minIndex: number;
        if (maxElements[1] <= NUMBER_OF_MONTHS) {
            if (maxIndex === 1) {
                throw Error("PATTERN NOT DETECTED");
            }
            minIndex = 1;
        } else {
            minIndex = maxElements.indexOf(min(maxElements));
        }
        pattern[maxIndex] = "YYYY";
        pattern[minIndex] = "M";
        return pattern.join(splitCharacter);
    }
    throw Error("PATTERN NOT DETECTED");
};
