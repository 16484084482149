import { Pipe, PipeTransform } from "@angular/core";
import { USER_TYPE_TO_TEXT, E_USER_TYPES } from "../constants";

@Pipe({ name: "userType" })
export class UserTypePipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(type: E_USER_TYPES) {
        try {
            return USER_TYPE_TO_TEXT[type];
        } catch (e) {
            return "Onbekend";
        }
    }
}
