<div class="toasts">
    <div
        *ngFor="let message of messages$ | async"
        class="toast"
        [ngClass]="{
            success: message.isSuccessMessage,
            error: message.isErrorMessage,
            notification: message.isNotification
        }"
    >
        <div
            class="toastIcon"
            [ngClass]="{
                successToast: message.isSuccessMessage,
                errorToast: message.isErrorMessage,
                notificationToast: message.isNotification
            }"
        >
            <i
                class="fad fa-2x"
                [ngClass]="{
                    'fa-check-circle': message.isSuccessMessage,
                    'fa-exclamation-circle': message.isErrorMessage,
                    'fa-bell': message.isNotification
                }"
            ></i>
        </div>
        <div class="content">
            <span class="title">{{ message.title }}</span>
            <span class="body">{{ message.content }}</span>
        </div>
        <div class="delete">
            <a (click)="deleteMessage(message.id)"><i class="fas fa-times"></i></a>
        </div>
    </div>
</div>
