import { Component, Output, Input, EventEmitter } from "@angular/core";

@Component({
    selector: "app-password-reset-form",
    templateUrl: "./password-reset-form.component.html",
    styleUrls: ["./password-reset-form.component.scss"],
})
export class PasswordResetFormComponent {
    password1: string;

    password2: string;

    @Input() error: string;

    @Output() resetEvent = new EventEmitter<string>();

    submit() {
        if (!this.password1 && !this.password2) {
            this.error = "Een of beide velden zijn niet ingevuld.";
        } else if (this.password1 !== this.password2) {
            this.error = "Bovenstaande wachtwoorden komen niet overeen.";
        } else {
            this.error = "";
            this.resetEvent.emit(this.password1);
        }
    }

    get isFilledIn() {
        return (
            (this.password1 != null && this.password1.length > 0) ||
            (this.password2 != null && this.password2.length > 0)
        );
    }
}
