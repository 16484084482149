import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-cp-modal",
    templateUrl: "./control-panel-modal.component.html",
    styleUrls: ["./control-panel-modal.component.scss"],
})
export class ControlPanelModalComponent {
    @Input()
    title: string;

    @Input()
    route: string;

    constructor(private router: Router) {}

    closeModal() {
        if (this.route) {
            this.router
                .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
                .then(() => this.router.navigate([`/${this.route}`]));
        } else {
            this.router.navigate([{ outlets: { modal: null } }]);
        }
    }
}
