import { Injectable } from "@angular/core";
import moment from "moment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { PackageFilter } from "../models/packageFilter";

const SUNDAY = 7;

@Injectable()
export class ExportService {
    constructor(private httpClient: HttpClient) {}

    downloadExport(filter: PackageFilter = new PackageFilter()): Observable<string> {
        const headers = new HttpHeaders({ Accept: "text/plain" });
        const params = filter.toSearchParams().delete("start").delete("limit");
        const options = { headers, responseType: "text" as "text", params };
        return this.httpClient.get(`${environment.apiBase}/exporter/packages`, options);
    }

    downloadExportForBillingOld(start: moment.Moment, end: moment.Moment): Observable<string> {
        const headers = new HttpHeaders({ Accept: "text/plain" });
        const params = {
            firstScanDateStart: start.format("YYYY-MM-DD"),
            firstScanDateEnd: end.format("YYYY-MM-DD"),
        };
        const options = { headers, responseType: "text" as "text", params };
        return this.httpClient.get(`${environment.apiBase}/exporter/packages/todeliver`, options);
    }

    downloadExportForBilling(start: moment.Moment, end: moment.Moment): Observable<string> {
        const params = {
            firstScanDateStart: start.format("YYYY-MM-DD"),
            firstScanDateEnd: end.format("YYYY-MM-DD"),
        };
        const headers = new HttpHeaders({ Accept: "text/plain" });
        const options = { headers, responseType: "text" as "text", params };
        return this.httpClient.get(`${environment.apiBase}/exporter/packages/forBilling`, options);
    }

    downloadExportForLogistic(start: moment.Moment, end: moment.Moment): Observable<string> {
        const params = {
            dateStart: start.format("YYYY-MM-DD"),
            dateEnd: end.format("YYYY-MM-DD"),
        };
        const headers = new HttpHeaders({ Accept: "text/plain" });
        const options = { headers, responseType: "text" as "text", params };
        return this.httpClient.get(`${environment.apiBase}/exporter/packages/forBilling`, options);
    }

    downloadExportForBillingForMonth(year: number, month: number): Observable<string> {
        const start = moment(new Date(year, month, 1));
        const end = moment(start).add(1, "M");
        return this.downloadExportForBilling(start, end);
    }

    downloadExportForBillingForWeek(year: number, week: number): Observable<string> {
        const end = moment().year(year).isoWeek(week).isoWeekday(SUNDAY);
        const start = moment(end).subtract(1, "week");
        return this.downloadExportForBilling(start, end);
    }

    downloadOldExportForBillingForMonth(year: number, month: number): Observable<string> {
        const start = moment(new Date(year, month, 1));
        const end = moment(start).add(1, "M");
        return this.downloadExportForBillingOld(start, end);
    }

    downloadOldExportForBillingForWeek(year: number, week: number): Observable<string> {
        const end = moment().year(year).isoWeek(week).isoWeekday(SUNDAY);
        const start = moment(end).subtract(1, "week");
        return this.downloadExportForBillingOld(start, end);
    }

    downloadExportForLogisticForMonth(year: number, month: number): Observable<string> {
        const start = moment(new Date(year, month, 1));
        const end = moment(start).add(1, "M");
        return this.downloadExportForLogistic(start, end);
    }

    downloadExportForLogisticForWeek(year: number, week: number): Observable<string> {
        const end = moment().year(year).isoWeek(week).isoWeekday(SUNDAY);
        const start = moment(end).subtract(1, "week");
        return this.downloadExportForLogistic(start, end);
    }
}
