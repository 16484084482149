<app-cp-modal title="Webshop Activeren/Deactiveren">
    <div *ngIf="user">
        <div class="warning">
            <p>Let op:</p>
            <p>Weet je zeker dat je deze webshop wilt deactiveren?</p>
        </div>
        <div class="submit">
            <button *ngIf="user && user.isActive" class="btn inverse" (click)="activationWebshop()">
                DEACTIVEER
            </button>
            <button
                *ngIf="!(user && user.isActive)"
                class="btn inverse"
                (click)="activationWebshop()"
            >
                ACTIVEER
            </button>
        </div>
    </div>
</app-cp-modal>
