<div class="content">
    <h1 class="heading">De gewenste bezorggegevens</h1>
    <form>
        <div class="firstRow">
            <input
                type="text"
                placeholder="Postcode"
                name="postcode"
                [(ngModel)]="searchPostcode"
                maxlength="8"
            />
            <input
                type="text"
                placeholder="Huisnummer"
                name="housenr"
                [(ngModel)]="searchHouseNr"
                maxlength="4"
            />
            <button type="submit" class="btn searchButton" (click)="updatePoints()">
                <i class="fad fa-2x fa-angle-right"></i>
            </button>
        </div>
    </form>

    <div *ngIf="searching" class="searching">
        <i class="fas fa-3x fa-spinner fa-pulse"></i>
        <p>Zoeken naar servicepunten...</p>
    </div>

    <div *ngIf="servicePoints.length === 0 && !searching" class="error">
        <p>Het lijkt erop dat er geen servicepunten zijn gevonden bij deze postcode.</p>
    </div>

    <div class="test">
        <div
            *ngFor="let servicepoint of servicePoints"
            (click)="selectServicePoint(servicepoint)"
            class="item"
        >
            <div class="left">
                <i class="fad fa-2x fa-map-marker-alt markerIcon"></i>
                <span>{{ servicepoint.distance }} meter</span>
            </div>
            <div class="right">
                <ul>
                    <li>{{ servicepoint.name }}</li>
                    <li>
                        {{ servicepoint.streetname }} {{ servicepoint.housenr
                        }}{{ servicepoint.houseNumberExtra }}
                    </li>
                    <li>{{ servicepoint.postcode }}, {{ servicepoint.city }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
