<!-- <img *ngIf="external == 'VIATIM' || external == 1"  [src]="externalUrl" alt="VIATIM-icon">
<img *ngIf="external == 'DHL' || external == 2"  src="assets/icons/DHL-icon.svg" alt="DHL-icon">
<img *ngIf="external == 'POSTNL' || external == 3"  src="assets/icons/POSTNL-icon.svg" alt="POSTNL-icon">
<img *ngIf="external == 'DPD' || external == 4"  src="assets/icons/DPD-icon.svg" alt="DPD-icon">
<img *ngIf="external == 'FEDEX' || external == 5"  src="assets/icons/FEDEX-icon.svg" alt="FEDEX-icon">
<img *ngIf="external == 'RJP' || external == 6"  src="assets/icons/RJP-icon.svg" alt="RJP-icon">

<img *ngIf="notfound" src="assets/icons/UNKNOWN-icon.svg" alt="UNKNOWN-icon"> -->

<div [style.background-image]="externalUrl"></div>
