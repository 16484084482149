import { Pipe, PipeTransform } from "@angular/core";
import { STATUS_TO_TEXT, E_TRACKING_STATUSES } from "../../helpers/constants";
import { User } from "../../models/user.model";

const STATUSES_WITH_NAME = [
    E_TRACKING_STATUSES.PACKAGE_SCANNED_BY_VIABODE,
    E_TRACKING_STATUSES.PACKAGE_SCANNED_BY_VIAPUNT,
    // E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_VIAGEBRUIKER,
    // E_TRACKING_STATUSES.PACKAGE_PICKED_UP_BY_VIAGEBRUIKER,
    // E_TRACKING_STATUSES.PACKAGE_VERIFIED_BY_VIAGEBRUIKER,
    E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_VIABODE,
    E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_VIAPUNT,
];

@Pipe({ name: "statusText" })
export class StatusTextPipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(status: E_TRACKING_STATUSES, user: User) {
        try {
            const text = STATUS_TO_TEXT[status];
            if (user && STATUSES_WITH_NAME.includes(status)) {
                return `${text} ${user.firstname}`;
            }
            return text;
        } catch (e) {
            return "Onbekend";
        }
    }
}
