/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
})
export class ModalComponent {
    @Input()
    title: string;

    @Output()
    onClose = new EventEmitter<boolean>();

    close() {
        this.onClose.emit();
    }
}
