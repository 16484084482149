<app-cp-page>
    <div class="import">
        <h1 class="import__title">Importeren werkbestand</h1>
        <div *ngIf="!file" class="import-upload">
            <div class="button-row">
                <button class="btn btn-labeled btn-outline-primary" (click)="uploader.click()">
                    <i class="fad fa-file-import"></i>
                    Importeren Werkbestand
                </button>
            </div>
        </div>
        <div *ngIf="file">
            <p class="import__subtitle">Bestand : {{ file.name }}</p>
            <p>{{ fileData.length }} regels</p>
        </div>
        <div *ngIf="data && data.length > 0">
            <div class="table-wrapper">
                <table *ngIf="data" class="table">
                    <thead class="sticky-top">
                        <tr class="table__header">
                            <th>Webshop</th>
                            <th>Datum</th>
                            <th>Categorie</th>
                            <th>Prijs</th>
                            <th>Viatim code</th>
                            <th>Externe code</th>
                            <th>Omschrijving</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let row of data; let i = index">
                        <td
                            [ngClass]="{
                                'button-danger': hasError(i, 'webshopId') || !row.webshopId
                            }"
                        >
                            {{ row.webshopName }} {{ row.webshopId | brackets }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': hasError(i, 'date') || !row.date
                            }"
                        >
                            {{ row.date | splitDate }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': hasError(i, 'category') || !row.category
                            }"
                        >
                            {{ row.category }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': hasError(i, 'price') || !row.price
                            }"
                        >
                            {{ row.price | priceAmount }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': hasError(i, 'reference') || !row.reference
                            }"
                        >
                            {{ row.reference }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger':
                                    hasError(i, 'externalReference') || !row.externalReference
                            }"
                        >
                            {{ row.externalReference }}
                        </td>
                        <td
                            [ngClass]="{
                                'button-danger': hasError(i, 'description') || !row.description
                            }"
                        >
                            {{ row.description }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="actions gap-3">
            <input
                type="file"
                #uploader
                (change)="upload($event)"
                placeholder="Upload file"
                accept=".csv"
            />
            <button *ngIf="file" class="btn btn-primary btn-lg" (click)="back()">Terug</button>
            <button
                *ngIf="data && data.length > 0"
                class="btn btn-primary btn-lg"
                (click)="import()"
            >
                Opslaan
            </button>
            <button
                *ngIf="file && fileData?.length > 0 && data.length === 0"
                class="btn btn-primary btn-lg"
                (click)="processFileData()"
            >
                Verwerken
            </button>
        </div>
    </div>
</app-cp-page>
