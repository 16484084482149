import moment, { Moment } from "moment";
import { WorksheetItemSaveJSON, WorksheetItemJSON } from "./json/worksheetItemJSON";

export class WorksheetItem {
    public constructor(
        public date: Moment,
        public reference: string,
        public externalReference: string,
        public webshopId: number,
        public webshopName: string,
        public category: string,
        public description: string,
        public price: number,
    ) {}

    validate() {
        const errors: any = {};
        if (!this.date) {
            errors.date = "required";
        } else if (!this.date.isValid()) {
            errors.date = "invalid";
        }
        if (!this.webshopId) {
            errors.webshopId = "required";
        }
        if (!this.category) {
            errors.category = "required";
        }
        if (!this.price) {
            errors.price = "required";
        }
        return errors;
    }

    toSaveJSON(): WorksheetItemSaveJSON {
        return {
            date: this.date.format("YYYY-MM-DD"),
            reference: this.reference,
            external_reference: this.externalReference,
            webshop_id: this.webshopId,
            category: this.category,
            description: this.description,
            price: this.price,
        };
    }

    toJSON(): WorksheetItemJSON {
        return {
            date: this.date.format("YYYY-MM-DD"),
            reference: this.reference,
            external_reference: this.externalReference,
            webshop_id: this.webshopId,
            webshop_name: this.webshopName,
            category: this.category,
            description: this.description,
            price: this.price,
        };
    }

    static fromJSON(json: WorksheetItemJSON): WorksheetItem {
        return new WorksheetItem(
            moment(json.date, "YYYY-MM-DD"),
            json.reference,
            json.external_reference,
            json.webshop_id,
            json.webshop_name,
            json.category,
            json.description,
            json.price,
        );
    }
}
