import moment from "moment";
import { Component, OnInit } from "@angular/core";
import * as FileSaver from "file-saver-es";
import { Store } from "@ngrx/store";
import { Message } from "../../../models/message";
import { addMessage } from "../../../store/actions/message.actions";
import { ExportService } from "../../../services/export.service";
import { MONTH_NAMES_DUTCH, surroundingYearOptions } from "../../../helpers/dateHelper";

@Component({
    selector: "app-old-invoicing-export-modal",
    templateUrl: "./old-invoicing-export-modal.component.html",
    styleUrls: ["./old-invoicing-export-modal.component.scss"],
})
export class OldInvoicingExportModalComponent implements OnInit {
    exportYear: number;

    exportMonth: number;

    exportWeek: number;

    yearOptions: number[];

    showLoading = false;

    failures = [];

    period: "week" | "month" = "week";

    monthOptions = MONTH_NAMES_DUTCH;

    weekOptions = Array.from({ length: 53 }, (_, key) => key + 1);

    constructor(private exportService: ExportService, private store: Store) {}

    ngOnInit() {
        this.updateDefaultPeriod();
        this.yearOptions = surroundingYearOptions(3);
    }

    setPeriod(period: "month" | "week") {
        this.period = period;
        this.updateDefaultPeriod();
    }

    updateDefaultPeriod() {
        const lastPeriod = moment().subtract(1, this.period);
        this.exportYear = lastPeriod.year();
        this.exportMonth = lastPeriod.month();
        this.exportWeek = lastPeriod.week();
    }

    download() {
        if (this.period === "month") {
            this.exportService
                .downloadOldExportForBillingForMonth(this.exportYear, this.exportMonth)
                .subscribe({
                    next: (data) => {
                        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                        FileSaver.saveAs(
                            blob,
                            `logistics-old-month-export-${this.exportYear}-${
                                this.exportMonth + 1
                            }.csv`,
                        );
                        this.showLoading = false;
                    },
                    error: () => {
                        const message = Message.createErrorMessage(
                            "Fout bij het downloaden",
                            "Er is iets fout gegaan bij het downloaden van de csv",
                        );
                        this.store.dispatch(addMessage({ message }));
                        this.showLoading = false;
                    },
                });
        } else {
            this.exportService
                .downloadOldExportForBillingForWeek(this.exportYear, this.exportWeek)
                .subscribe({
                    next: (data) => {
                        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                        FileSaver.saveAs(
                            blob,
                            `logistics-old-week-export-${this.exportYear}-${this.exportWeek}.csv`,
                        );
                        this.showLoading = false;
                    },
                    error: () => {
                        const message = Message.createErrorMessage(
                            "Fout bij het downloaden",
                            "Er is iets fout gegaan bij het downloaden van de csv",
                        );
                        this.store.dispatch(addMessage({ message }));
                        this.showLoading = false;
                    },
                });
        }
    }
}
