import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { filter, flatMap } from "rxjs/operators";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { AuthenticationState } from "../models/authenticationState";
import { User } from "../models/user.model";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { E_USER_TYPES } from "../helpers/constants";

const NOT_ALLOWED = [
    {
        role: E_USER_TYPES.SUPPORT,
        page: DashboardComponent,
        alternative: "packages",
    },
];

@Injectable()
export class AuthGuard {
    constructor(
        private auth: AuthService,
        private store: Store<{
            session: {
                loginState: AuthenticationState;
            };
            currentUser: User;
        }>,
        private router: Router,
    ) {}

    waitForCurrentUser(): Observable<{
        loginState: AuthenticationState;
        user: User;
    }> {
        return this.store
            .select((state) => ({
                loginState: state.session.loginState,
                user: state.currentUser,
            }))
            .pipe(
                filter(({ loginState, user }) => {
                    if (loginState !== AuthenticationState.LoggedIn) {
                        this.auth.localAuthenticate();
                        return false;
                    }
                    return user != null;
                }),
            );
    }

    // The CanActivate will be called on every page in the code that requires a login
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.waitForCurrentUser().pipe(
            flatMap(({ loginState, user }) => {
                if (loginState === AuthenticationState.LoggedIn) {
                    const notAllowedRecord = NOT_ALLOWED.find(
                        ({ role, page }) => role === user.type && page === route.component,
                    );
                    if (notAllowedRecord) {
                        setTimeout(() => this.router.navigate([notAllowedRecord.alternative]), 0);
                        return of(false);
                    }
                    return of(true);
                }
                return of(true);
            }),
        );
    }
}
