import { createAction, props } from "@ngrx/store";

export const selectPackage = createAction(
    "[Selected Packages Component] Select Package",
    props<{ tracktrace: string }>(),
);

export const toggleSelectPackage = createAction(
    "[Selected Packages Component] Toggle Package",
    props<{ tracktrace: string }>(),
);

export const unSelectPackage = createAction(
    "[Selected Packages Component] Unselect Package",
    props<{ tracktrace: string }>(),
);

export const clearSelectedPackages = createAction(
    "[Selected Packages Component] Clear Selected Packages",
);
