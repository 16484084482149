import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { mergeMap } from "rxjs/operators";
import { AuthService } from "../../services/auth.service";
import { BackendService } from "../../services/backend.service";
import { E_USER_TYPES } from "../../helpers/constants";
import { CountryService } from "../../services/country.service";
import { DataService } from "../../services/data.service";
import { Package } from "../../models/package.model";

@Component({
    selector: "app-package-detail",
    templateUrl: "./package-detail.component.html",
    styleUrls: ["./package-detail.component.scss"],
})
export class PackageDetailComponent implements OnInit {
    package: Package;

    private tracktrace: string;

    tabs: Array<string> = ["Status"];

    activeTab: string = "Status";

    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private backend: BackendService,
        private countryService: CountryService,
        private dataService: DataService,
    ) {}

    id: string;

    ngOnInit() {
        this.route.paramMap
            .pipe(
                mergeMap((params) => {
                    const tracktrace = params.get("id");
                    this.tracktrace = tracktrace;
                    return this.backend.getPackageByTrackTrace(this.tracktrace);
                }),
            )
            .subscribe(
                (parcel) => {
                    this.package = parcel;
                    if (this.package.owner.type === E_USER_TYPES.TRANSPORTER) {
                        this.tabs.push("Bundel");
                    }
                    if (this.package.concept) {
                        this.router.navigateByUrl(`/packages/edit/${this.tracktrace}`);
                    }
                },
                () => {
                    this.backend.showMessage(false, "Het pakket kon niet worden opgehaald.");
                    this.router.navigateByUrl("/packages");
                },
            );
    }

    get value() {
        if (!this.package.value || this.package.value == 0) {
            return null;
        }
        return this.package.value;
    }

    showTab(tab: string) {
        this.activeTab = tab;
    }

    deletePackage(tracktrace: string) {
        this.dataService.changeMessage({
            recipient: "delete",
            content: tracktrace,
        });
        this.router.navigateByUrl(`/packages/${tracktrace}(modal:delete-package)`);
    }

    forcePackage() {
        this.router.navigateByUrl(`/packages/${this.tracktrace}(modal:force-package)`);
    }

    labelModal(tracktrace: string) {
        this.dataService.changeMessage({ recipient: "print", content: tracktrace });
        this.router.navigateByUrl(`/packages/${tracktrace}(modal:print-label)`);
    }

    retourLabelModal(t: string) {
        this.router.navigateByUrl(`/packages/${t}(modal:retour-label)`);
    }

    get canCreateRetour() {
        return (
            this.package.recipient.type === E_USER_TYPES.NON_INTEGRATED_USER &&
            this.countryService
                .countriesThatExternalCanSendFrom(this.package.external_id)
                .map((c) => c.code)
                .includes(this.package.recipient.country) &&
            this.countryService
                .countriesThatExternalCanSendTo(this.package.external_id)
                .map((c) => c.code)
                .includes(this.package.sender.country)
        );
    }

    reportProblem() {
        const params = [
            "usp=pp_url",
            `entry.1077317714=${this.package.sender.companyName}`,
            `entry.1665944094=${this.package.carrier_code}`,
            `entry.1910379643=${this.externalReference}`,
            `entry.1399855433=${this.package.recipient.getFullName()}`,
            `entry.2022286162=${this.package.recipient.streetname} ${this.package.recipient.houseNumber}${this.package.recipient.houseNumberExtra}`,
            `entry.1348639643=${this.package.recipient.postcode}`,
        ];

        const isNotUndefined = (currentValue) => currentValue != undefined;
        const dimensionsArray = [this.package.length, this.package.width, this.package.height];

        // Check if all dimensions are defined
        if (dimensionsArray.every(isNotUndefined)) {
            params.push(`entry.1899252832=${dimensionsArray.join("x")}`);
        }

        if (this.package.weight > 0) {
            params.push(`entry.1256839357=${this.package.weight}`);
        }

        window.open(
            `https://docs.google.com/forms/d/e/1FAIpQLSd1_ihH-LdQD6djSZKzVjhoMY2oMc61Jrh-2bh_T4n3ECIVpA/viewform?${params.join(
                "&",
            )}`,
            "_blank",
        );
    }

    get externalReference() {
        if (this.package && this.package.external_reference) {
            const reference = this.package.external_reference;
            if (reference.includes("-")) {
                return reference.split("-")[1];
            }
            return reference;
        }
        return null;
    }

    get isAllowedToDelete() {
        return this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN;
    }

    get isAllowedToForce() {
        return (
            this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN ||
            this.auth.getAccountRole() === E_USER_TYPES.VIADC
        );
    }

    get isAllowedToPrint() {
        return (
            this.auth.getAccountRole() === E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() === E_USER_TYPES.VIAWEBSHOP ||
            this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    copyLabel(tracktrace: string) {
        this.router.navigateByUrl(`/packages/${tracktrace}(modal:copy-label)`);
    }

    translateCountry(countryCode: string) {
        const country = this.countryService.findCountryByISO(countryCode);
        if (country) {
            return country.name;
        }
        return `${countryCode} - ONBEKEND LAND`;
    }

    editPackage() {
        this.router.navigateByUrl(`/packages/edit/${this.tracktrace}`);
    }

    copyMessage(val: any) {
        this.backend.showMessage(true, `${val} is gekopieerd naar je klembord.`);
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
    }

    hasServicePoint() {
        if (this.package.service_point) {
            return true;
        }
        return false;
    }
}
