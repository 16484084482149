<app-cp-page>
    <div class="row">
        <button [routerLink]="['/webshops/create']" class="btn add" tabindex="1">
            <span> <i class="fa fa-plus fa-lg"></i> </span>ViaTim Webshop
        </button>
    </div>

    <form #userForm="ngForm" (ngSubmit)="updateUsers()">
        <div class="row">
            <i class="far fa-search fa-sm" data-fa-transform="right-35"></i>
            <input
                #search
                type="search"
                name="search"
                [(ngModel)]="searchTerm"
                placeholder="Bedrijfsnaam, Website, Adres..."
                (search)="updateUsers()"
            />
        </div>
        <div class="row">
            <table class="filter">
                <tr>
                    <th>Webshop actief?</th>
                </tr>
                <tr>
                    <td>
                        <select
                            name="active"
                            [(ngModel)]="filters.active"
                            (change)="filterChange()"
                        >
                            <option [ngValue]="true">Actief</option>
                            <option [ngValue]="false">Non-actief</option>
                            <option [ngValue]="null">Beide</option>
                        </select>
                    </td>
                </tr>
            </table>
        </div>
    </form>
    <h4 class="warning" *ngIf="users.length === 0">
        Er zijn geen Webshops gevonden met de huidige filters.
    </h4>

    <div *ngIf="users.length > 0" class="webshopCount">
        Totaal {{ total_items }} webshop<span *ngIf="total_items > 0">s</span>
    </div>

    <table *ngIf="users.length > 0">
        <tr>
            <th>Webshop</th>
            <th>Naam</th>
            <th>Telefoonnummer</th>
            <th>Emailadres</th>
            <th>Adres</th>
            <th></th>
        </tr>
        <tr *ngFor="let user of users">
            <td id="toDetails">{{ user.companyName }}</td>
            <td>{{ user | fullName }}</td>
            <td>
                <a href="tel:{{ user.phone }}">{{ user.phone }}</a
                ><br />
            </td>
            <td>
                <a href="mailto:{{ user.email }}">{{ user.email }}</a>
            </td>
            <td>
                {{ user.streetname }} {{ user.houseNumber }} {{ user.houseNumberExtra }}<br />{{
                    user.postcode
                }}, {{ user.city }}
            </td>
            <td>
                <button [routerLink]="['/webshops', user.id]" class="btn small inRowBtn">
                    <i class="fad fa-eye fa-lg"></i>
                </button>
                <button [routerLink]="['/webshops', user.id, 'edit']" class="btn small inRowBtn">
                    <i class="fas fa-pencil-alt"></i>
                </button>
            </td>
        </tr>
    </table>
    <nav *ngIf="users.length > 0" class="pagination">
        <span [ngClass]="{ clickable: true, invisible: curr_page === 0 }" (click)="previousPage()"
            ><i class="fas fa-arrow-left"></i
        ></span>
        Pagina {{ curr_page + 1 }} van {{ getTotalPages() }}
        <span
            [ngClass]="{
                clickable: true,
                invisible: curr_page + 1 === getTotalPages()
            }"
            (click)="nextPage()"
            ><i class="fas fa-arrow-right"></i
        ></span>
    </nav>
</app-cp-page>
