<app-cp-modal [title]="title">
    <section>
        <div class="text">
            <h1>Sub-accounts</h1>
            <p>
                <span>‌—</span> Het is nu mogelijk om een sub-account voor een webshop aan te
                vragen!<br />
                <span>—</span> Met een sub-account is het mogelijk om pakketten te versturen onder
                een andere naam en adres.
            </p>

            <h1>Inbound pakketten</h1>
            <p>
                <span>‌—</span> Het is nu mogelijk om pakketten aan te maken die van de klant
                righting het account of een sub-account gaat.<br />
                <span>‌—</span> Je kan nu direct een retour label laten printen vanaf een pakket
                detail pagina.
            </p>

            <h1>Bugfixes</h1>
            <p>
                <span>‌—</span> Vele verbeteringen aan snelheid.<br />
                <span>‌—</span> Beter error afhandeling bij webshop koppelingen.
            </p>
        </div>
    </section>
</app-cp-modal>
