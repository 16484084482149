import { createAction, props } from "@ngrx/store";
import { Message } from "../../models/message";

export const addMessage = createAction(
    "[Message Component] Add Success Message",
    props<{ message: Message }>(),
);

export const deleteMessage = createAction(
    "[Message Component] Delete Message",
    props<{ id: number }>(),
);
