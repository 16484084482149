import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getValidationErrorMessages } from "../../helpers/errorHelper";
import { BackendService } from "../../services/backend.service";
import { ViaWebshop } from "../../models/viawebshop.model";
import { Message } from "../../models/message";
import { ConflictError, ValidationError } from "../../services/errors";
import { addMessage } from "../../store/actions/message.actions";

@Component({
    selector: "app-webshops-create",
    templateUrl: "./webshops-create.component.html",
})
export class WebshopsCreateComponent {
    viaWebshop: ViaWebshop = ViaWebshop.createEmpty();

    errors: ValidationError[] = [];

    constructor(
        private store: Store<{ messages: Message[] }>,
        private router: Router,
        private backendService: BackendService,
    ) {}

    create(viaWebshop: ViaWebshop) {
        this.backendService.createViawebshop(viaWebshop).subscribe(
            () => {
                this.errors = [];
                const message = Message.createSuccessMessage(
                    "Webshop is aangemaakt",
                    `De webshop ${viaWebshop.companyName} is succesvol aangemaakt`,
                );
                this.store.dispatch(addMessage({ message }));
                this.router.navigateByUrl("/webshops");
            },
            (error) => {
                const reasonedErrors = error.errors;
                if (reasonedErrors?.length > 0) {
                    this.errors = reasonedErrors;
                    const errorMessages = getValidationErrorMessages(this.errors);
                    errorMessages.forEach((errorMessage) => {
                        this.store.dispatch(addMessage({ message: errorMessage }));
                    });
                } else {
                    this.errors = [];
                    if (error instanceof ConflictError) {
                        this.store.dispatch(
                            addMessage({
                                message: Message.createErrorMessage(
                                    "De Webshop bestaat al",
                                    `Er bestaat al een Webshop punt met hetzelfde emailadres: ${viaWebshop.email}`,
                                ),
                            }),
                        );
                    }
                }
            },
        );
    }

    cancel() {
        this.router.navigateByUrl("/webshops");
    }
}
