<app-cp-modal title="Tip een webshop!">
    <section *ngIf="currentUser$ | async as currentUser">
        <div class="image"></div>
        <div class="text">
            <h1>
                Hi <span>{{ currentUser.getFullName() }}</span
                >,
            </h1>
            <p>
                Wij hopen dat jij net zo blij bent met ons als dat wij met jou zijn! We hopen
                natuurlijk ook dat jij je goede ervaringen met anderen deelt.
            </p>
            <p>
                Misschien zit er in je netwerk van vrienden, familie en kennissen wel iemand met een
                webshop. Die heeft natuurlijk ondertussen al veel goede verhalen over ons gehoord 😉
            </p>
            <p>
                Wij zouden graag met die webshop in contact komen om ze nog meer te vertellen over
                verzenden ViaTim! Stuur een mailtje met jouw tip naar
                <a href="mailto:sales@viatim.nl?SUBJECT=Tip een webshop">sales&#64;viatim.nl</a>!
            </p>
            <p>
                Wanneer wij een afspraak hebben met jouw getipte webshop krijg jij iets lekkers! Wij
                sturen jou ViaTim een heerlijk verrassingspakket van
                <a href="http://www.rotterdamsekaas.nl/" target="_blank">www.rotterdamsekaas.nl</a>
            </p>
            <p>Kijk eens hoe blij Koningin Maxima er mee is op de foto. Dat wil jij toch ook!</p>
            <p>Alvast bedankt voor jouw tip!</p>
        </div>
    </section>
</app-cp-modal>
