import { createAction, props } from "@ngrx/store";

export const startProgress = createAction(
    "[Progress Component] Start Progress Message",
    props<{ total: number }>(),
);

export const updateProgress = createAction(
    "[Progress Component] Update Progress Message",
    props<{ count: number }>(),
);

export const finishProgress = createAction("[Progress Component] Finish Progress Message");
