import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";

@Injectable({
    providedIn: "root",
})
export class PackageOwnerGuard {
    constructor(
        private backendService: BackendService,
        private auth: AuthService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const tracktrace = route.params["package"];
        const { url } = state;

        return this.backendService.getPackageByTrackTrace(tracktrace).pipe(
            map((pagePackage) => {
                if (pagePackage && pagePackage.owner.id === this.auth.getOwnUserId()) {
                    return true;
                }
                this.router.navigate(["login"], {
                    queryParams: {
                        userId: pagePackage.owner.id,
                        url,
                    },
                });
                return false;
            }),
        );
    }
}
