import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
    name: "fromDate",
})
export class FromDatePipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(date: string | moment.Moment): string {
        if (!date) {
            return "";
        }
        let mDate: moment.Moment;
        if (typeof date === "string") {
            mDate = moment(date);
        } else {
            mDate = date;
        }
        mDate.local();
        return mDate.locale("nl").fromNow();
    }
}
