import { postfixLength, splitOnPosition } from "../helpers/textHelper";
import { TransporterInvoiceImportItemJSON } from "./json/transporterInvoiceImportItemJSON";

export class TransporterInvoiceImportItem {
    public constructor(
        public totalAmount: number,
        public externalId: number,
        public externalReference: string,
        public price: number,
        public productName: string,
        public externalInvoiceId: string,
    ) {}

    static fromJSON(json: TransporterInvoiceImportItemJSON) {
        return new TransporterInvoiceImportItem(
            json.total_amount,
            json.external_id,
            json.external_reference,
            json.price,
            json.product_name,
            json.external_invoice_id,
        );
    }

    toSaveJSON(): TransporterInvoiceImportItemJSON {
        return {
            total_amount: this.totalAmount,
            external_id: this.externalId,
            external_reference: this.externalReference,
            price: this.price,
            product_name: this.productName,
            external_invoice_id: this.externalInvoiceId,
        };
    }

    split() {
        const result = [];
        const numberLength = postfixLength(this.externalReference, (c) => c >= "0" && c <= "9");
        const [prefix, numberPart] = splitOnPosition(
            this.externalReference,
            this.externalReference.length - numberLength,
        );
        for (let index = 0n; index < this.totalAmount; index++) {
            const reference = prefix + `${BigInt(numberPart) + index}`.padStart(numberLength, "0");
            result.push(
                new TransporterInvoiceImportItem(
                    1,
                    this.externalId,
                    reference,
                    this.price / this.totalAmount,
                    this.productName,
                    this.externalInvoiceId,
                ),
            );
        }
        return result;
    }
}
