import { Component, OnInit } from "@angular/core";
import { BackendService } from "../services/backend.service";
import { ViaWebshop } from "../models/viawebshop.model";
import { PAGE_SIZE_10 } from "../helpers/constants";

@Component({
    selector: "app-webshops",
    templateUrl: "./webshops.component.html",
    styleUrls: ["./webshops.component.scss"],
})
export class WebshopsComponent implements OnInit {
    users: ViaWebshop[] = [];

    searchTerm = "";

    total_items: number;

    items_per_page = PAGE_SIZE_10;

    curr_page = 0;

    filters: { active: boolean; start: number } = {
        active: true,
        start: 0,
    };

    constructor(private backend: BackendService) {}

    ngOnInit() {
        this.updateUsers();
    }

    updateUsers() {
        this.filters.start = this.curr_page * this.items_per_page;
        this.backend
            .getWebshops(this.searchTerm, this.filters, this.items_per_page)
            .subscribe((pageResult) => {
                this.total_items = pageResult.total;
                this.users = pageResult.users.map(ViaWebshop.fromJSON);
            });
    }

    nextPage() {
        this.curr_page++;
        this.updateUsers();
    }

    previousPage() {
        this.curr_page--;
        this.updateUsers();
    }

    getTotalPages(): number {
        let total = Math.floor(this.total_items / this.items_per_page);
        const plusOne = this.total_items % this.items_per_page;

        if (plusOne) {
            total++;
        }

        return total;
    }

    filterChange() {
        this.curr_page = 0;
        this.updateUsers();
    }
}
