import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BackendService } from "../../services/backend.service";
import { AddressResultJson } from "../../models/json/addressResultJson";
import { CountryService } from "../../services/country.service";
import { isEmptyText } from "../../helpers/textHelper";

import { User } from "../../models/user.model";
import { Country } from "../../models/country";

const MAX_LENGTH_NAME = 50;

@Component({
    selector: "app-create-contact-modal",
    templateUrl: "./create-contact-modal.component.html",
    styleUrls: ["./create-contact-modal.component.scss"],
})
export class CreateContactModalComponent implements OnInit {
    contact = User.createEmpty();

    errors: any = {};

    countries: Country[] = [];

    manualStreetInput = false;

    addressRes: AddressResultJson;

    streetRes: string;

    cityRes: string;

    housenrletters: string;

    constructor(
        private router: Router,
        private countryService: CountryService,
        private backend: BackendService,
    ) {}

    ngOnInit() {
        this.countries = this.countryService.countries;
        this.contact.country = "NL";
    }

    get hasErrors() {
        return Object.keys(this.errors).length > 0;
    }

    onSubmit(event: Event) {
        event.preventDefault();
        this.checkInput();
        if (!this.hasErrors) {
            this.backend.createContact(this.contact).subscribe(
                () => {
                    this.backend.showMessage(true, "Adres is aangemaakt!");
                    this.router
                        .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
                        .then(() => this.router.navigate(["/contacts"]));
                },
                () => {
                    this.backend.showMessage(
                        false,
                        "Foutcode 12a: Het toevoegen van een adres is niet gelukt.",
                    );
                },
            );
        }
    }

    updateManualStreetInput() {
        if (!this.hasAutomaticPostcodeCheck) {
            this.manualStreetInput = true;
        }
    }

    get hasAutomaticPostcodeCheck() {
        return this.contact.country === "NL";
    }

    updateAddress() {
        if (this.contact.houseNumber && this.contact.postcode && !this.manualStreetInput) {
            this.backend
                .getAddressByPostcode(
                    this.contact.postcode,
                    this.contact.houseNumber,
                    this.contact.houseNumberExtra,
                )
                .subscribe(
                    (addressRes) => {
                        this.contact.streetname = addressRes.street;
                        this.contact.city = addressRes.city;
                        this.checkInput();
                    },
                    (e) => {
                        if (e.success === false) {
                            this.errors.streetname = ["cannot-resolve"];
                        } else {
                            this.errors.streetname = ["server-error"];
                        }
                        this.contact.streetname = "";
                        this.contact.city = "";
                    },
                );
        }
    }

    hasError(field: string, error?: string) {
        if (this.errors[field]) {
            if (error) {
                return this.errors[field].includes(error);
            }
            return this.errors[field];
        }
        return false;
    }

    get housenrContainsNum() {
        return /\d/g.test(this.contact.houseNumber);
    }

    checkInput() {
        this.errors = {};
        if (!this.contact.country) {
            this.errors.country = ["required"];
        }
        if (isEmptyText(this.contact.firstname)) {
            this.errors.firstname = ["required"];
        } else if (this.contact.firstname.length > MAX_LENGTH_NAME) {
            this.errors.firstname = ["too-long"];
        }
        if (this.contact.country && this.contact.country.toUpperCase() === "NL") {
            if (isEmptyText(this.contact.houseNumber)) {
                this.errors.housenr = ["required"];
            } else if (!this.housenrContainsNum) {
                this.errors.housenr = ["invalid"];
            }
        }

        if (isEmptyText(this.contact.lastname)) {
            this.errors.lastname = ["required"];
        } else if (this.contact.lastname.length > MAX_LENGTH_NAME) {
            this.errors.lastname = ["too-long"];
        }
        if (isEmptyText(this.contact.postcode)) {
            this.errors.postcode = ["required"];
        }
    }
}
