import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface Message {
    recipient: string;
    content: any;
}

@Injectable()
export class DataService {
    private messageSource = new BehaviorSubject<any>([]);

    currentMessage = this.messageSource.asObservable();

    changeMessage(message?: Message) {
        this.messageSource.next(message);
    }
}
