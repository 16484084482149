import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler } from "@angular/core";
import { AuthGuard } from "./auth.guard";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";
import { ContactService } from "./contact.service";
import { CountryService } from "./country.service";
import { DataService } from "./data.service";
import { DHLSyncService } from "./dhl-sync.service";
import { TokenInterceptor } from "./token.interceptor";
import { JwtInterceptor } from "./jwt.interceptor";
import { SentryErrorHandler } from "./sentryErrorHandler";
import { PriceCategoryService } from "./priceCategory.service";
import { InvoiceService } from "./invoices.service";
import { ExportService } from "./export.service";

export const serviceProviders = [
    AuthService,
    AuthGuard,
    BackendService,
    CountryService,
    ContactService,
    DataService,
    ExportService,
    PriceCategoryService,
    InvoiceService,
    DHLSyncService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true,
    },
    {
        provide: ErrorHandler,
        useClass: SentryErrorHandler,
    },
];
