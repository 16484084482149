import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filter",
})
export class FilterPipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        const lowerSearchText = searchText.toLowerCase();
        return items.filter((d) => {
            const searchTerms = [
                d.fullname,
                d.firstname,
                d.lastname,
                d.streetname,
                d.postcode,
                d.city,
                d.phone,
                d.email,
                d.company_name,
            ];
            const searchString = searchTerms.join("|").toLowerCase();
            return searchString.includes(lowerSearchText);
        });
    }
}
