import { Component, OnInit } from "@angular/core";
import * as FileSaver from "file-saver-es";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceService } from "../../services/invoices.service";
import { TablePage } from "../../models/tablePage.model";
import { TransporterInvoice } from "../../models/transporterInvoice";
import { Store } from "@ngrx/store";
import { addMessage } from "../../store/actions/message.actions";
import { Message } from "../../models/message";
import { finishBusy, startBusy } from "src/app/store/actions/busy.actions";

const NUMBER_OF_RESULTS = 20;
@Component({
    selector: "app-transporter-invoice-list",
    templateUrl: "./transporter-invoice-list.component.html",
    styleUrls: ["./transporter-invoice-list.component.scss"],
})
export class TransporterInvoiceListComponent implements OnInit {
    items: TablePage<TransporterInvoice>;

    page = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private invoiceService: InvoiceService,
        private store: Store,
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe({
            next: (params) => {
                this.page = Number.parseInt(params.get("page")) || 0;
                this.updateInvoices();
            },
        });
    }

    updateInvoices() {
        this.store.dispatch(startBusy());
        this.invoiceService.get(this.page * NUMBER_OF_RESULTS, NUMBER_OF_RESULTS).subscribe({
            next: (result) => {
                this.store.dispatch(finishBusy());
                this.items = result;
            },
        });
    }

    gotoPage(page: number) {
        this.page = page;
        this.updateInvoices();
    }

    open(invoice: TransporterInvoice) {
        this.router.navigate([
            "invoices",
            "compare",
            invoice.externalId,
            invoice.externalInvoiceId,
        ]);
    }

    delete(invoice: TransporterInvoice) {
        this.invoiceService
            .deleteTransporterInvoice(invoice.externalId, invoice.externalInvoiceId)
            .subscribe({
                complete: () => {
                    this.store.dispatch(
                        addMessage({
                            message: Message.createSuccessMessage(
                                "Succes",
                                "De factuur is verwijderd",
                            ),
                        }),
                    );
                    this.updateInvoices();
                },
            });
    }

    recalculate(invoice: TransporterInvoice) {
        this.store.dispatch(startBusy());
        this.invoiceService.recalculateTransporterInvoice(invoice.externalInvoiceId).subscribe({
            complete: () => {
                this.store.dispatch(finishBusy());
                this.store.dispatch(
                    addMessage({
                        message: Message.createSuccessMessage(
                            "Succes",
                            "De prijzen zijn herberekend",
                        ),
                    }),
                );
                this.updateInvoices();
            },
            error: () => {
                this.store.dispatch(finishBusy());
                this.store.dispatch(
                    addMessage({
                        message: Message.createErrorMessage(
                            "Fout",
                            "Er ging iets fout bij het herberekenen van de prijzen",
                        ),
                    }),
                );
            },
        });
    }

    exportCSV(invoice: TransporterInvoice) {
        this.store.dispatch(startBusy());
        this.invoiceService
            .exportTransporterInvoice(invoice.externalId, invoice.externalInvoiceId)
            .subscribe({
                next: (data) => {
                    this.store.dispatch(finishBusy());
                    const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                    FileSaver.saveAs(
                        blob,
                        `invoice-comparison-${invoice.externalId}-${invoice.externalInvoiceId}.csv`,
                    );
                },
                error: () => {
                    this.store.dispatch(finishBusy());
                    const message = Message.createErrorMessage(
                        "Fout bij het downloaden",
                        "Er is iets fout gegaan bij het downloaden van de csv",
                    );
                    this.store.dispatch(addMessage({ message }));
                },
            });
    }
}
