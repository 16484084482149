import moment from "moment";
import { User } from "./user.model";
import { E_USER_TYPES } from "../helpers/constants";

export class ViaBode extends User {
    constructor(
        public override dateCreated: moment.Moment,
        public override postcode: string,
        public override houseNumber: string,
        public override houseNumberExtra: string,
        public override city: string,
        public override streetname: string,
        public override addressExtra: string,
        public override initials: string,
        public override firstname: string,
        public override middlename: string,
        public override lastname: string,
        public override email: string,
        public override companyName: string,
        public override country: string,
        public override phone: string,
        public name: string,
        public dateApply: moment.Moment,
        public dateStart: moment.Moment,
        public override iban: string,
        public packagebox: Boolean,
        public termsAccepted: Boolean,
        public photoPath: string = "empty",
        public idScanPath: string = "empty",
        public vogScanPath: string = "empty",
        public override userName: string,
        public override isActive: boolean,
        public override kvk?: string,
        public override id?: number,
    ) {
        super(
            dateCreated,
            postcode,
            houseNumber,
            houseNumberExtra,
            city,
            streetname,
            addressExtra,
            initials,
            firstname,
            middlename,
            lastname,
            email,
            companyName,
            country,
            E_USER_TYPES.VIABODE,
            phone,
            [],
            userName,
            isActive,
            E_USER_TYPES.VIABODE,
            id,
        );
    }

    static override createEmpty() {
        return new ViaBode(
            moment(),
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "NL",
            "",
            "",
            moment(),
            moment(),
            "",
            false,
            false,
            "empty",
            "empty",
            "empty",
            "",
            true,
        );
    }

    toCreateJSON() {
        const data = {
            user: {
                postcode: this.postcode,
                housenr: this.houseNumber,
                phone: this.phone,
                type: E_USER_TYPES.VIABODE,
                initials: this.initials,
                firstname: this.firstname,
                middlename: this.middlename,
                lastname: this.lastname,
                email: this.email,
                country: "NL",
                company_name: "ViaTim",
            },
            viabode: {
                iban: this.iban,
                id_scan_path: this.idScanPath,
                vog_scan_path: this.vogScanPath,
            },
            account: {
                username: this.getFullName().split(" ").join(".").toLowerCase(),
            },
        };
        return data;
    }
}
