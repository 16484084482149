<app-cp-page>
    <div>
        <svg
            version="1.2"
            baseProfile="tiny"
            id="Laag_2"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 200 100"
            xml:space="preserve"
        >
            <polyline
                class="path1"
                fill="none"
                stroke="#F4479D"
                stroke-width="4"
                stroke-miterlimit="10"
                points="161,56 127,56 150,22 150,71 "
            />
            <polyline
                class="path1"
                fill="none"
                stroke="#F4479D"
                stroke-width="4"
                stroke-miterlimit="10"
                points="74,56 40,56 63,22 63,71 "
            />
            <path
                class="path2"
                fill="none"
                stroke="#F4479D"
                stroke-width="4"
                stroke-miterlimit="10"
                d="M100,20c8.2,0,15,6.7,15,15v21c0,8.2-6.7,15-15,15h0 c-8.2,0-15-6.7-15-15V35C85,26.7,91.7,20,100,20L100,20"
            />
            <line
                class="line1"
                fill="none"
                stroke="#F4479D"
                stroke-width="4"
                stroke-miterlimit="10"
                x1="114.8"
                y1="32.8"
                x2="85.2"
                y2="58.6"
            />
        </svg>
        <h1>Timmetje is zoek!</h1>
        <p>
            We hebben ons best gedaan... maar de pagina die je zocht kunnen we helaas niet (meer)
            vinden.
        </p>
        <p>
            Maar wees niet teleurgesteld, via ons menu aan de linker kant kunnen we je wellicht
            helpen.
        </p>
        <p>
            Wil je liever met ons praten? Dat kan via
            <a href="tel:010-261-3616">010-261-3616</a> of
            <a href="mailto:it@viatim.nl?SUBJECT=404pagina">it&#64;viatim.nl</a>
        </p>
    </div>
</app-cp-page>
