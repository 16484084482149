import { Pipe, PipeTransform } from "@angular/core";
import { PACKAGE_TYPES_TO_TEXT, E_PACKAGE_TYPES } from "../../helpers/constants";

@Pipe({ name: "packageType" })
export class PackageTypePipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(type: E_PACKAGE_TYPES) {
        try {
            return PACKAGE_TYPES_TO_TEXT[type];
        } catch (e) {
            return "Onbekend";
        }
    }
}
