import { Component, OnInit, OnDestroy } from "@angular/core";
import * as FileSaver from "file-saver-es";
import { Store } from "@ngrx/store";
import moment from "moment";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { isString, isEmpty } from "lodash-es";
import { BackendService } from "../../services/backend.service";
import { DataService } from "../../services/data.service";
import { Package } from "../../models/package.model";
import { Message } from "../../models/message";
import { addMessage } from "../../store/actions/message.actions";
import { refreshPackages } from "../../store/actions/packages.actions";
import { ValidationError } from "../../services/errors";

@Component({
    selector: "app-print-label-modal",
    templateUrl: "./print-label-modal.component.html",
    styleUrls: ["./print-label-modal.component.scss"],
})
export class PrintLabelModalComponent implements OnInit, OnDestroy {
    private tracktrace: string;

    package: Package;

    private sub: Subscription;

    constructor(
        private backend: BackendService,
        private dataService: DataService,
        private location: Location,
        private store: Store,
    ) {}

    ngOnInit() {
        this.sub = this.dataService.currentMessage.subscribe((message) => {
            if (message.recipient !== "print") {
                return;
            }
            this.tracktrace = message.content;
            if (!isString(this.tracktrace)) {
                this.location.back();
                return;
            }
            this.backend.getPackageByTrackTrace(this.tracktrace).subscribe(
                (result) => {
                    this.package = result;
                },
                () => {
                    const errorMessage = Message.createErrorMessage(
                        "Fout bij verwijderen",
                        "Er is iets misgegaan bij het verwijderen. Probeer het later nog eens.",
                    );
                    this.closeModal(errorMessage);
                },
            );
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    get hasServicePoint() {
        return !isEmpty(this.package.service_point);
    }

    onSubmit() {
        this.backend.getPackageLabel(this.tracktrace).subscribe(
            (d) => {
                const blob = new Blob([d], { type: "application/pdf" });
                const date = moment().format("YYYY-MM-DD");
                FileSaver.saveAs(blob, `Label_${date}_${this.tracktrace}.pdf`);
                const message = Message.createSuccessMessage(
                    "Label geprint",
                    "Label geprint en pakket is definitief.",
                );
                this.closeModal(message);
            },
            (e) => {
                let message: Message;
                if (e instanceof ValidationError) {
                    message = Message.createErrorMessage(
                        "Printen mislukt",
                        `De informatie op het label is niet correct ${e.fields.join(", ")} ${
                            e.reason
                        }`,
                    );
                } else {
                    message = Message.createErrorMessage(
                        "Printen mislukt",
                        "Het label kon niet worden opgehaald.",
                    );
                }
                this.closeModal(message);
            },
        );
    }

    closeModal(message: Message = null) {
        if (message) {
            this.store.dispatch(addMessage({ message }));
        }
        this.store.dispatch(refreshPackages());
        this.location.back();
    }
}
