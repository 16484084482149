export class TablePage<T> {
    constructor(
        public elements: T[],
        public start: number,
        public total: number,
        public pageSize: number,
    ) {}

    get pageNumber() {
        return Math.floor(this.start / this.pageSize);
    }

    get numberOfPages() {
        return Math.ceil(this.total / this.pageSize);
    }

    get isLastPage() {
        return this.pageNumber === this.numberOfPages - 1;
    }

    get isFirstPage() {
        return this.start === 0;
    }
}
