import { Action, createReducer, on } from "@ngrx/store";
import { PackageFilter } from "../../models/packageFilter";
import * as PackageFilterActions from "../actions/package-filter.actions";

export const initialState: PackageFilter = new PackageFilter();

const packageFilterReducer = createReducer(
    initialState,
    on(PackageFilterActions.clearPackageFilter, () => new PackageFilter()),
    on(PackageFilterActions.setPackageFilter, (_, props) => props.filter),
);

export function reducer(state: PackageFilter, action: Action) {
    return packageFilterReducer(state, action);
}
