<div *ngIf="packages$ | async as packages" class="package-table">
    <app-package-filter [totalPackages]="packages.total"></app-package-filter>
    <h4 class="warning" *ngIf="packages && packages.total === 0">
        Er zijn geen pakketjes gevonden met de huidige filters.
    </h4>
    <table class="table" *ngIf="packages && packages.total > 0">
        <thead>
            <tr>
                <th *ngIf="!isSupportUser"></th>
                <!-- Select Button -->
                <th></th>
                <!-- External -->
                <th>Track & Trace</th>
                <th>Aangemaakt</th>
                <th>Type</th>
                <th>Afzender</th>
                <th>Ontvanger</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let package of packages.elements; let i = index" [attr.data-index]="i">
                <td *ngIf="!isSupportUser">
                    <div class="custom-control custom-checkbox">
                        <input
                            *ngIf="selectedPackages$ | async as selectedPackages"
                            (click)="clickCheckbox($event, package.tracktrace, i)"
                            class="form-check-input position-static"
                            type="checkbox"
                            [checked]="selectedPackages.includes(package.tracktrace)"
                        />
                    </div>
                </td>
                <td>
                    <app-external-icon external="{{ package.external_id }}"></app-external-icon>
                </td>
                <td *ngIf="!package.concept">
                    <div class="copyClipboard" (click)="copyMessage(package.tracktrace)">
                        <i class="fad fa-paste fa-lg"></i>
                    </div>
                    <a
                        *ngIf="!package.concept"
                        href="{{ package.trackAndTraceUrl }}"
                        target="_blank"
                    >
                        {{ package.tracktrace }}
                        <i class="far fa-share fa-xs" data-fa-transform="up-2"></i
                    ></a>
                </td>
                <td *ngIf="package.concept">
                    <div class="copyClipboard" (click)="copyMessage(package.tracktrace)">
                        <i class="fad fa-paste fa-lg"></i>
                    </div>
                    {{ package.tracktrace }}
                </td>
                <td>{{ package.dateCreated | splitDate }}</td>
                <!-- For Webshop -->
                <td>{{ package.type | packageType }}</td>
                <td>
                    <app-address-cell [address]="package.sender"></app-address-cell>
                </td>
                <td>
                    <app-address-cell [address]="package.recipient"></app-address-cell>
                </td>
                <!-- Check if package is in concept. If yes show in concept message -->
                <td *ngIf="package.concept === true && package.last_status === 0; else showStatus">
                    <b *ngIf="!package.containsErrors">Pakket in concept</b>
                    <span *ngIf="package.containsErrors" class="badge rounded-pill bg-danger">
                        <i class="fa fa-exclamation-triangle errorPackage"></i> Dit pakket bevat
                        validatiefouten
                    </span>
                </td>
                <!-- Below is when the package is NOT in concept  -->
                <ng-template #showStatus>
                    <td>
                        {{ package | currentStatusText }}
                    </td>
                </ng-template>
                <td>
                    <div *ngIf="!isSupportUser" class="btn-group" role="group">
                        <button
                            *ngIf="!package.concept"
                            type="button"
                            [routerLink]="['/packages', package.tracktrace]"
                            class="btn btn-outline-primary"
                        >
                            <i class="fad fa-eye fa-lg"></i>
                        </button>
                        <button
                            *ngIf="package.concept"
                            [routerLink]="['/packages/edit', package.tracktrace]"
                            type="button"
                            class="btn btn-outline-primary"
                        >
                            <i class="fad fa-pencil fa-lg"></i>
                        </button>
                        <button
                            (click)="labelModal(package.tracktrace)"
                            type="button"
                            class="btn btn-outline-primary"
                        >
                            <i class="fad fa-print fa-lg"></i>
                        </button>
                        <button
                            *ngIf="package.concept"
                            (click)="deleteModal(package.tracktrace)"
                            type="button"
                            class="btn btn-outline-primary"
                        >
                            <i class="fad fa-trash-alt fa-lg"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <app-pagination [tablePage]="packages" (onPageChange)="gotoPage($event)"></app-pagination>
</div>
