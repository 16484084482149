import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { components } from "../components";
import { pipes } from "../helpers/pipes";

@NgModule({
    imports: [CommonModule],
    declarations: [...components, ...pipes],
    exports: [...components, ...pipes],
})
export class SharedModule {}
