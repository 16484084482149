import moment, { Moment } from "moment";
import { PickupItemJSON, PickupItemSaveJSON } from "./json/pickupItemJSON";

export class PickupItem {
    public constructor(
        public date: Moment,
        public webshopId: number,
        public webshopName: string,
        public category: string,
    ) {}

    toSaveJSON(): PickupItemSaveJSON {
        return {
            date: this.date.format("YYYY-MM-DD"),
            webshop_id: this.webshopId,
            category: this.category,
        };
    }

    toJSON(): PickupItemJSON {
        return {
            date: this.date.format("YYYY-MM-DD"),
            webshop_id: this.webshopId,
            webshop_name: this.webshopName,
            category: this.category,
        };
    }

    static fromJSON(json: PickupItemJSON): PickupItem {
        return new PickupItem(
            moment(json.date, "YYYY-MM-DD"),
            json.webshop_id,
            json.webshop_name,
            json.category,
        );
    }
}
