import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { mergeMap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { BackendService } from "../../../../services/backend.service";
import { Package } from "../../../../models/package.model";

@Component({
    selector: "app-package-detail-history-tab",
    templateUrl: "./package-detail-history-tab.component.html",
    styleUrls: ["./package-detail-history-tab.component.scss"],
})
export class PackageDetailHistoryTabComponent implements OnInit, OnDestroy {
    signature: any;

    history: any;

    private sub: Subscription;

    @Input() public package: any; // this is typed as string, but you can use any type you want

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private backend: BackendService,
    ) {}

    ngOnInit() {
        this.sub = this.route.paramMap
            .pipe(
                mergeMap((params) => {
                    const tracktrace = params.get("id");
                    return this.backend.getPackageByTrackTrace(tracktrace);
                }),
            )
            .subscribe(
                (result) => {
                    this.package = result;
                    this.getPackageHistory(this.package);
                },
                () => {
                    this.router.navigateByUrl("/packages");
                },
            );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    getPackageHistory(parcel: Package) {
        this.backend.getPackageHistory(parcel.id).subscribe(
            (result) => {
                this.history = result;
                const statusses = this.history.map((step) => step.external.status);
                this.backend
                    .getStatusTranslation(statusses, "NL", parcel.external_id)
                    .subscribe((translations) => {
                        translations.forEach((translation, index) => {
                            this.history[index].external.status = translation;
                        });
                    });
            },
            () => {
                this.router.navigateByUrl("/packages");
            },
        );
    }
}
