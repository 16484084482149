import { UrlTree } from "@angular/router";
import { createAction, props } from "@ngrx/store";
import { AuthenticationState } from "../../models/authenticationState";

export const login = createAction(
    "[Login Component] Login",
    props<{
        username: string;
        password: string;
        userId?: number;
        redirectUrlTree: UrlTree;
    }>(),
);

export const logout = createAction("[Login Component] Logout");

export const loginSuccess = createAction(
    "[Login Component] Login Success",
    props<{ token: string }>(),
);

export const loginFailure = createAction(
    "[Login Component] Login Failure",
    props<{ reason: AuthenticationState }>(),
);
