import { Action, createReducer, on } from "@ngrx/store";
import * as currentUserActions from "../actions/current-user.actions";
import { User } from "../../models/user.model";

export const initialState: User = undefined;

const currentUserReducer = createReducer(
    initialState,
    on(currentUserActions.loadCurrentUser, () => undefined),
    on(currentUserActions.loadedCurrentUser, (_, props) => props.user),
);

export function reducer(state: User, action: Action) {
    return currentUserReducer(state, action);
}
