<app-cp-page>
    <div class="standard-container" *ngIf="user">
        <div class="header">
            <h1>Account Managing voor {{ user.companyName }}</h1>
            <p>Aangemaakt op {{ user.dateCreated | splitDate }}</p>
        </div>

        <form [formGroup]="editForm" (ngSubmit)="onSubmit(editForm.value)">
            <div class="form-group row">
                <div class="col">
                    <label class="text-right" for="firstname"
                        >Voornaam<i class="fas fa-asterisk"></i
                    ></label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Voornaam"
                        formControlName="firstname"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
                <div class="col">
                    <label class="text-right" for="middlename"
                        >tussenvoegsel<i class="fas fa-asterisk"></i
                    ></label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Tussenvoegsel"
                        formControlName="middlename"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
                <div class="col">
                    <label class="text-right" for="lastname"
                        >Achternaam<i class="fas fa-asterisk"></i
                    ></label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Achternaam"
                        formControlName="lastname"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right" for="companyName"
                    >Bedrijfsnaam<i class="fas fa-asterisk"></i
                ></label>
                <div class="col-sm-10">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="ViaTim"
                        formControlName="companyName"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
            </div>

            <div *ngIf="userIsWebshop" class="form-group row">
                <label class="col-sm-2 col-form-label text-right" for="domain"
                    >Website<i class="fas fa-asterisk"></i
                ></label>
                <div class="col-sm-10">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="ViaTim"
                        formControlName="domain"
                        [appInlineError]="{ errors: errors, prefix: 'viawebshop' }"
                    />
                </div>
            </div>

            <div class="form-group row">
                <div class="col">
                    <label class="coltext-right" for="postcode"
                        >Postcode<i class="fas fa-asterisk"></i
                    ></label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="3044CK"
                        formControlName="postcode"
                        (change)="updateAddress()"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
                <div class="col">
                    <label class="coltext-right" for="houseNumber"
                        >Huisnummer<i class="fas fa-asterisk"></i
                    ></label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="62"
                        formControlName="houseNumber"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
                <div class="col">
                    <label class="coltext-right" for="houseNumberExtra"
                        >Toevoeging<i class="fas fa-asterisk"></i
                    ></label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="A"
                        formControlName="houseNumberExtra"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
            </div>

            <div class="form-group row mb-3 mt-3">
                <div class="col">
                    <div class="form-check form-check-inline">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            [disabled]="!hasAutomaticPostcodeCheck"
                            [(ngModel)]="manualStreetInput"
                            [ngModelOptions]="{ standalone: true }"
                            name="manualStreetInput"
                            id="manualStreetInput"
                        />
                        <label class="form-check-label" for="manualStreetInput"
                            >Handmatig adres invoeren</label
                        >
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right" for="streetname"
                    >Straat<i class="fas fa-asterisk"></i
                ></label>
                <div class="col-sm-10">
                    <input
                        type="text"
                        [readonly]="!manualStreetInput"
                        class="form-control"
                        placeholder="Vlaardingweg"
                        formControlName="streetname"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right" for="addressExtra"
                    >Tweede adresregel</label
                >
                <div class="col-sm-10">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Extra adresregel"
                        formControlName="addressExtra"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right" for="city"
                    >Plaatsnaam<i class="fas fa-asterisk"></i
                ></label>
                <div class="col-sm-10">
                    <input
                        type="text"
                        [readonly]="!manualStreetInput"
                        class="form-control"
                        placeholder="Rotterdam"
                        formControlName="city"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right" for="email"
                    >Emailadres<i class="fas fa-asterisk"></i
                ></label>
                <div class="col-sm-10">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="tim@viatim.nl"
                        formControlName="email"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label text-right" for="phone"
                    >Telefoonnummer<i class="fas fa-asterisk"></i
                ></label>
                <div class="col-sm-10">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="010 261 3616"
                        formControlName="phone"
                        [appInlineError]="{ errors: errors, prefix: 'user' }"
                    />
                </div>
            </div>

            <div class="d-grid gap-2 d-md-flex justify-content-center mt-3">
                <button class="btn btn-secondary inverse" [routerLink]="['/account-management']">
                    Terug
                </button>
                <button class="btn btn-primary inverse" [disabled]="editForm.invalid">
                    Opslaan
                </button>
            </div>
        </form>
    </div>
</app-cp-page>
