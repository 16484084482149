import { Observable, throwError, EMPTY } from "rxjs";
import { StatusCodes } from "http-status-codes";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from "@angular/common/http";
import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";

const EXPIRED_TOKEN_MESSAGE = "Token Failed Authentication.";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private backend: BackendService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (
                    err instanceof HttpErrorResponse &&
                    (err.status === StatusCodes.UNAUTHORIZED ||
                        err.status === StatusCodes.FORBIDDEN)
                ) {
                    this.auth.logout();
                    if (
                        err.error.message == EXPIRED_TOKEN_MESSAGE ||
                        err.error == EXPIRED_TOKEN_MESSAGE
                    ) {
                        this.backend.showMessage(false, "Uw sessie is verlopen, log opnieuw in.");
                        return EMPTY;
                    }
                }
                return throwError(err);
            }),
        );
    }
}
