/* eslint-disable max-classes-per-file */

export class NotFoundError extends Error {}

export class ForbiddenError extends Error {}

export class UnauthorizedError extends Error {}

export class ExternalServerError extends Error {}

export class ConnectionError extends Error {}

export class ConflictError extends Error {}

export class ValidationError extends Error {
    error: string;

    fields: string[];

    reason: string;

    constructor(e) {
        super(e.message);
        Object.assign(this, e.error);
    }
}

export class DeleteConflictError extends Error {
    constructor(public cause?: Error) {
        super("DeleteConflictError");
    }
}

export class ServerError extends Error {
    constructor(public cause?: Error) {
        super("ServerError");
    }
}
