import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PackageJSON } from "../models/json/packageJSON";
import { environment } from "../../environments/environment";
import { Package } from "../models/package.model";
import { TablePage } from "../models/tablePage.model";
import { PackageFilter } from "../models/packageFilter";
import { getToken } from "../helpers/tokenHelper";
import { Observable } from "rxjs";

export interface PackagesResponse {
    success: boolean;
    packages: PackageJSON[];
    total: number;
}

@Injectable()
export class PackagesService {
    constructor(private http: HttpClient) {}

    /**
     * Retrieves packages from the ViaTim backend service.
     * An optional filter can be provided to adjust the result.
     * By default the method will request up to 32 packages.
     * @param {PackageFilter} [filter]
     * @returns {Observable<Package[]>}
     */
    getPackages(filter: PackageFilter = new PackageFilter()): Observable<TablePage<Package>> {
        const headers = new HttpHeaders().set("x-access-token", getToken());

        const params = filter.toSearchParams();
        const options = { params, headers };

        return this.http.get<PackagesResponse>(`${environment.apiBase}/packages`, options).pipe(
            map((response) => {
                const elements = response.packages.map(Package.fromJSON);
                return new TablePage(elements, filter.start, response.total, filter.limit);
            }),
        );
    }
}
