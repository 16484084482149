<app-cp-modal title="Kopieer pakket">
    <form (ngSubmit)="onSubmit()" #packageForm="ngForm">
        <div *ngIf="package" id="print-label">
            <h2>
                <span>Let op:</span> Bij het bevestigen van deze actie wordt er een nieuw pakket
                aangemaakt met de volgende gegevens.
            </h2>
            <div class="select-div">
                <span>Nieuwe vervoerder: </span
                ><select
                    class="select-option"
                    [(ngModel)]="optionSelected"
                    [ngModelOptions]="{ standalone: true }"
                >
                    <option
                        class="option"
                        *ngFor="let option of availableCarriers"
                        [ngValue]="option.value"
                    >
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div id="packageDetails">
                <div class="senderInfo">
                    <ul>
                        <li>
                            <h4>Verzender</h4>
                        </li>
                        <li>{{ package.sender | fullName }}</li>
                        <li>
                            {{ package.sender.streetname }} {{ package.sender.houseNumber }}
                            {{ package.sender.houseNumberExtra }}
                        </li>
                        <li>{{ package.sender.addressExtra }}</li>
                        <li>{{ package.sender.postcode }}</li>
                        <li>{{ package.sender.city }}</li>
                        <li>
                            <a href="tel:{{ package.sender.phone }}"> {{ package.sender.phone }}</a>
                        </li>
                        <li>
                            <a href="mailto:{{ package.sender.email }}">{{
                                package.sender.email
                            }}</a>
                        </li>
                    </ul>
                </div>
                <div class="recipientInfo">
                    <ul>
                        <li>
                            <h4>Ontvanger</h4>
                        </li>
                        <li>
                            <i
                                *ngIf="package.recipient.companyName !== ''"
                                class="far fa-building"
                            ></i>
                            {{ package.recipient.companyName }}
                        </li>
                        <li>{{ package.recipient | fullName }}</li>
                        <li>
                            {{ package.recipient.streetname }}
                            {{ package.recipient.houseNumber }}
                            {{ package.recipient.houseNumberExtra }}
                        </li>
                        <li>{{ package.recipient.addressExtra }}</li>
                        <li>{{ package.recipient.postcode }}</li>
                        <li>{{ package.recipient.city }}</li>
                        <li>{{ package.recipient.country }}</li>
                        <li>
                            <a href="tel:{{ package.recipient.phone }}">
                                {{ package.recipient.phone }}</a
                            >
                        </li>
                        <li>
                            <a href="mailto:{{ package.recipient.email }}">
                                {{ package.recipient.email }}</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <button class="btn inverse" type="submit">Maak aan</button>
    </form>
</app-cp-modal>
