export const MAX_LENGTH = {
    TRACKTRACE: 12,
    SIZE: 255,
    INSTRUCTIONS: 144,
    SIGNIATURE: 255,
    MARK: 30,
    SOURCE_REFERENCE: 24,
    SOURCE: 24,
    FIRSTNAME: 50,
    MIDDLENAME: 30,
    LASTNAME: 50,
    COMPANY_NAME: 55,
    VIAPUNT_NAME: 128,
    POSTCODE: 16,
    HOUSENR: 10,
    HOUSENR_EXTRA: 5,
    STREETNAME: 64,
    ADDRESS_EXTRA: 255,
    CITY: 30,
    COUNTRY: 2,
    PHONE: 15,
    EMAIL: 255,
    INITIALS: 10,
    BTWNR: 15,
    KVK: 20,
    IBAN: 34,
    BIC: 11,
    ASCRIPTION: 128,
    USERNAME: 255,
    DOMAIN: 50,
    BTW: 20,
    ORDER_REFERENCE: 15,
    NEWSLETTER_TITLE: 50,
    NOTIFICATION_TITLE: 50,
    NOTIFICATION_BODY: 60,
    NEWSLETTER_BODY: 3900,
};
