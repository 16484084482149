import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BackendService } from "../../services/backend.service";
import { AuthService } from "../../services/auth.service";
import { Package } from "../../models/package.model";
import { E_USER_TYPES, E_TRACKING_STATUSES } from "../../helpers/constants";

@Component({
    selector: "app-force-package",
    templateUrl: "./force-package.component.html",
    styleUrls: ["./force-package.component.scss"],
})
export class ForcePackageComponent implements OnInit {
    private tracktrace: string;

    package: Package;

    constructor(
        private backend: BackendService,
        private router: Router,
        private auth: AuthService,
    ) {}

    ngOnInit() {
        const urlraw = this.router.url.split("/")[2];
        const url = urlraw.split("(")[0];
        this.tracktrace = url;

        this.backend.getPackageByTrackTrace(this.tracktrace).subscribe(
            (result) => {
                this.package = result;
                if (
                    this.auth.getAccountRole() !== E_USER_TYPES.GLOBAL_ADMIN &&
                    this.auth.getAccountRole() !== E_USER_TYPES.MODERATOR &&
                    this.auth.getAccountRole() !== E_USER_TYPES.VIADC
                ) {
                    this.backend.showMessage(
                        false,
                        "Foutcode 5a: Je hebt geen toegang om deze actie uit te voeren!",
                    );
                    this.router.navigateByUrl("/unauthorized");
                }
            },
            () => {
                this.router.navigateByUrl("/packages");
            },
        );
    }

    onSubmit() {
        this.backend
            .updatePackage(E_TRACKING_STATUSES.PACKAGE_GIVEN_TO_VIAGEBRUIKER, this.package.id)
            .subscribe();
        this.backend.showMessage(true, "Pakket heeft nu de status bezorgd");
        this.router.navigateByUrl(`/packages/${this.tracktrace}`);
    }
}
