import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule } from "@angular/forms";

import { ControlPanelModule } from "../control-panel/control-panel.module";
import { ContactsRoutingModule } from "./contacts-routing.module";

import { CreateContactModalComponent } from "./create-contact-modal/create-contact-modal.component";

// Shared Module
import { SharedModule } from "../shared/shared.module";

@NgModule({
    imports: [FormsModule, CommonModule, ControlPanelModule, ContactsRoutingModule, SharedModule],
    declarations: [CreateContactModalComponent],
    providers: [
        // PackagesService
    ],
})
export class ContactsModule {}
