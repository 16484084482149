import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { timer } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { addMessage, deleteMessage } from "../actions/message.actions";
import { countWords } from "../../helpers/textHelper";

const SHORT_MESSAGE_DELAY = 3500;
const LONG_MESSAGE_DELAY = 5000;
const SHORT_MESSAGE_WORDS_LIMIT = 10;

function calculateHideMessageDelay(words) {
    if (words <= SHORT_MESSAGE_WORDS_LIMIT) {
        return SHORT_MESSAGE_DELAY;
    }
    return LONG_MESSAGE_DELAY;
}

@Injectable()
export class MessageEffects {
    constructor(private actions$: Actions) {}

    addMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addMessage),
            mergeMap((action) =>
                timer(calculateHideMessageDelay(countWords(action.message.content))).pipe(
                    map(() => deleteMessage({ id: action.message.id })),
                ),
            ),
        ),
    );
}
