<app-cp-page>
    <div *ngIf="user" class="infoContainer">
        <div class="actionBox">
            <i class="fal fa-shopping-cart fa-4x"></i>
            <a [routerLink]="['/webshops', user.id, 'edit']" class="btn inverse">AANPASSEN</a>
        </div>
        <div>
            <h2>
                {{ user.companyName }}
            </h2>
            <dl class="viawebshopInfo greyContainer">
                <dt>
                    <p>ID:</p>
                    <p>{{ user.id }}</p>
                </dt>
                <dt>
                    <p>Website:</p>
                    <a href="//{{ user.domain }}" target="_blank" class="clickable">{{
                        user.domain
                    }}</a>
                </dt>
                <dt *ngIf="user.userName">
                    <p>Actief:</p>
                    <p>{{ user.isActive ? "Actief" : "Non-actief" }}</p>
                </dt>
                <dt>
                    <p>Aangemaakt:</p>
                    <p>{{ user.dateCreated | splitDate }}</p>
                </dt>
            </dl>
        </div>
        <div class="bigContainer">
            <h2>Contact gegevens</h2>
            <dl class="address greyContainer">
                <dt>
                    <p>Naam:</p>
                    <p>{{ user | fullName }}</p>
                </dt>
                <dt>
                    <p>Straat:</p>
                    <p>
                        {{ user.streetname }} {{ user.houseNumber }}
                        {{ user.houseNumberExtra }}
                    </p>
                </dt>
                <dt>
                    <p>Postcode:</p>
                    <p>{{ user.postcode }}</p>
                </dt>
                <dt>
                    <p>Stad:</p>
                    <p>{{ user.city }}</p>
                </dt>
                <dt>
                    <p>Telefoon:</p>
                    <a href="tel:{{ user.phone }}" class="clickable">
                        {{ user.phone ? user.phone : "-" }}</a
                    >
                </dt>
                <dt>
                    <p>E-mail:</p>
                    <a href="mailto:{{ user.email }}" class="clickable">{{ user.email }}</a>
                </dt>
            </dl>
        </div>
        <div>
            <h2>Operationeel</h2>
            <dl class="contactInfo greyContainer">
                <dt>
                    <p>BTW:</p>
                    <p>{{ user.btw }}</p>
                </dt>
                <dt>
                    <p>KvK:</p>
                    <p>{{ user.kvk }}</p>
                </dt>
                <dt>
                    <p>IBAN:</p>
                    <p>{{ user.iban ? user.iban : "-" }}</p>
                </dt>
                <dt>
                    <p>Webshopkoppeling:</p>
                    <p>
                        {{ user.webshopConnectorType ? user.webshopConnectorType : "-" }}
                    </p>
                </dt>
            </dl>
        </div>
    </div>

    <div *ngIf="isAllowedToDeactivate && user">
        <div class="row disabled infoContainer" *ngIf="!user.userName">
            <p>
                <i class="fas fa-ban icon"></i> Deze webshop heeft geen account en kan dus niet
                geactiveerd / gedeactiveerd worden! Neem contact op met IT
            </p>
        </div>

        <div class="row danger infoContainer" *ngIf="user.isActive">
            <p>
                <i class="far fa-times-circle icon"></i> Als je een Webshop deactiveert kan deze
                <span>niets</span> meer doen.
            </p>
            <button class="btn inverse" (click)="activateWebshopModal()">DEACTIVEER</button>
        </div>

        <div class="row success infoContainer" *ngIf="!user.isActive">
            <p>
                <i class="fas fa-heart icon"></i> Zodra je een Webshop heractiveert kan deze alles
                weer doen.
            </p>
            <button class="btn inverse" (click)="activateWebshopModal()">ACTIVEER</button>
        </div>
    </div>
</app-cp-page>
