import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DataService } from "../../../services/data.service";
import { BackendService } from "../../../services/backend.service";
import { ViaWebshop } from "../../../models/viawebshop.model";

@Component({
    selector: "app-webshops-activation-modal",
    templateUrl: "./webshops-activation-modal.component.html",
    styleUrls: ["./webshops-activation-modal.component.scss"],
})
export class WebshopsActivationModalComponent implements OnInit, OnDestroy {
    user: ViaWebshop;

    private sub: Subscription;

    accountStatus: string = "";

    constructor(
        private data: DataService,
        private backend: BackendService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.sub = this.data.currentMessage.subscribe((message) => {
            if (message.recipient == "webshop-activation") {
                this.user = <ViaWebshop>message.content;
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    activationWebshop() {
        this.backend.activateAccount(this.user.id).subscribe(
            () => {
                this.backend.showMessage(true, "Dit account is succesvol aangepast!");
                this.router.navigateByUrl("webshops");
            },
            () => {
                this.backend.showMessage(false, "De (de)activatie is niet gelukt!");
                this.router.navigate([`webshops/${this.user.id}`]);
            },
        );
    }
}
