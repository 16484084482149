import { TransporterInvoiceItemJSON } from "./json/transporterInvoiceItemJSON";

export class TransporterInvoiceItem {
    constructor(
        public id: number,
        public basePrice: number,
        public carrierCode: string,
        public companyName: string,
        public externalInvoiceId: string,
        public externalPrice: number,
        public externalReference: string,
        public externalPriceComponents: { name: string; price: number }[],
        public productName: string,
        public price: number,
        public internalPriceComponents: { name: string; price: number }[],
        public priceDifference: number,
        public priceType: number,
        public recipientCountry: string,
        public senderCountry: string,
        public size: string,
        public tracktrace: string,
        public weight: number,
    ) {}

    static fromJSON(json: TransporterInvoiceItemJSON): TransporterInvoiceItem {
        return new TransporterInvoiceItem(
            json.id,
            json.base_price,
            json.carrier_code,
            json.company_name,
            json.external_invoice_id,
            json.total_price,
            json.external_reference,
            json.external_price_components,
            json.product_name,
            json.price,
            json.internal_price_components,
            json.price_difference,
            json.price_type,
            json.recipient_country,
            json.sender_country,
            json.size,
            json.tracktrace,
            json.weight,
        );
    }

    get country() {
        if (this.senderCountry === "NL") {
            return this.recipientCountry;
        }
        return this.senderCountry;
    }
}
