/* eslint-disable max-classes-per-file */
import { WebshopTransporterConfigDataJSON, Priority } from "./json/webshopTransporterConfigJSON";

export class WebshopTransporterConfigData {
    constructor(
        public priorities?: Priority[],
        public accountID?: string,
        public inboundAccountID?: string,
        public rotate?: number,
        public sameDay?: boolean,
        public sendMail?: boolean,
    ) {}

    static fromJSON(json: WebshopTransporterConfigDataJSON) {
        return new WebshopTransporterConfigData(
            json.priorities,
            json.ACCOUNT_ID,
            json.INBOUND_ACCOUNT_ID,
            json.rotate,
            json.sameDay,
            json.sendMail !== false,
        );
    }

    toJSON(): WebshopTransporterConfigDataJSON {
        return {
            priorities: this.priorities,
            ACCOUNT_ID: this.accountID,
            INBOUND_ACCOUNT_ID: this.inboundAccountID,
            rotate: this.rotate,
            sameDay: this.sameDay,
            sendMail: this.sendMail,
        };
    }
}

export type WebshopTransporterConfigJSON = {
    transporter_code: string;
    data: WebshopTransporterConfigDataJSON;
};

export class WebshopTransporterConfig {
    constructor(public transporterCode: string, public data: WebshopTransporterConfigData) {}

    static fromJSON(json: WebshopTransporterConfigJSON): WebshopTransporterConfig {
        return new WebshopTransporterConfig(
            json.transporter_code,
            WebshopTransporterConfigData.fromJSON(json.data),
        );
    }

    toJSON(): WebshopTransporterConfigJSON {
        return {
            transporter_code: this.transporterCode,
            data: this.data.toJSON(),
        };
    }
}
