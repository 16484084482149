<app-cp-page>
    <!-- Package detailed information  -->
    <div *ngIf="package" id="package">
        <div class="packageDetails">
            <div class="packageOptions">
                <app-external-icon [external]="package.external_id"></app-external-icon>
                <span *ngIf="package.concept" class="concept">CONCEPT</span>
                <span *ngIf="package.id_check" class="idcheck"> 18+ </span>
                <span *ngIf="package.insured" class="verzekerd">
                    <i class="fal fa-shield-check fa-3x"></i>
                    <br />
                    VERZ
                </span>
                <span *ngIf="package.proof_of_delivery" class="aangetekend">
                    <i class="fal fa-check-square fa-3x"></i>
                    <br />
                    AANG
                </span>
                <span *ngIf="package.no_neighbours" class="no_neighbours">
                    <i class="fal fa-user-slash fa-3x"></i>
                    <br />
                    NBB
                </span>
                <span *ngIf="package.same_day" class="sameday">
                    <i class="fal fa-calendar-exclamation fa-3x"></i>
                    <br />
                    SAMD
                </span>
                <span *ngIf="package.evening_delivery" class="evening_delivery">
                    <i class="far fa-moon fa-3x"></i>
                    <br />
                    EVEN
                </span>
                <span *ngIf="package.premium" class="premium">
                    <i class="far fa-stars fa-3x"></i>
                    <br />
                    PREM
                </span>
            </div>
            <div class="packageInfo">
                <h2>
                    <button
                        *ngIf="package.concept"
                        id="editPackage"
                        (click)="editPackage()"
                        class="btn small"
                        tabindex="1"
                    >
                        <i style="color: var(--main-color)" class="far fa-pencil"></i>
                    </button>
                    <div class="copyClipboard" (click)="copyMessage(package.tracktrace)">
                        <i class="fad fa-paste fa-sm"></i>
                    </div>
                    <a
                        class="packageTrackTrace"
                        *ngIf="!package.concept"
                        href="{{ package.trackAndTraceUrl }}"
                        target="_blank"
                    >
                        {{ package.tracktrace }}
                        <i class="far fa-share fa-xs" data-fa-transform="up-2"></i
                    ></a>
                    <a class="packageTrackTrace" *ngIf="package.concept">
                        {{ package.tracktrace }}</a
                    >
                </h2>
                <table>
                    <tr>
                        <td>ID:</td>
                        <th>{{ package.id }}</th>
                    </tr>
                    <tr *ngIf="package.mark">
                        <td>Kenmerk:</td>
                        <th>{{ package.mark }}</th>
                    </tr>
                    <tr>
                        <td>Type:</td>
                        <th>{{ package.type | packageType }}</th>
                    </tr>
                    <tr>
                        <td>Externe code:</td>
                        <th>{{ externalReference | unknown : "Onbekend" }}</th>
                    </tr>
                    <tr>
                        <td>Gewicht:</td>
                        <th>{{ package.weight | weight | unknown : "Onbekend" }}</th>
                    </tr>
                    <tr *ngIf="package.size">
                        <td>lxbxh:</td>
                        <th>{{ package.size }}</th>
                    </tr>
                    <tr>
                        <td>Waarde:</td>
                        <th>
                            {{
                                value
                                    | currency : "EUR" : "symbol" : "4.2-2" : "nl"
                                    | unknown : "Onbekend"
                            }}
                        </th>
                    </tr>
                </table>
            </div>
            <div class="senderInfo">
                <ul>
                    <li>
                        <h4>Verzender</h4>
                    </li>
                    <li *ngIf="package.sender.companyName; else senderFullname">
                        {{ package.sender.companyName }}
                    </li>
                    <ng-template #senderFullname>
                        <li>{{ package.sender | fullName }}</li>
                    </ng-template>
                    <li>
                        {{ package.sender.streetname }} {{ package.sender.houseNumber }}
                        {{ package.sender.houseNumberExtra }}
                    </li>
                    <li>{{ package.sender.addressExtra }}</li>
                    <li>{{ package.sender.postcode }}</li>
                    <li>{{ package.sender.city }}</li>
                    <li>
                        <a href="tel:{{ package.sender.phone }}"> {{ package.sender.phone }}</a>
                    </li>
                    <li>
                        <a href="mailto:{{ package.sender.email }}">{{ package.sender.email }}</a>
                    </li>
                </ul>
            </div>
            <div class="recipientInfo">
                <ul>
                    <li>
                        <h4>Ontvanger</h4>
                    </li>
                    <li *ngIf="package.recipient.companyName; else recipientFullname">
                        {{ package.recipient.companyName }}
                    </li>
                    <ng-template #recipientFullname>
                        <li>{{ package.recipient | fullName }}</li>
                    </ng-template>
                    <li>
                        {{ package.recipient.streetname }}
                        {{ package.recipient.houseNumber }}
                        {{ package.recipient.houseNumberExtra }}
                    </li>
                    <li>{{ package.recipient.addressExtra }}</li>
                    <li>{{ package.recipient.postcode }}</li>
                    <li>{{ package.recipient.city }}</li>
                    <li>{{ translateCountry(package.recipient.country) }}</li>
                    <li>
                        <a href="tel:{{ package.recipient.phone }}">
                            {{ package.recipient.phone }}</a
                        >
                    </li>
                    <li>
                        <a href="mailto:{{ package.recipient.email }}">
                            {{ package.recipient.email }}</a
                        >
                    </li>
                </ul>
            </div>
            <div *ngIf="hasServicePoint()" class="recipientInfo">
                <ul>
                    <li>
                        <h4>Servicepunt Informatie</h4>
                    </li>
                    <li>{{ package.service_point.name }}</li>
                    <li>
                        {{ package.service_point.streetname }}
                        {{ package.service_point.housenr
                        }}{{ package.service_point.houseNumberExtra }}
                    </li>
                    <li>{{ package.service_point.postcode }}</li>
                    <li>{{ package.service_point.city }}</li>
                </ul>
            </div>
        </div>
        <!-- Package comment -->
        <div *ngIf="package.instructions" id="comment">
            <div class="row warning">
                <p><span>Opmerking:</span> {{ package.instructions }}</p>
            </div>
        </div>

        <div id="history">
            <h1>Details</h1>
            <ul class="tabs">
                <li
                    *ngFor="let tab of tabs"
                    (click)="showTab(tab)"
                    [class.activeTab]="activeTab === tab"
                >
                    {{ tab }}
                </li>
            </ul>
            <app-package-detail-history-tab [package]="package" *ngIf="activeTab === 'Status'">
            </app-package-detail-history-tab>
            <app-package-detail-bundle
                *ngIf="activeTab === 'Bundel'"
                [package]="package"
            ></app-package-detail-bundle>
        </div>

        <!-- Package Super User Part  -->
        <div *ngIf="isAllowedToPrint" id="super">
            <!-- Print Label -->
            <div class="row warning" *ngIf="canCreateRetour">
                <p>
                    <i class="warning_icon fas fa-exclamation-triangle"></i>
                    Met deze optie is het mogelijk om een retour label aan te maken.
                </p>
                <button (click)="retourLabelModal(package.tracktrace)" class="btn inverse">
                    RETOUR LABEL MAKEN
                </button>
            </div>
            <div class="row warning">
                <p>
                    <i class="warning_icon fas fa-exclamation-triangle"></i>
                    Met deze optie is het mogelijk om het label te printen.
                </p>
                <button (click)="labelModal(package.tracktrace)" class="btn inverse">
                    LABEL PRINTEN
                </button>
            </div>
            <div class="row warning">
                <p>
                    <i class="warning_icon fas fa-exclamation-triangle"></i>
                    Met deze optie is het mogelijk om dit pakket te kopieren naar een andere
                    vervoerder.
                </p>
                <button (click)="copyLabel(package.tracktrace)" class="btn inverse">
                    Kopie aanmaken
                </button>
            </div>

            <div class="row warning">
                <p>
                    <i class="warning_icon fas fa-exclamation-triangle"></i>
                    Is er een probleem met dit pakket. Meld het in het formulier
                </p>
                <button (click)="reportProblem()" class="btn inverse">MELD PROBLEEM</button>
            </div>

            <!-- Force Package -->
            <div
                class="row danger"
                *ngIf="isAllowedToForce && package.last_status !== 4; else denyForce"
            >
                <p>
                    <i class="danger_icon fas fa-exclamation-square"></i>
                    Het is <span>niet</span> terug te zien welk proces dit pakket doorlopen heeft.
                </p>
                <button class="btn inverse" (click)="forcePackage()">FORCEER BEZORGING</button>
            </div>
            <ng-template #denyForce>
                <div class="row disabled" *ngIf="isAllowedToForce">
                    <p>Dit pakket heeft al de status bezorgd.</p>
                    <button class="btn inverse">FORCEER BEZORGING</button>
                </div>
            </ng-template>

            <!-- Delete Package -->
            <div class="row danger" *ngIf="isAllowedToDelete">
                <p>
                    <i class="danger_icon fas fa-exclamation-square"></i>
                    <span>Verwijderen</span> is permanent. Weet je zeker dat je dit wilt doen?
                </p>
                <button class="btn inverse" (click)="deletePackage(package.tracktrace)">
                    VERWIJDER
                </button>
            </div>
        </div>
    </div>
</app-cp-page>
