import { Pipe, PipeTransform } from "@angular/core";
import { UserJSON } from "../../models/json/userJSON";
import { User } from "../../models/user.model";

@Pipe({ name: "shortUserAddress" })
export class ShortUserAddressPipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(userObj: UserJSON | User) {
        let user;
        if (userObj instanceof User) {
            user = userObj.toSaveJSON();
        } else {
            user = userObj;
        }
        if (user) {
            const addressParts = [user.postcode, user.housenr, user.housenr_extra, user.city];
            return addressParts.filter((x) => x && x.length > 0).join(" ");
        }
        return "";
    }
}
