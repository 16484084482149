import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Message } from "../../../models/message";
import { addMessage } from "../../../store/actions/message.actions";
import { BackendService } from "../../../services/backend.service";
import { User } from "../../../models/user.model";

@Component({
    selector: "app-return-labels-modal",
    templateUrl: "./return-labels-modal.component.html",
    styleUrls: ["./return-labels-modal.component.scss"],
})
export class ReturnLabelsModalComponent implements OnInit {
    busy = false;

    constructor(
        private backendService: BackendService,
        private store: Store<{ currentUser: User }>,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.store
            .select((state) => state.currentUser.email)
            .subscribe((email) => this.form.patchValue({ email }));
    }

    form = new FormGroup({
        email: new FormControl("", { nonNullable: true }),
        count: new FormControl(100, { nonNullable: true }),
        parcelType: new FormControl("NORMAL", { nonNullable: true }),
    });

    download() {
        const { email, count, parcelType } = this.form.getRawValue();
        this.busy = true;
        this.backendService.getReturnLabels(count, email, parcelType).subscribe({
            next: () => {
                const message = Message.createSuccessMessage(
                    "Labels worden gemaakt",
                    "De labels worden gemaakt en wanneer ze klaar zijn, worden ze naar je gemaild",
                );
                this.store.dispatch(addMessage({ message }));
                this.busy = false;
                this.router.navigateByUrl("/dashboard");
            },
            error: () => {
                const message = Message.createErrorMessage(
                    "Fout bij het downloaden",
                    `Er is iets fout gegaan bij het aanmaken van de retourlabels.
                    Probeer het later nog een keer of neem contact op met ViaTim`,
                );
                this.store.dispatch(addMessage({ message }));
                this.busy = false;
            },
        });
    }
}
