import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

import { getToken } from "../helpers/tokenHelper";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = getToken();
        if (token) {
            const newRequest = request.clone({
                setHeaders: {
                    Accept: "application/json",
                    "x-access-token": token,
                },
            });
            return next.handle(newRequest);
        }
        return next.handle(request);
    }
}
