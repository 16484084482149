import { Pipe, PipeTransform } from "@angular/core";
import { CENTS_PER_EURO } from "../constants";

const CENTS_SIZE = Math.log10(CENTS_PER_EURO);
const GROUP_SIZE = 3;

@Pipe({
    name: "priceAmount",
})
export class PriceAmountPipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(amount: number): string {
        if (amount != null) {
            const priceString = (Math.ceil(amount) / CENTS_PER_EURO).toFixed(CENTS_SIZE);
            const [euro, cents] = priceString.split(".");
            const splitEuros = [...euro].reduceRight((acc, value) => {
                if (value !== "-" && acc.length > 0 && acc.length % (GROUP_SIZE + 1) === 0) {
                    return `${value}.${acc}`;
                }
                return `${value}${acc}`;
            }, ",");
            return `\u20AC ${splitEuros}${cents}`;
        }
        return "-";
    }
}
