import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { loadCurrentUser, loadedCurrentUser } from "../actions/current-user.actions";
import { BackendService } from "../../services/backend.service";

@Injectable()
export class CurrentUserEffects {
    constructor(private actions$: Actions, private service: BackendService) {}

    loadCurrentUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCurrentUser),
            mergeMap(() =>
                this.service.getCurrentUser().pipe(map((user) => loadedCurrentUser({ user }))),
            ),
        ),
    );
}
