import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { BackendService } from "../services/backend.service";
import { User } from "../models/user.model";
import { E_USER_TYPES } from "../helpers/constants";
import { E_EXTERNAL_PARTIES } from "../helpers/constants/transporters";
import { AuthService } from "../services/auth.service";
import { ExternalDisplayName } from "../helpers/pipes/external-display-name.pipe";

@Component({
    selector: "app-account-management",
    templateUrl: "./account-management.component.html",
    styleUrls: ["./account-management.component.scss"],
})
export class AccountManagementComponent implements OnInit {
    user: User;

    transporters: string;

    constructor(
        private backend: BackendService,
        private router: Router,
        private auth: AuthService,
        private ExternalPipe: ExternalDisplayName,
    ) {}

    ngOnInit() {
        this.backend.getCurrentUser().subscribe(
            (result) => {
                this.user = result;
                this.getTransporterString(this.user.rights);
            },
            () => {
                this.router.navigateByUrl("/");
            },
        );
    }

    get canEdit() {
        return (
            this.auth.getAccountRole() == E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() == E_USER_TYPES.VIAWEBSHOP ||
            this.auth.getAccountRole() == E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() == E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    get userIsWebshop() {
        return this.auth.getAccountRole() == E_USER_TYPES.VIAWEBSHOP;
    }

    getTransporterString(rights: string[]) {
        rights.map((transporter) => {
            const transporterCapString = transporter.split("can_use_")[1];
            const transporterId = E_EXTERNAL_PARTIES[transporterCapString];
            if (transporterId) {
                this.transporters = this.transporters
                    ? `${this.transporters}, ${this.ExternalPipe.transform(transporterId)}`
                    : this.ExternalPipe.transform(transporterId);
            }
            return transporter;
        });
    }
}
