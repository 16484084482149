<app-cp-modal title="Bulk verwijderen">
    <form *ngIf="selectedPackages$ | async as selectedPackages">
        <div id="delete-packages">
            <h2>
                <span>Let op:</span> Weet je zeker dat je deze
                <span>{{ selectedPackages.length }}</span> pakketten wilt verwijderen? <br /><br />
                Pakketten die niet in concept staan zullen niet verwijderd worden.
            </h2>
            <div id="confirmOptions">
                <div class="close">
                    <button (click)="closeModal()" class="btn inverse" type="submit">
                        Annuleer
                    </button>
                </div>
                <div class="close">
                    <button (click)="deletePackages()" class="btn inverse" type="submit">
                        Verwijder de {{ selectedPackages.length }} pakketten
                    </button>
                </div>
            </div>
        </div>
    </form>
</app-cp-modal>
