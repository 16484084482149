import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { BackendService } from "../../services/backend.service";
import { Package } from "../../models/package.model";

@Component({
    selector: "app-retour-label-modal",
    templateUrl: "./retour-label-modal.component.html",
})
export class RetourLabelModalComponent implements OnInit, OnDestroy {
    private tracktrace: string;

    package: Package;

    private sub: Subscription;

    constructor(
        private backend: BackendService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        const urlraw = this.router.url.split("/")[2];
        const url = urlraw.split("(")[0];
        this.tracktrace = url;

        this.sub = this.route.params.subscribe(() => {
            this.backend.getPackageByTrackTrace(this.tracktrace).subscribe(
                (result) => {
                    this.package = result;
                    const { sender, recipient } = this.package;
                    this.package.sender = recipient;
                    this.package.recipient = sender;
                    this.package.service_point = null;
                    this.package.proof_of_delivery = false;
                    this.package.same_day = false;
                    this.package.evening_delivery = false;
                    this.package.premium = false;
                    this.package.no_neighbours = false;
                    this.package.id_check = false;
                    this.package.insured = false;
                    delete this.package.sender.id;
                    if (!this.package.instructions) {
                        delete this.package.instructions;
                    }
                },
                () => {
                    this.router.navigateByUrl("/packages");
                },
            );
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit() {
        this.backend.createPackage(this.package).subscribe(
            () => {
                this.router.navigateByUrl("/packages");
                this.backend.showMessage(true, "Retour pakket is aangemaakt!");
            },
            () => {
                this.backend.showMessage(
                    false,
                    "Foutcode 10b: Het aanmaken van een retourpakket is niet gelukt.",
                );
            },
        );
    }
}
