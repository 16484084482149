import { CARRIER_CODES, CarrierCode } from "../helpers/constants/transporters";
import { ExternalPriceTypeJSON } from "./json/externalPriceTypeJSON";

export class ExternalPriceType {
    constructor(public carrierCode: CarrierCode, public types: { type: number; name: string }[]) {}

    static fromJSON(json: ExternalPriceTypeJSON): ExternalPriceType {
        return new ExternalPriceType(
            CARRIER_CODES.find((x) => x === json.transporter),
            json.types,
        );
    }
}
