import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { parse } from "papaparse";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { isEqual, sortBy, parseInt, isNumber } from "lodash-es";
import { E_PACKAGE_TYPES, ERROR_TRANSLATION } from "../../helpers/constants";
import { BackendService } from "../../services/backend.service";
import { Message } from "../../models/message";
import { addMessage } from "../../store/actions/message.actions";
import { User } from "../../models/user.model";
import { ViaWebshop } from "../../models/viawebshop.model";
import { Package } from "../../models/package.model";

export enum CSV_UPLOAD_STATUS {
    NO_FILE_SELECTED,
    CSV_UPLOADING,
    CSV_UPLOADED,
    CSV_PARSED,
    ADDING_PARCELS,
    PARCELS_ADDED,
    CSV_UPLOAD_FAILED,
}

interface Error {
    message: string;
    row?: number;
}
const MAX_ROWS = 250;

type AccountOption = {
    id?: number;
    name: string;
};

type CSVLine = {
    mark: string;
    companyname: string;
    firstname: string;
    middlename: string;
    lastname: string;
    postcode: string;
    housenumber: string;
    housenumber_extra: string;
    address_extra: string;
    phone: string;
    email: string;
    type: string;
    id_check: string;
    proof_of_delivery: string;
    insured: string;
    no_neighbours: string;
    same_day: string;
    premium: string;
    evening_delivery: string;
    instructions: string;
    carrier: string;
    country: string;
    city: string;
    streetname: string;
};

@Component({
    selector: "app-csv-upload-modal",
    templateUrl: "./csv-upload-modal.component.html",
    styleUrls: ["./csv-upload-modal.component.scss"],
})
export class CsvUploadModalComponent {
    currentUser$: Observable<User> = this.store.select((state) => state.currentUser);

    get hasNoErrors(): boolean {
        return this.errors.length == 0;
    }

    public shownPackages: CSVLine[] = [];

    public errors: Error[] = [];

    public warnings: Error[] = [];

    public isThereData: boolean = false;

    public firstRowCheck: boolean = false;

    allowedExternals: string[] = [];

    accountOptions: AccountOption[] = [];

    accountId: number;

    public status: CSV_UPLOAD_STATUS = CSV_UPLOAD_STATUS.NO_FILE_SELECTED;

    public fileName = "";

    constructor(
        private backend: BackendService,
        private router: Router,
        private location: Location,
        private store: Store<{ messages: Message[]; currentUser: User }>,
    ) {
        this.currentUser$.subscribe((user: User) => {
            if (user) {
                this.accountId = user.id;
                this.accountOptions.push({ id: user.id, name: user.companyName });
                if (user instanceof ViaWebshop) {
                    this.accountOptions.push(...user.subAccounts);
                }
                this.allowedExternals = user.rights.map((x) =>
                    x
                        .replace("can_use_", "")
                        .replace("PARCELPRO", "POSTNL")
                        .replace("MYPARCEL", "POSTNL"),
                );
            }
        });
    }

    changeListener(event: Event): void {
        const target = <HTMLInputElement>event.target;
        const file = target.files[0];
        this.status = file ? CSV_UPLOAD_STATUS.CSV_UPLOADING : CSV_UPLOAD_STATUS.NO_FILE_SELECTED;
        if (this.status === CSV_UPLOAD_STATUS.CSV_UPLOADING) {
            this.readThis(event.target);
            this.fileName = file.name;
        }
    }

    addError(message: string, row?: number, isWarning = false) {
        if (isWarning) {
            this.warnings.push({ message, row });
        } else {
            this.errors.push({ message, row });
        }
    }

    isErrorRow(row: number) {
        return this.errors.some((error) => error.row === row);
    }

    isWarningRow(row: number) {
        return this.warnings.some((warning) => warning.row === row);
    }

    readThis(inputValue: any): void {
        this.errors = [];
        this.firstRowCheck = false;
        const file: File = inputValue.files[0];
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = () => {
            const input = <string>fileReader.result;
            const result = parse(input, {
                skipEmptyLines: true,
                header: true,
                dynamicTyping: false,
                transformHeader: (header: string) => {
                    const cleanedHeader = header.split("*").join("").toLowerCase();
                    switch (cleanedHeader) {
                        case "kenmerk":
                            return "mark";
                        case "bedrijfsnaam":
                            return "companyname";
                        case "voornaam":
                            return "firstname";
                        case "tussenvoegsel":
                            return "middlename";
                        case "achternaam":
                            return "lastname";
                        case "postcode":
                            return "postcode";
                        case "huisnummer":
                            return "housenumber";
                        case "toevoeging":
                            return "housenumber_extra";
                        case "2de adresregel":
                            return "address_extra";
                        case "telefoon":
                            return "phone";
                        case "email":
                            return "email";
                        case "soort zending":
                            return "type";
                        case "18+":
                            return "id_check";
                        case "18":
                            return "id_check";
                        case "aangetekend":
                            return "proof_of_delivery";
                        case "verzekerd":
                            return "insured";
                        case "niet bij buren":
                            return "no_neighbours";
                        case "extra luxe":
                            return "premium";
                        case "avondbezorging":
                            return "evening_delivery";
                        case "zelfde dag bezorging":
                            return "same_day";
                        case "opmerking":
                            return "instructions";
                        case "vervoerder":
                            return "carrier";
                        case "land":
                            return "country";
                        case "stad":
                            return "city";
                        case "straat":
                            return "streetname";
                        default:
                            return cleanedHeader;
                    }
                },
            });
            if (
                !(
                    result.meta.fields.includes("postcode") &&
                    result.meta.fields.includes("housenumber") &&
                    result.meta.fields.includes("lastname")
                )
            ) {
                this.addError("Het bestand bevat niet de juiste aanwijzingen");
                this.status = CSV_UPLOAD_STATUS.CSV_PARSED;
                return;
            }
            if (result.data.length > MAX_ROWS) {
                this.addError("Je CSV bestand mag niet meer dan 250 rijen bevatten");
            }
            this.shownPackages = <CSVLine[]>result.data;
            if (this.hasNoErrors) {
                this.shownPackages.forEach(this.checkCSVLine, this);
            }
            this.status = CSV_UPLOAD_STATUS.CSV_PARSED;
        };
        fileReader.readAsText(file);
    }

    checkCSVLine(csvObject: CSVLine, index: number) {
        if (csvObject.carrier && !this.allowedExternals.includes(csvObject.carrier)) {
            this.addError("U mag niet versturen met deze vervoerder", index);
        }
        if (!csvObject.country || csvObject.country === "NL") {
            if (!(csvObject.postcode && csvObject.housenumber)) {
                this.addError("Het adres is niet compleet", index);
            }
        } else if (
            !(csvObject.postcode && csvObject.housenumber && csvObject.streetname && csvObject.city)
        ) {
            this.addError("Het adres is niet compleet", index);
        }
        if (!csvObject.email) {
            this.addError("Het e-mailadres ontbreekt", index);
        }
    }

    get csvIsParsed(): boolean {
        return (
            this.status === CSV_UPLOAD_STATUS.CSV_PARSED ||
            this.status === CSV_UPLOAD_STATUS.ADDING_PARCELS ||
            this.status === CSV_UPLOAD_STATUS.CSV_UPLOAD_FAILED
        );
    }

    get inProgress(): boolean {
        return (
            this.status === CSV_UPLOAD_STATUS.CSV_UPLOADING ||
            this.status === CSV_UPLOAD_STATUS.ADDING_PARCELS
        );
    }

    get csvIsLoading(): boolean {
        return this.status === CSV_UPLOAD_STATUS.CSV_UPLOADING;
    }

    get defaultCarrier(): string {
        if (this.allowedExternals.includes("VIATIM")) {
            return "VIATIM";
        }
        return this.allowedExternals[0];
    }

    buildInput() {
        const toBoolean = (check: string) =>
            check != null && ["J", "Y", "X"].includes(check.toUpperCase());
        this.status = CSV_UPLOAD_STATUS.ADDING_PARCELS;
        if (this.hasNoErrors) {
            let csvJSONarray: any = [];
            csvJSONarray = this.shownPackages.map((line: CSVLine) => ({
                mark: line.mark,
                recipient: {
                    type: 0,
                    company_name: line.companyname,
                    firstname: line.firstname,
                    middlename: line.middlename,
                    lastname: line.lastname,
                    postcode: line.postcode,
                    housenr: line.housenumber,
                    housenr_extra: line.housenumber_extra,
                    address_extra: line.address_extra,
                    phone: line.phone,
                    email: line.email,
                    streetname: line.streetname,
                    city: line.city,
                    country: line.country || "NL",
                },
                sender_id: this.accountId,
                type: line.type
                    ? Number.parseInt(line.type, 10)
                    : E_PACKAGE_TYPES.STANDARD_PACKAGE_WEBSHOP,
                id_check: toBoolean(line.id_check),
                same_day: toBoolean(line.same_day),
                evening_delivery: toBoolean(line.evening_delivery),
                premium: toBoolean(line.premium),
                proof_of_delivery: toBoolean(line.proof_of_delivery),
                insured: toBoolean(line.insured),
                no_neighbours: toBoolean(line.no_neighbours),
                instructions: line.instructions,
                external_name: line.carrier || this.defaultCarrier,
            }));
            this.makePackageBulk(csvJSONarray);
        }
    }

    static colorRow(rowNumber: number, status: string) {
        const table = document.getElementById("table");
        const element = <HTMLTableRowElement>table.children[rowNumber];
        element.className = status;
    }

    close() {
        this.location.back();
    }

    makePackageBulk(packages: Array<Package>) {
        this.backend.createBulkPackage(packages).subscribe(
            (d) => {
                let validationErrorCount = 0;
                d.succesfullPackages.forEach((p) => {
                    p.package.validation_errors.errors.map((error) => {
                        let message =
                            ERROR_TRANSLATION[error.reason ? error.reason : error.error][
                                error.fields[0]
                            ];
                        if (!message) {
                            message =
                                ERROR_TRANSLATION[error.reason ? error.reason : error.error]
                                    .default;
                        }
                        if (!message) {
                            message = error.reason;
                        }
                        this.addError(`Error "${message}" op veld ${error.fields[0]}`, p.row, true);
                        validationErrorCount++;
                        return error;
                    });
                    CsvUploadModalComponent.colorRow(p.row + 1, "succeeded");
                });
                d.errorPackages.forEach((p) => {
                    if (p.validation_errors) {
                        p.validation_errors.map((error) => {
                            let message = ERROR_TRANSLATION[error.reason].default;
                            if (!message) {
                                message = ERROR_TRANSLATION[error.reason];
                            }
                            if (!message) {
                                message = ERROR_TRANSLATION.default;
                            }
                            const fields = error.fields.join(" & ");
                            this.addError(
                                `Pakket kon niet worden aangemaakt: Error "${message}" op veld${
                                    error.fields.length > 1 ? "en" : ""
                                } ${fields}`,
                                p.row,
                            );
                            return error;
                        });
                    } else {
                        this.addError("Pakket kon niet worden aangemaakt", p.row);
                    }

                    CsvUploadModalComponent.colorRow(p.row + 1, "errored");
                });
                this.status = CSV_UPLOAD_STATUS.PARCELS_ADDED;
                if (d.errorPackages.length === 0 && validationErrorCount === 0) {
                    this.router
                        .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
                        .then(() => this.router.navigate(["/packages"]));
                    const message = Message.createSuccessMessage(
                        "CSV Import",
                        "De pakketten uit de csv zijn succesvol geimporteerd",
                    );
                    this.store.dispatch(addMessage({ message }));
                }
            },
            (e) => {
                if (e.error.errors) {
                    e.error.errors.map((error) => {
                        const field = error.fields[0];

                        if (
                            isEqual(
                                sortBy(error.fields),
                                sortBy(["postcode", "housenr", "housenr_extra", "country"]),
                            )
                        ) {
                            this.addError(
                                "Fout bij het uploaden van de CSV: Een postcode-huisnummer combinatie is onjuist",
                            );
                            return this;
                        }
                        const fieldPath = field.split(".");
                        if (isNumber(parseInt(fieldPath[0]))) {
                            this.addError(
                                `Fout bij het uploaden van de CSV: Het veld ${fieldPath.pop()} is onjuist`,
                                parseInt(fieldPath[0]),
                            );
                            return this;
                        }
                        this.addError(
                            `Fout bij het uploaden van de CSV: Het veld ${field} is onjuist`,
                        );
                        return this;
                    });
                } else {
                    this.addError(`Fout bij het uploaden van de CSV : ${e.error}`);
                }
                this.status = CSV_UPLOAD_STATUS.CSV_UPLOAD_FAILED;
            },
        );
    }
}
