<form [formGroup]="filterForm" (ngSubmit)="onFilterChange()">
    <div class="form-row">
        <div class="form-group">
            <div class="input-group">
                <span class="input-group-text"><i class="far fa-search fa-sm"></i></span>
                <input
                    class="form-control"
                    type="search"
                    name="searchterm"
                    formControlName="searchTerm"
                    (keyup.enter)="onFilterChange()"
                    (search)="onFilterChange()"
                    placeholder="Track & Trace, Postcode,..."
                />
                <button
                    class="btn search-btn btn-outline-secondary form-control"
                    (click)="onFilterChange()"
                    type="button"
                >
                    Zoek
                </button>
                <button
                    class="btn btn-outline-secondary form-control"
                    (click)="toggleFilter()"
                    type="button"
                    [innerHTML]="toggleButtonText"
                ></button>
            </div>
        </div>
    </div>
    <div *ngIf="showAdvancedFilters" class="filter-group">
        <div class="row">
            <div class="form-group col">
                <div class="input-group">
                    <label class="input-group-text" for="isConceptFilter">In concept</label>
                    <select formControlName="isConcept" class="form-select" id="isConceptFilter">
                        <option [ngValue]="null">Beide</option>
                        <option [ngValue]="true">Ja</option>
                        <option [ngValue]="false">Nee</option>
                    </select>
                </div>
                <div class="input-group">
                    <label class="input-group-text" for="isReturnLabel">Retour</label>
                    <select formControlName="isReturnLabel" class="form-select" id="isReturnLabel">
                        <option [ngValue]="null">Beide</option>
                        <option [ngValue]="true">Ja</option>
                        <option [ngValue]="false">Nee</option>
                    </select>
                </div>
                <div class="input-group">
                    <label class="input-group-text" for="type">Type</label>
                    <select formControlName="type" class="form-select" id="type">
                        <option [ngValue]="null">Alle</option>
                        <option [ngValue]="5">0 - 15 KG</option>
                        <option [ngValue]="6">15 - 30 KG</option>
                        <option [ngValue]="7">&gt; 30 KG</option>
                        <option [ngValue]="8">Webshoppakket</option>
                        <option [ngValue]="9">Consumenten Pakket</option>
                    </select>
                </div>
                <div class="input-group">
                    <label class="input-group-text" for="dateFrom">Vanaf</label>
                    <input
                        class="form-control"
                        placeholder="dd-mm-yyyy"
                        name="dateFrom"
                        formControlName="dateFrom"
                        ngbDatepicker
                        #dateFrom="ngbDatepicker"
                    />
                    <button
                        class="btn btn-outline-secondary form-control"
                        (click)="dateFrom.toggle()"
                        type="button"
                    >
                        <i class="fas fa-calendar"></i>
                    </button>
                </div>
                <div class="input-group col">
                    <label class="input-group-text" for="dateTo">Tot</label>
                    <input
                        class="form-control"
                        placeholder="dd-mm-yyyy"
                        name="dateTo"
                        formControlName="dateTo"
                        ngbDatepicker
                        #dateTo="ngbDatepicker"
                    />
                    <button
                        class="btn btn-outline-secondary form-control"
                        (click)="dateTo.toggle()"
                        type="button"
                    >
                        <i class="fas fa-calendar"></i>
                    </button>
                </div>
            </div>
            <div class="form-group col">
                <div class="input-group">
                    <label class="input-group-text" for="carrierCode">Vervoerder</label>
                    <select formControlName="carrierCode" class="form-select" id="carrierCode">
                        <option [ngValue]="null">Alle</option>
                        <option value="VIATIM">ViaTim</option>
                        <option value="DHL">DHL</option>
                        <option value="POSTNL">PostNL</option>
                    </select>
                </div>
                <div class="row">
                    <legend class="col-form-label col-sm-2">Status</legend>
                    <div class="col-sm-9">
                        <div
                            class="form-check"
                            formArrayName="statuses"
                            *ngFor="let status of statusesFormArray.controls; let i = index"
                        >
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="{{ 'status' + i }}"
                                [formControlName]="i"
                            />
                            <label class="form-check-label" for="{{ 'status' + i }}">{{
                                packageStatuses[i].name
                            }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span style="color: var(--main-color)">{{ totalPackages }} resultaten</span>
</form>
