import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Location } from "@angular/common";
import { BackendService } from "../../services/backend.service";
import { ViaWebshop } from "../../models/viawebshop.model";
import { addMessage } from "../../store/actions/message.actions";
import { Message } from "../../models/message";
import { ValidationError } from "../../services/errors";
import { getValidationErrorMessages } from "src/app/helpers/errorHelper";

@Component({
    selector: "app-webshops-edit",
    templateUrl: "./webshops-edit.component.html",
    styleUrls: ["./webshops-edit.component.scss"],
})
export class WebshopsEditComponent implements OnInit {
    viaWebshop: ViaWebshop;

    errors: ValidationError[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private service: BackendService,
        private location: Location,
        private store: Store<void>,
    ) {}

    ngOnInit() {
        this.route.params.subscribe((params) => {
            const { id } = params;
            this.service.getViaWebshopById(id).subscribe((viaWebshop) => {
                this.viaWebshop = viaWebshop;
            });
        });
    }

    cancel() {
        this.location.back();
    }

    save(newViaWebshop: ViaWebshop) {
        this.service
            .editViawebshop(this.viaWebshop.id, newViaWebshop.toViawebshopUpdateJSON())
            .subscribe({
                complete: () => {
                    this.errors = [];
                    this.store.dispatch(
                        addMessage({
                            message: Message.createSuccessMessage(
                                "Succes",
                                "De Webshop is aangepast",
                            ),
                        }),
                    );
                    this.router.navigate(["/webshops/", this.viaWebshop.id]);
                },
                error: (error) => {
                    const reasonedErrors = error.error.errors;
                    if (reasonedErrors?.length > 0) {
                        this.errors = reasonedErrors;
                        const errorMessages = getValidationErrorMessages(this.errors);
                        errorMessages.forEach((errorMessage) => {
                            this.store.dispatch(addMessage({ message: errorMessage }));
                        });
                    } else {
                        this.store.dispatch(
                            addMessage({
                                message: Message.createErrorMessage(
                                    "Fout",
                                    `De  aanpassen geeft een fout ${error.error}`,
                                ),
                            }),
                        );
                    }
                },
            });
    }
}
