import { Input, Directive, ElementRef, Renderer2, OnChanges } from "@angular/core";
import { isEqual } from "lodash-es";
import { getErrorMessages } from "../helpers/errorHelper";
import { ValidationError } from "../services/errors";

@Directive({
    selector: "[appInlineError]",
})
export class InlineErrorDirective implements OnChanges {
    @Input("appInlineError") input: {
        errors: ValidationError[];
        prefix?: string;
    };

    @Input() fieldName: string;

    @Input() formControlName: string;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnChanges() {
        if (this.errorMessages && this.errorMessages.length > 0) {
            if (this.renderer.nextSibling(this.elementRef.nativeElement)) {
                this.renderer.removeChild(
                    this.elementRef.nativeElement,
                    this.renderer.nextSibling(this.elementRef.nativeElement),
                );
            }
            const error = this.renderer.createElement("div");
            error.setAttribute("class", "invalid-feedback");
            this.renderer.parentNode(this.elementRef.nativeElement).appendChild(error);
            error.appendChild(this.renderer.createText(this.errorMessages.join(",")));
            this.renderer.addClass(this.elementRef.nativeElement, "is-invalid");
        } else {
            this.renderer.removeClass(this.elementRef.nativeElement, "is-invalid");
        }
    }

    get errorMessages() {
        return getErrorMessages(this.error);
    }

    get error() {
        if (this.input && this.input.errors && this.input.errors.length > 0) {
            const errorName = this.fieldName
                ? this.fieldName.split(",")
                : [`${this.input.prefix}.${this.formControlName}`];
            return this.input.errors.filter((e) => {
                if (isEqual(e.fields, errorName)) {
                    return e;
                }
                return null;
            });
        }
        return null;
    }
}
