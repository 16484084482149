import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";

import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ControlPanelModule } from "../control-panel/control-panel.module";
import { PackagesRoutingModule } from "./packages-routing.module";
import { PackagesService } from "./packages.service";

import { PackagesComponent } from "./packages.component";
import { PackageTableComponent } from "./package-table/package-table.component";
import { PackageDetailComponent } from "./package-detail/package-detail.component";
import { PackageTypePipe } from "./pipes/package-type.pipe";
import { StatusTextPipe } from "./pipes/status-text.pipe";
import { ShortUserAddressPipe } from "./pipes/user-short-address.pipe";
import { UserAddressPipe } from "./pipes/user-address.pipe";
import { CurrentStatusTextPipe } from "./pipes/current-status-text.pipe";
import { DeletePackageModalComponent } from "./delete-package-modal/delete-package-modal.component";
import { PrintLabelModalComponent } from "./print-label-modal/print-label-modal.component";
import { ForcePackageComponent } from "./force-package/force-package.component";
import { CsvUploadModalComponent } from "./csv-upload-modal/csv-upload-modal.component";
import { BulkPrintModalComponent } from "./bulk-print-modal/bulk-print-modal.component";
import { RetourLabelModalComponent } from "./retour-label-modal/retour-label-modal.component";
import { CopyLabelModalComponent } from "./copy-label-modal/copy-label-modal.component";

import { PackageDetailHistoryTabComponent } from "./package-detail/package-detail-tabs/package-detail-history-tab/package-detail-history-tab.component";

// Shared Module
import { SharedModule } from "../shared/shared.module";
import { BulkDeleteModalComponent } from "./bulk-delete-modal/bulk-delete-modal.component";
import { NewPackageComponent } from "./new-package/new-package.component";
import { ChooseParcelpointComponent } from "./choose-parcelpoint/choose-parcelpoint.component";
import { AddressFormComponent } from "./new-package/address-form/address-form.component";
import { AddressCellComponent } from "./package-table/address-cell/address-cell.component";
import { PackageFilterComponent } from "./package-table/package-filter/package-filter-component";
import { PackageWeightPipe } from "./pipes/weight.pipe";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        ControlPanelModule,
        PackagesRoutingModule,
        LoadingBarHttpClientModule,
        SharedModule,
        NgbDatepickerModule,
    ],
    declarations: [
        PackageTypePipe,
        PackageWeightPipe,
        StatusTextPipe,
        UserAddressPipe,
        ShortUserAddressPipe,
        CurrentStatusTextPipe,
        PackagesComponent,
        PackageDetailComponent,
        PackageTableComponent,
        DeletePackageModalComponent,
        PrintLabelModalComponent,
        ForcePackageComponent,
        CsvUploadModalComponent,
        BulkPrintModalComponent,
        RetourLabelModalComponent,
        CopyLabelModalComponent,
        PackageDetailHistoryTabComponent,
        BulkDeleteModalComponent,
        NewPackageComponent,
        ChooseParcelpointComponent,
        AddressFormComponent,
        AddressCellComponent,
        PackageFilterComponent,
    ],
    providers: [PackagesService],
})
export class PackagesModule {}
