<app-cp-modal title="CSV Import" route="packages">
    <div *ngIf="!csvIsParsed; else showData">
        <p>
            Selecteer een CSV bestand met daarin:
            <br />
        </p>
        <p class="req">
            Kenmerk, Bedrijfsnaam, Voornaam, Tussenvoegsel, Achternaam<i class="fas fa-asterisk"></i
            >, Postcode<i class="fas fa-asterisk"></i>, Huisnummer<i class="fas fa-asterisk"></i>,
            Toevoeging,
            <br />
            2de adresregel, Straat, Stad, Land, Telefoon, Email<i class="fas fa-asterisk"></i>,
            Soort zending, 18+, Aangetekend, Verzekerd, Niet bij buren, Zelfde dag bezorging, Extra
            luxe, Avond bezorging, Opmerking, Vervoerder
            <br />
            <b>(<i class="fas fa-asterisk"></i> = verplicht)</b>.
        </p>
        <p>
            <b>Let op:</b> De eerste regel van je CSV bestand wordt niet mee genomen in de upload!
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            In deze rij staan aanwijzingen wat in welke kolom dient te staan.
            <br />
            <br />
            Het CSV bestand mag niet meer dan <b>250</b> items bevatten.
            <br />
            <br />
            "Soort zending" is niet verplicht, maar als je het gebruikt moet het een van deze
            nummers zijn:
            <br />
            <span>2</span> = Brievenbuspakket
            <br />
            <span>5</span> = Pakket tussen de 0 - 15kg
            <br />
            <span>6</span> = Pakket tussen 15 - 30kg
        </p>
        <p>
            Zie hier de
            <a
                href="https://viatim-csv-examples.s3.eu-central-1.amazonaws.com/voorbeeld-csv-15112021.csv"
                >voorbeeld CSV</a
            >
            van ViaTim. Het CSV bestand kan door komma's of door puntkomma's gescheiden worden.
        </p>
    </div>

    <div *ngIf="inProgress" id="loading">
        <div class="mul7">
            <div class="mul7circ m7c1"></div>
            <div class="mul7circ m7c2"></div>
            <div class="mul7circ m7c3"></div>
            <div class="mul7circ m7c4"></div>
            <div class="mul7circ m7c5"></div>
            <div class="mul7circ m7c6"></div>
            <div class="mul7circ m7c7"></div>
            <div class="mul7circ m7c8"></div>
            <div class="mul7circ m7c9"></div>
            <div class="mul7circ m7c10"></div>
            <div class="mul7circ m7c11"></div>
            <div class="mul7circ m7c12"></div>
        </div>
        <h1 id="loadingText">Je CSV wordt verwerkt!</h1>
    </div>

    <ng-template #showData>
        <div class="container">
            <p class="error" *ngIf="errors.length > 0">
                <b>Hier onder geeft ons systeem aan wat er fout is aan het pakketje.</b><br />
                <span *ngFor="let error of errors">
                    <span *ngIf="error.row">Rij {{ error.row + 1 }}: </span>
                    {{ error.message }}<br />
                </span>
            </p>
            <p class="warning" *ngIf="warnings.length > 0">
                <b
                    >LET OP! Een aantal pakketten hebben validatie fouten maar zijn toch
                    toegevoegt!</b
                ><br />
                <span *ngFor="let warning of warnings">
                    <span *ngIf="warning.row">Rij {{ warning.row + 1 }}: </span>
                    {{ warning.message }}<br />
                </span>
            </p>
        </div>

        <div *ngIf="accountOptions.length > 1">
            <label>Wie is de afzender van deze pakketten?</label>
            <select [(ngModel)]="accountId">
                <option *ngFor="let account of accountOptions" [ngValue]="account.id">
                    {{ account.name }}
                </option>
            </select>
        </div>
        <p>
            <b>Let op:</b> De eerste regel van je CSV bestand wordt niet meegenomen in de upload en
            wordt ook niet getoond.
            <br />
            Een overzicht van alle pakketjes:
        </p>
        <table id="table" [class.blurthis]="csvIsLoading">
            <tr>
                <th>#</th>
                <th>Kenmerk</th>
                <th>Bedrijfsnaam</th>
                <th>Voornaam</th>
                <th>Tussenvoegsel</th>
                <th>Achternaam<i class="fas fa-asterisk"></i></th>
                <th>Postcode<i class="fas fa-asterisk"></i></th>
                <th>Straatnaam</th>
                <th>Huisnummer<i class="fas fa-asterisk"></i></th>
                <th>Toevoeging</th>
                <th>2de adresregel</th>
                <th>Stad</th>
                <th>Land</th>
                <th>Telefoonnummer</th>
                <th>Email<i class="fas fa-asterisk"></i></th>
                <th>Soort zending</th>
                <th>18+</th>
                <th>Aangetekend</th>
                <th>Verzekerd</th>
                <th>Niet bij buren</th>
                <th>Zelfde dag bezorging</th>
                <th>Extra luxe</th>
                <th>Avond bezorging</th>
                <th>Opmerking</th>
                <th>Vervoerder</th>
            </tr>
            <tr
                *ngFor="let dataRow of shownPackages; let i = index"
                [ngClass]="{ errorrow: isErrorRow(i), warningrow: isWarningRow(i) }"
            >
                <td>{{ i + 1 }}</td>
                <th>{{ dataRow.mark }}</th>
                <th>{{ dataRow.companyname }}</th>
                <th>{{ dataRow.firstname }}</th>
                <th>{{ dataRow.middlename }}</th>
                <th>{{ dataRow.lastname }}<i class="fas fa-asterisk"></i></th>
                <th>{{ dataRow.postcode }}<i class="fas fa-asterisk"></i></th>
                <th>{{ dataRow.streetname }}</th>
                <th>{{ dataRow.housenumber }}<i class="fas fa-asterisk"></i></th>
                <th>{{ dataRow.housenumber_extra }}</th>
                <th>{{ dataRow.address_extra }}</th>
                <th>{{ dataRow.city }}</th>
                <th>{{ dataRow.country }}</th>
                <th>{{ dataRow.phone }}</th>
                <th>{{ dataRow.email }}<i class="fas fa-asterisk"></i></th>
                <th>{{ dataRow.type }}</th>
                <th>{{ dataRow.id_check }}</th>
                <th>{{ dataRow.proof_of_delivery }}</th>
                <th>{{ dataRow.insured }}</th>
                <th>{{ dataRow.no_neighbours }}</th>
                <th>{{ dataRow.same_day }}</th>
                <th>{{ dataRow.premium }}</th>
                <th>{{ dataRow.evening_delivery }}</th>
                <th>{{ dataRow.instructions }}</th>
                <th>{{ dataRow.carrier }}</th>
            </tr>
        </table>
    </ng-template>

    <form #packageForm="ngForm">
        <div>
            <label (change)="changeListener($event)" class="form-file">
                <span *ngIf="!fileName"
                    ><i class="fas fa-arrow-alt-from-bottom fa-lg"></i> KIES EEN BESTAND</span
                >
                <span *ngIf="fileName"
                    ><i class="fas fa-arrow-alt-from-bottom fa-lg"></i>{{ fileName }}</span
                >
                <input type="file" accept=".csv" value="" />
            </label>
            <button
                *ngIf="csvIsParsed"
                class="btn inverse"
                type="submit"
                (click)="buildInput()"
                [disabled]="!hasNoErrors"
            >
                TOEVOEGEN
            </button>
            <button
                *ngIf="status === 4 || status === 3"
                class="btn inverse"
                type="button"
                (click)="close()"
            >
                SLUITEN
            </button>
        </div>
    </form>
</app-cp-modal>
