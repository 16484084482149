import { Component, OnDestroy, OnInit } from "@angular/core";
import { sortBy } from "lodash-es";
import { ActivatedRoute } from "@angular/router";
import { InvoiceService, TransporterInvoiceItemFilter } from "../../services/invoices.service";
import { PriceCategoryService } from "../../services/priceCategory.service";
import { ExternalPriceType } from "../../models/externalPriceType";
import { TablePage } from "../../models/tablePage.model";
import { ViaWebshop } from "../../models/viawebshop.model";
import { BackendService } from "../../services/backend.service";
import { TransporterInvoiceItem } from "../../models/transporterInvoiceItem";
import { Subscription } from "rxjs";

const NUMBER_OF_RESULTS = 20;
@Component({
    selector: "app-compare-prices",
    templateUrl: "./compare-prices.component.html",
    styleUrls: ["./compare-prices.component.scss"],
})
export class ComparePricesComponent implements OnInit, OnDestroy {
    file: File;

    input: string;

    items: TablePage<TransporterInvoiceItem>;

    page = 0;

    externalPriceTypes: ExternalPriceType[] = [];

    externalPriceTypeFilter = "";

    webshopIdFilter: number | null = null;

    webshops: ViaWebshop[];

    externalId: number;

    invoiceId: string;

    selectedRow: TransporterInvoiceItem | null = null;

    subscriptions: Subscription[] = [];

    constructor(
        private service: PriceCategoryService,
        private backendService: BackendService,
        private route: ActivatedRoute,
        private invoiceService: InvoiceService,
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.service.getExternalPriceTypes().subscribe((externalPriceTypes) => {
                this.externalPriceTypes = externalPriceTypes;
            }),
        );
        this.subscriptions.push(
            this.backendService.getAllWebshops().subscribe({
                next: (webshops) => (this.webshops = sortBy(webshops, "displayName")),
            }),
        );
        this.subscriptions.push(
            this.route.paramMap.subscribe({
                next: (params) => {
                    this.externalId = Number.parseInt(params.get("externalId"));
                    this.invoiceId = params.get("invoiceId");
                    this.updateInvoices();
                },
            }),
        );
    }

    updateInvoices() {
        const filter: TransporterInvoiceItemFilter = {
            externalId: this.externalId,
            externalInvoiceId: this.invoiceId,
            start: this.page * NUMBER_OF_RESULTS,
            limit: NUMBER_OF_RESULTS,
            ownerId: this.webshopIdFilter,
        };
        if (this.externalPriceTypeFilter) {
            const [carrierCode, typeId] = this.externalPriceTypeFilter.split("|");
            filter.carrierCode = carrierCode;
            filter.externalPriceTypeId = Number.parseInt(typeId);
        }

        return this.invoiceService.comparePrices(filter).subscribe({
            next: (result) => {
                this.items = result;
            },
        });
    }

    get externalPriceTypeOptions() {
        if (!this.externalPriceTypes) {
            return [];
        }
        return this.externalPriceTypes.reduce((acc, value) => {
            const newValues = value.types.map((v) => ({
                id: `${value.carrierCode}|${v.type}`,
                name: `${value.carrierCode} - ${v.name}`,
            }));
            return [...acc, ...newValues];
        }, []);
    }

    downloadPackages() {
        console.log("download packages!!!");
    }

    editable(row: any) {
        return !row.webshop;
    }

    getExternalPriceType(parcel: TransporterInvoiceItem) {
        const carrierTypes = this.externalPriceTypes.find(
            (pt) => pt.carrierCode === parcel.carrierCode,
        );
        if (carrierTypes && carrierTypes.types) {
            return carrierTypes.types.find((pt) => pt.type === parcel.priceType).name;
        }
        return "";
    }

    gotoPage(page: number) {
        this.page = page;
        this.updateInvoices();
    }

    selectRow(row: TransporterInvoiceItem) {
        this.selectedRow = row;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
