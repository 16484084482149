import { createAction, props } from "@ngrx/store";
import { PackageFilter } from "../../models/packageFilter";
import { Package } from "../../models/package.model";
import { TablePage } from "../../models/tablePage.model";

export const refreshPackages = createAction("[Packages Component] Refresh Packages");

export const loadPackages = createAction(
    "[Packages Component] Load Packages",
    props<{ filter: PackageFilter }>(),
);

export const loadedPackages = createAction(
    "[Packages Component] Loaded Packages",
    props<{ packages: TablePage<Package> }>(),
);
