import { Location } from "@angular/common";
import { Store } from "@ngrx/store";
import { Component } from "@angular/core";
import { v4 as uuidv4 } from "uuid";
import { first, map, switchMap } from "rxjs/operators";
import * as FileSaver from "file-saver-es";
import { Observable } from "rxjs";
import { BackendService } from "../../services/backend.service";
import { AuthService } from "../../services/auth.service";
import { currentDateString } from "../../helpers/textHelper";
import { Message } from "../../models/message";
import { addMessage } from "../../store/actions/message.actions";
import { refreshPackages } from "../../store/actions/packages.actions";
import { clearSelectedPackages } from "../../store/actions/selected-packages.actions";
import { FULL_PERCENTAGES } from "../../helpers/constants";

@Component({
    selector: "app-bulk-print-modal",
    templateUrl: "./bulk-print-modal.component.html",
    styleUrls: ["./bulk-print-modal.component.scss"],
})
export class BulkPrintModalComponent {
    failures = [];

    public showLoading: boolean = false;

    public counter: number = 0;

    public total: number = 0;

    public statusText = "Je PDF wordt gegenereerd!";

    selectedPackages$: Observable<string[]> = this.store.select((state) => state.selectedPackages);

    constructor(
        private backend: BackendService,
        private store: Store<{ selectedPackages: string[] }>,
        private location: Location,
        private auth: AuthService,
    ) {}

    submit() {
        this.counter = 0;
        this.showLoading = true;
        const uuid = uuidv4();
        this.selectedPackages$.pipe(first()).subscribe((selectedPackages) => {
            this.total = selectedPackages.length;
            const jobs = selectedPackages.reduce(
                (acc: Observable<{ parcel: string; success: boolean }[]>, parcel: string) => {
                    if (!acc) {
                        return this.backend
                            .addBulkLabel(parcel, uuid)
                            .pipe(map((success) => [{ parcel, success }]));
                    }
                    return acc.pipe(
                        switchMap((result: { parcel: string; success: boolean }[]) => {
                            this.counter++;
                            return this.backend
                                .addBulkLabel(parcel, uuid)
                                .pipe(
                                    map((success: boolean) => result.concat([{ parcel, success }])),
                                );
                        }),
                    );
                },
                null,
            );
            jobs.subscribe((result) => {
                this.failures = result
                    .filter(({ success }) => !success)
                    .map(({ parcel }) => parcel);
                if (this.failures.length < selectedPackages.length) {
                    this.downloadFile(uuid);
                } else {
                    const message = Message.createErrorMessage(
                        "Printen van labels is mislukt",
                        "Het printen van alle labels is mislukt",
                    );
                    this.store.dispatch(addMessage({ message }));
                }
            });
        });
    }

    get progressPercentage() {
        if (this.total) {
            return Math.round((this.counter / this.total) * FULL_PERCENTAGES);
        }
        return 0;
    }

    downloadFile(uuid: string) {
        this.backend.downloadBulkLabel(uuid).subscribe(
            (d) => {
                const blob = new Blob([d], { type: "application/pdf" });
                const fileName = `${this.auth.getOwnUserName()}-${currentDateString()}`;
                FileSaver.saveAs(blob, `ViaTim_Bulklabel_${fileName}.pdf`);
                this.statusText = "Je PDF is gedownload!";
                this.showLoading = false;
                this.store.dispatch(clearSelectedPackages());
                if (this.failures.length === 0) {
                    const message = Message.createSuccessMessage(
                        "Labels geprint",
                        "Labels zijn geprint en pakketten zijn definitief.",
                    );
                    this.closeModal(message);
                }
            },
            () => {
                const message = Message.createErrorMessage(
                    "Ophalen labels mislukt",
                    "Het downloaden van de labels is mislukt",
                );
                this.closeModal(message);
            },
        );
    }

    closeModal(message: Message = null) {
        if (message) {
            this.store.dispatch(addMessage({ message }));
        }
        this.store.dispatch(refreshPackages());
        this.location.back();
    }
}
