import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "../models/user.model";
import { AuthService } from "../services/auth.service";
import { BackendService } from "../services/backend.service";
import { ViaBode } from "../models/viabode.model";
import { E_USER_TYPES } from "../helpers/constants";

@Component({
    selector: "app-create-user",
    templateUrl: "./create-user.component.html",
    styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent {
    viabode: ViaBode = ViaBode.createEmpty();

    user = User.createEmpty();

    userType: number = 0;

    userTypeOptions = [
        { id: 0, name: "Kies een gebruiker", disabled: true },
        { id: 2, name: "ViaBode" },
    ];

    selectedType: number = 0;

    constructor(
        private auth: AuthService,
        private backend: BackendService,
        private cdRef: ChangeDetectorRef,
        private router: Router,
    ) {}

    get canCreateViaBode() {
        return (
            this.auth.getAccountRole() === E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() === E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    onSubmit(): void {
        const p = this.userType;

        if (p == E_USER_TYPES.VIABODE) {
            this.backend.createViabode(this.viabode).subscribe({
                complete: () => {
                    this.router.navigateByUrl("/");
                    this.backend.showMessage(true, `ViaBode is aangemaakt!`);
                },
                error: () => {
                    this.backend.showMessage(
                        false,
                        "Foutcode 9a: Het aanmaken van een ViaBode account is niet gelukt.",
                    );
                },
            });
        }
    }

    onUserTypeChange() {
        this.cdRef.detectChanges();
    }

    selectType(_selectedType: number) {
        this.selectedType = _selectedType;
    }
}
