import { uniq, xor } from "lodash-es";
import { Action, createReducer, on } from "@ngrx/store";
import * as SelectedPackagesActions from "../actions/selected-packages.actions";

export const initialState: string[] = [];

const selectedPackagesReducer = createReducer(
    initialState,
    on(SelectedPackagesActions.clearSelectedPackages, () => []),
    on(SelectedPackagesActions.selectPackage, (state, props) => uniq([...state, props.tracktrace])),
    on(SelectedPackagesActions.toggleSelectPackage, (state, props) =>
        xor(state, [props.tracktrace]),
    ),
    on(SelectedPackagesActions.unSelectPackage, (state, props) =>
        state.filter((p) => p !== props.tracktrace),
    ),
);

export function reducer(state: string[], action: Action) {
    return selectedPackagesReducer(state, action);
}
