import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";
import { PackageFilter } from "../../models/packageFilter";
import { PackagesService } from "../../packages/packages.service";
import { loadedPackages, loadPackages, refreshPackages } from "../actions/packages.actions";

@Injectable()
export class PackagesEffects {
    packageFilter$: Observable<PackageFilter> = this.store.select((state) => state.packageFilter);

    constructor(
        private actions$: Actions,
        private store: Store<{ packageFilter: PackageFilter }>,
        private packagesService: PackagesService,
    ) {}

    loadPackages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPackages),
            mergeMap((action) =>
                this.packagesService
                    .getPackages(action.filter)
                    .pipe(map((packages) => loadedPackages({ packages }))),
            ),
        ),
    );

    refreshPackages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(refreshPackages),
            withLatestFrom(this.packageFilter$),
            mergeMap(([, filter]) =>
                this.packagesService
                    .getPackages(filter)
                    .pipe(map((packages) => loadedPackages({ packages }))),
            ),
        ),
    );
}
