import { Component, Input, Output, EventEmitter } from "@angular/core";
import { TablePage } from "../../models/tablePage.model";

const MAX_NUMBER_OF_PAGES = 10;
const CURRENT_PAGE_PADDING = 2;
export const DEFAULT_PAGE_SIZE = 20;

@Component({
    selector: "app-pagination",
    templateUrl: "./pagination.component.html",
    styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {
    @Input()
    tablePage: TablePage<any>;

    @Output()
    onPageChange = new EventEmitter<number>();

    get navigationPageNumbers() {
        let result = [];
        if (this.tablePage.numberOfPages <= MAX_NUMBER_OF_PAGES) {
            for (let index = 0; index < this.tablePage.numberOfPages; index++) {
                result.push(index);
            }
        } else {
            const firstPage = Math.max(0, this.tablePage.pageNumber - CURRENT_PAGE_PADDING);
            const lastPage = Math.min(
                this.tablePage.pageNumber + CURRENT_PAGE_PADDING + 1,
                this.tablePage.numberOfPages - 1,
            );
            for (let index = firstPage; index < lastPage; index++) {
                result.push(index);
            }
            if (!result.includes(0)) {
                if (!result.includes(1)) {
                    result = [null, ...result];
                }
                result = [0, ...result];
            }
            if (!result.includes(this.tablePage.numberOfPages - 1)) {
                if (!result.includes(this.tablePage.numberOfPages - 2)) {
                    result.push(null);
                }
                result.push(this.tablePage.numberOfPages - 1);
            }
        }
        return result;
    }

    gotoPage(page: number) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.onPageChange.emit(page);
    }
}
