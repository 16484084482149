import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ImportPricesComponent } from "./import-prices/import-prices.component";
import { AuthGuard } from "../services/auth.guard";
import { PriceListComponent } from "./price-list/price-list.component";
import { ImportTransporterInvoiceComponent } from "./import-transporter-invoice/import-transporter-invoice.component";
import { ImportWorksheetComponent } from "./import-worksheet/import-worksheet.component";
import { ImportPickupsComponent } from "./import-pickups/import-pickups.component";
import { ComparePricesComponent } from "./compare-prices/compare-prices.component";

const routes: Routes = [
    {
        path: "invoices",
        component: PriceListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "invoices/prices-import",
        component: ImportPricesComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "invoices/worksheet-import",
        component: ImportWorksheetComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "invoices/transporter-import",
        component: ImportTransporterInvoiceComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "invoices/pickup-import",
        component: ImportPickupsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "invoices/compare/:externalId/:invoiceId",
        component: ComparePricesComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InvoicesRoutingModule {}
