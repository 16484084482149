import { Component } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { environment } from "../environments/environment";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent {
    public constructor(private meta: Meta) {
        this.meta.addTag({ name: "environment", content: environment.envName });
        this.meta.addTag({ name: "version", content: environment.version });
    }
}
