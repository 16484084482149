import { Action, createReducer, on } from "@ngrx/store";
import * as ProgressActions from "../actions/progress.actions";

export type Progress = { total: number; count: number; busy: boolean };

export const initialState: Progress = {
    total: 0,
    count: 0,
    busy: false,
};

const messageReducer = createReducer(
    initialState,
    on(ProgressActions.startProgress, (state, props) => ({
        ...state,
        total: props.total,
        busy: true,
    })),
    on(ProgressActions.updateProgress, (state, props) => ({
        ...state,
        count: props.count,
        busy: true,
    })),
    on(ProgressActions.finishProgress, (state) => ({ ...state, busy: false })),
);

export function reducer(state: Progress, action: Action) {
    return messageReducer(state, action);
}
