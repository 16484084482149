<app-cp-page>
    <div class="create-contact-btn">
        <button (click)="createContact()" class="btn add" tabindex="1">
            <span><i class="fa fa-plus fa-lg"></i></span>Contactpersoon
        </button>
    </div>
    <span *ngIf="contacts">
        <div class="address-book">
            <div class="address-book__list">
                <h1 class="address-book__list--title">Contacten</h1>
                <div class="address-book__list--searchbar">
                    <i class="address-book__list--searchbar__icon far fa-search fa-sm"></i>
                    <input
                        class="address-book__list--searchbar__input"
                        #search
                        type="search"
                        [(ngModel)]="searchTerm"
                        (change)="onChangeSearchTerm()"
                        placeholder="Zoeken"
                    />
                </div>

                <main class="address-book__list--main">
                    <ul class="address-book__list--main__key">
                        <span
                            class="address-book__list--main__key--contacts"
                            *ngFor="let contact of displayedContacts"
                        >
                            <li
                                class="address-book__list--main__key--contacts__name"
                                (click)="showContactDetails(contact)"
                            >
                                <b>{{ contact.lastname }}</b
                                >, {{ contact.firstname }} {{ contact.middlename }}
                                <span
                                    class="address-book__list--main__key--contacts__name--icon"
                                    (click)="deleteContact(contact)"
                                >
                                    <i
                                        class="address-book__list--main__key--contacts__name--icon__close-btn fas fa-times-circle"
                                        data-fa-transform="up-1"
                                    ></i>
                                </span>
                            </li>
                        </span>
                    </ul>
                </main>
            </div>

            <div *ngIf="details" class="address-book__details">
                <h1 class="address-book__details--title">Details</h1>
                <aside class="address-book__details--icon-user">
                    <i class="fal fa-user fa-4x"></i>
                </aside>
                <aside class="address-book__details--data-user">
                    <h2>{{ details | fullName }}</h2>
                    <div class="fullwidth" *ngIf="details.companyName !== ''">
                        <span class="fullwidth__left">Bedrijf</span>
                        <span class="fullwidth__right">{{ details.companyName }}</span>
                    </div>
                    <div class="fullwidth">
                        <span class="fullwidth__left">Adres</span>
                        <span class="fullwidth__right"
                            >{{ details.streetname }} {{ details.houseNumber
                            }}{{ details.houseNumberExtra
                            }}<br *ngIf="details.addressExtra !== ''" />{{ details.addressExtra
                            }}<br />{{ details.postcode }}, {{ details.city }}<br />{{
                                details.country
                            }}</span
                        >
                    </div>
                    <div class="fullwidth" *ngIf="details.phone !== '' || details.email !== ''">
                        <span class="fullwidth__left" *ngIf="details.phone !== ''">Telefoon</span>
                        <span class="fullwidth__right" *ngIf="details.phone !== ''">
                            <a class="phone-link" href="tel:{{ details.phone }}">{{
                                details.phone
                            }}</a>
                        </span>
                        <br *ngIf="details.phone !== ''" />

                        <span class="fullwidth__left" *ngIf="details.email !== ''">E-mail</span>
                        <span class="fullwidth__right" *ngIf="details.email !== ''">
                            <a class="email-link" href="mailto:{{ details.email }}">{{
                                details.email
                            }}</a>
                        </span>
                    </div>
                    <div class="fullwidth">
                        <span class="fullwidth__left">Datum aangemaakt</span
                        ><span class="fullwidth__right">{{ details.dateCreated | fromDate }}</span>
                    </div>
                </aside>
            </div>
        </div>
    </span>

    <app-modal *ngIf="contactForDelete" title="Contact Verwijderen" (close)="closeDialog()">
        <form>
            <div class="delete-contact">
                <div class="delete-contact__warning">
                    <span class="delete-contact__warning-title--icon"
                        ><i class="fad fa-exclamation-square"></i
                    ></span>
                    <h3 class="delete-contact__warning-title">
                        <strong> Waarschuwing:</strong> Weet je zeker dat je onderstaand contact
                        wilt verwijderen?
                    </h3>
                </div>
                <div class="recipient-info">
                    <ul class="recipient-info__list">
                        <li>
                            <h4><strong>Contact</strong></h4>
                        </li>
                        <li>
                            <i
                                *ngIf="contactForDelete.companyName !== ''"
                                class="far fa-building"
                            ></i>
                            {{ contactForDelete.companyName }}
                        </li>
                        <li>{{ contactForDelete | fullName }}</li>
                        <li>
                            {{ contactForDelete.streetname }}
                            {{ contactForDelete.houseNumber }}
                            {{ contactForDelete.houseNumberExtra }}
                        </li>
                        <li>{{ contactForDelete.addressExtra }}</li>
                        <li>{{ contactForDelete.postcode }}</li>
                        <li>{{ contactForDelete.city }}</li>
                        <li>{{ contactForDelete.country }}</li>
                        <li>
                            <a
                                class="recipient-info__list--phone-link"
                                href="tel:{{ contactForDelete.phone }}"
                            >
                                {{ contactForDelete.phone }}</a
                            >
                        </li>
                        <li>
                            <a
                                class="recipient-info__list--email-link"
                                href="mailto:{{ contactForDelete.email }}"
                            >
                                {{ contactForDelete.email }}</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <button class="btn inverse" (click)="delete()">Verwijder</button>
        </form>
    </app-modal>
</app-cp-page>
