import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { BackendService } from "../services/backend.service";
import { E_USER_TYPES } from "../helpers/constants";
import { environment } from "../../environments/environment";
import { addMessage } from "../store/actions/message.actions";
import { Message } from "../models/message";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    readNews: boolean = false;

    warehouseSyncing: boolean = false;

    private patch: string = environment.version;

    private stats: any;

    statistics: any = {};

    roles = E_USER_TYPES;

    constructor(
        private auth: AuthService,
        private backend: BackendService,
        private router: Router,
        private store: Store,
    ) {}

    ngOnInit() {
        if (localStorage.getItem(this.patch) != null || undefined) {
            this.readNews = true;
        }
        if (this.hasStats) {
            this.getStats();
        }
    }

    newPackage() {
        this.router.navigateByUrl("/packages/create");
    }

    newPackageBulk() {
        this.router.navigateByUrl("/(modal:csv-upload)");
    }

    newReturnLabels() {
        this.router.navigateByUrl("/(modal:return-labels)");
    }

    createUser() {
        this.router.navigateByUrl("/(modal:create-user)");
    }

    openNews() {
        this.router.navigateByUrl("/(modal:news)");
    }

    openPatchNotes() {
        localStorage.setItem(this.patch, "read");
        this.readNews = true;

        this.router.navigateByUrl("/(modal:patch-notes)");
    }

    downloadExport() {
        this.router.navigateByUrl("/(modal:logistics-export)");
    }

    openInvoicingExportModal() {
        this.router.navigateByUrl("/(modal:old-invoicing-export)");
    }

    openBulkUpdate() {
        this.router.navigateByUrl("/(modal:bulk-status)");
    }

    openTipWebshop() {
        this.router.navigateByUrl("/(modal:tip-webshop)");
    }

    openStats() {
        this.router.navigateByUrl("/(modal:statistics)");
    }

    openSyncLabels() {
        this.router.navigateByUrl("/(modal:sync-labels)");
    }

    syncWarehouse() {
        this.warehouseSyncing = true;
        this.backend.syncDatawarehouse().subscribe(
            () => {
                this.warehouseSyncing = false;
                const message = Message.createSuccessMessage(
                    "Export afgerond",
                    "Data is succesvol geexporteerd naar Datawarehouse",
                );
                this.store.dispatch(addMessage({ message }));
            },
            () => {
                this.warehouseSyncing = false;
                const message = Message.createErrorMessage(
                    "Export mislukt",
                    "Er is geen data geexporteerd naar Datawarehouse",
                );
                this.store.dispatch(addMessage({ message }));
            },
        );
    }

    getStats() {
        // Packages not picked up
        this.checkStatsCall("noPickup", "status=0&concept=true");

        this.checkStatsCall("notDelivered", "status=1,2,3,5,6,7,8,9,10&concept=false");
        // this.checkStatsCall("noPickup", "status=0&concept=true");
        // this.checkStatsCall("noPickup", "status=0&concept=true");
        // this.checkStatsCall("noPickup", "status=0&concept=true");
    }

    get canCreatePackage() {
        return this.auth.getAccountRole() === E_USER_TYPES.VIAWEBSHOP;
    }

    get canCreateReturnLabels() {
        return this.auth.getAccountRole() === E_USER_TYPES.VIAWEBSHOP;
    }

    get canExportPackages() {
        return [E_USER_TYPES.VIADC, E_USER_TYPES.MODERATOR, E_USER_TYPES.GLOBAL_ADMIN].includes(
            this.auth.getAccountRole(),
        );
    }

    get canBulkStatusUpdate() {
        return [E_USER_TYPES.VIADC, E_USER_TYPES.MODERATOR, E_USER_TYPES.GLOBAL_ADMIN].includes(
            this.auth.getAccountRole(),
        );
    }

    get canCreateUser() {
        return [E_USER_TYPES.MODERATOR, E_USER_TYPES.GLOBAL_ADMIN].includes(
            this.auth.getAccountRole(),
        );
    }

    get canSyncDHL() {
        return [
            E_USER_TYPES.VIADC,
            E_USER_TYPES.VIABODE,
            E_USER_TYPES.MODERATOR,
            E_USER_TYPES.GLOBAL_ADMIN,
        ].includes(this.auth.getAccountRole());
    }

    get canSyncWarehouse() {
        return [
            E_USER_TYPES.VIADC,
            E_USER_TYPES.VIABODE,
            E_USER_TYPES.MODERATOR,
            E_USER_TYPES.GLOBAL_ADMIN,
        ].includes(this.auth.getAccountRole());
    }

    get hasStats() {
        return [
            E_USER_TYPES.VIAWEBSHOP,
            E_USER_TYPES.VIADC,
            E_USER_TYPES.MODERATOR,
            E_USER_TYPES.GLOBAL_ADMIN,
        ].includes(this.auth.getAccountRole());
    }

    checkStatsCall(varName: string, query: string) {
        this.backend.getPackageStats(query).subscribe((result) => {
            this.stats = result.total;
            this.statistics[varName] = this.stats;
        });
    }
}
