import { Action, createReducer, on } from "@ngrx/store";
import * as MessageActions from "../actions/message.actions";
import { Message } from "../../models/message";

export const initialState: Message[] = [];

const messageReducer = createReducer(
    initialState,
    on(MessageActions.addMessage, (state, props) => [...state, props.message]),
    on(MessageActions.deleteMessage, (state, props) =>
        state.filter((message) => message.id !== props.id),
    ),
);

export function reducer(state: Message[] | undefined, action: Action) {
    return messageReducer(state, action);
}
