import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { InvoicesRoutingModule } from "./invoices-routing.module";
import { ImportPricesComponent } from "./import-prices/import-prices.component";
import { PriceListComponent } from "./price-list/price-list.component";
import { ControlPanelModule } from "../control-panel/control-panel.module";
import { SharedModule } from "../shared/shared.module";
import { NgbDatepickerModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ImportTransporterInvoiceComponent } from "./import-transporter-invoice/import-transporter-invoice.component";
import { FormsModule } from "@angular/forms";
import { ImportWorksheetComponent } from "./import-worksheet/import-worksheet.component";
import { ImportPickupsComponent } from "./import-pickups/import-pickups.component";
import { ComparePricesComponent } from "./compare-prices/compare-prices.component";
import { EditPackageModalComponent } from "./import-transporter-invoice/edit-package-modal/edit-package-modal.component";
import { TransporterInvoiceListComponent } from "./transporter-invoices-list/transporter-invoice-list.component";

@NgModule({
    declarations: [
        ImportPricesComponent,
        PriceListComponent,
        ImportTransporterInvoiceComponent,
        TransporterInvoiceListComponent,
        ImportWorksheetComponent,
        ImportPickupsComponent,
        ComparePricesComponent,
        EditPackageModalComponent,
    ],
    imports: [
        CommonModule,
        InvoicesRoutingModule,
        ControlPanelModule,
        SharedModule,
        NgbDatepickerModule,
        NgbTooltipModule,
        FormsModule,
    ],
})
export class InvoicesModule {}
