import { MessageEffects } from "./message.effects";
import { SessionEffects } from "./session.effects";
import { CurrentUserEffects } from "./current-user.effects";
import { PackageFilterEffects } from "./package-filter.effects";
import { PackagesEffects } from "./packages.effects";

export const effects = [
    MessageEffects,
    SessionEffects,
    CurrentUserEffects,
    PackageFilterEffects,
    PackagesEffects,
];
