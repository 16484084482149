import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
    name: "splitDate",
})
export class SplitDatePipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */
    transform(date: string | moment.Moment): string {
        if (date != null) {
            if (typeof date === "string") {
                return new Date(date).toLocaleDateString();
            }
            return date.format("DD/MM/YYYY");
        }
        return "";
    }
}
