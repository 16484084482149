<app-cp-modal title="Contact Aanmaken">
    <form (ngSubmit)="onSubmit($event)">
        <div class="row">
            <span>Land<i class="fas fa-asterisk"></i></span>
            <select
                [(ngModel)]="contact.country"
                name="country"
                id="country"
                ng="field.selectable"
                (change)="updateManualStreetInput()"
            >
                <option *ngFor="let country of countries" [value]="country.code">
                    {{ country.name }}
                </option>
            </select>
        </div>
        <div class="row errorRow" *ngIf="hasError('country')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('lastname')">{{
                    item | errorToText : "country"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span>Naam</span>
            <input
                type="text"
                class="input-large"
                placeholder="Voornaam"
                id="firstname"
                name="firstname"
                maxlength="50"
                [(ngModel)]="contact.firstname"
            />
            <input
                type="text"
                class="input-small"
                placeholder="Tussenvoegsel"
                id="middlename"
                name="middlename"
                maxlength="30"
                [(ngModel)]="contact.middlename"
            />
        </div>
        <div class="row errorRow" *ngIf="hasError('firstname')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('firstname')">{{
                    item | errorToText : "firstname"
                }}</span>
            </div>
        </div>
        <div class="row errorRow" *ngIf="hasError('middlename')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('middlename')">{{
                    item | errorToText : "middlename"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span>Achternaam<i class="fas fa-asterisk"></i></span>
            <input
                type="text"
                [class.errorField]="hasError('lastname')"
                (change)="checkInput()"
                placeholder="Achternaam"
                id="lastname"
                name="family-name"
                maxlength="50"
                [(ngModel)]="contact.lastname"
                required
            />
        </div>
        <div class="row errorRow" *ngIf="hasError('lastname')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('lastname')">{{
                    item | errorToText : "lastname"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span>Bedrijfsnaam</span>
            <input
                type="text"
                placeholder="ViaTim"
                id="company"
                maxlength="55"
                [(ngModel)]="contact.companyName"
                name="organization"
            />
        </div>
        <div class="row errorRow" *ngIf="hasError('company_name')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('company_name')">{{
                    item | errorToText : "company_name"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span>Postcode + Huisnr<i class="fas fa-asterisk"></i></span>
            <input
                type="text"
                class="input-large"
                [class.errorField]="hasError('postcode')"
                placeholder="Postcode"
                maxlength="12"
                id="postcode"
                name="postal-code"
                [(ngModel)]="contact.postcode"
                (change)="updateAddress(); checkInput()"
                required
            />
            <input
                type="text"
                class="input-large"
                [class.errorField]="hasError('housenr')"
                placeholder="Huis nr."
                id="housenr"
                name="housenr"
                maxlength="20"
                [(ngModel)]="contact.houseNumber"
                (change)="updateAddress(); checkInput()"
                required
            />
            <input
                type="text"
                class="input-small"
                [class.errorField]="hasError('housenr_extra')"
                placeholder="A"
                id="housenrletters"
                maxlength="5"
                name="housenr_extra"
                [(ngModel)]="contact.houseNumberExtra"
                (change)="updateAddress()"
            />
        </div>
        <div class="row errorRow" *ngIf="hasError('postcode')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('postcode')">{{
                    item | errorToText : "postcode"
                }}</span>
            </div>
        </div>
        <div class="row errorRow" *ngIf="hasError('housenr')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('housenr')">{{
                    item | errorToText : "housenr"
                }}</span>
            </div>
        </div>
        <div class="row errorRow" *ngIf="hasError('housenr_extra')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('housenr_extra')">{{
                    item | errorToText : "housenr_extra"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span></span>
            <li>
                <label for="autoFillAdress">
                    <input
                        type="checkbox"
                        [disabled]="!hasAutomaticPostcodeCheck"
                        [(ngModel)]="manualStreetInput"
                        name="manualStreetInput"
                    />
                    Handmatig adres invoeren
                </label>
            </li>
        </div>
        <div class="row">
            <span>Straat<i class="fas fa-asterisk"></i></span>
            <input
                type="text"
                [readonly]="!manualStreetInput"
                [class.errorField]="hasError('streetname')"
                placeholder="Straatnaam"
                class="form-control"
                id="street"
                maxlength="64"
                (change)="checkInput()"
                [(ngModel)]="contact.streetname"
                name="address-line1"
            />
        </div>
        <div class="row errorRow" *ngIf="hasError('streetname')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('streetname')">{{
                    item | errorToText : "streetname"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span>Tweede adresregel</span>
            <input
                type="text"
                placeholder="Extra adresregel"
                class="form-control"
                maxlength="50"
                id="address_extra"
                name="address_extra"
                [(ngModel)]="contact.addressExtra"
            />
        </div>
        <div class="row errorRow" *ngIf="hasError('address_extra')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('address_extra')">{{
                    item | errorToText : "address_extra"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span>Plaatsnaam<i class="fas fa-asterisk"></i></span>
            <input
                type="text"
                [readonly]="!manualStreetInput"
                (change)="checkInput()"
                [class.errorField]="hasError('city')"
                placeholder="Plaatsnaam"
                class="form-control"
                maxlength="30"
                [(ngModel)]="contact.city"
                id="city"
                name="city"
            />
        </div>
        <div class="row errorRow" *ngIf="hasError('city')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('city')">{{
                    item | errorToText : "city"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span>Email<i class="fas fa-asterisk"></i></span>
            <input
                placeholder="E-mail"
                type="text"
                id="email"
                name="email"
                [(ngModel)]="contact.email"
            />
        </div>
        <div class="row errorRow" *ngIf="hasError('email')">
            <span></span>
            <div class="errorSectionInput">
                <span class="errorMessageInput" *ngFor="let item of hasError('email')">{{
                    item | errorToText : "email"
                }}</span>
            </div>
        </div>
        <div class="row">
            <span>Telefoonnummer</span>
            <input
                placeholder="Telefoonnummer"
                type="text"
                id="tel"
                name="tel"
                maxlength="15"
                [(ngModel)]="contact.phone"
            />
        </div>
        <button class="btn inverse" type="submit" [disabled]="hasErrors">AANMAKEN</button>
    </form>
</app-cp-modal>
