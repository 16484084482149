<app-cp-modal title="Gebruiker toevoegen">
    <form (ngSubmit)="(onSubmit)" #userForm="ngForm">
        <div class="row" id="button-bar">
            <button *ngIf="canCreateViaBode" class="btn add" (click)="selectType(2)">
                <span><i class="fa fa-plus fa-lg"></i></span> ViaBode
            </button>
        </div>

        <app-create-user-create-viabode *ngIf="selectedType === 2"></app-create-user-create-viabode>

        <!-- EIND AANMAKEN VIAGEBRUIKER -->

        <!-- Extra information  -->
    </form>
</app-cp-modal>
