<app-cp-page>
    <div class="main content-margin">
        <h1>Aanmaken Webshop</h1>
        <app-edit-webshop-form
            *ngIf="viaWebshop"
            [viaWebshop]="viaWebshop"
            (onSave)="create($event)"
            (onCancel)="cancel()"
            [errors]="errors"
        ></app-edit-webshop-form>
    </div>
</app-cp-page>
